//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import _          from 'lodash';
import objectHash from 'object-hash';
import { put }    from 'redux-saga/effects';

import SagaStateHelper          from '@helper/SagaStateHelper';
import { ChangeTrackerActions } from '@slices/changeTracker';

function* checkChanges(action) {
    const payload                                               = _.get(action, 'payload', {});
    const { configuration, ignoredCompareField, changedAction } = payload;
    const referenceHash                                         = yield SagaStateHelper.selectFromState('changeTracker', payload.key);
    const stateObject                                           = yield SagaStateHelper.selectFromState(configuration.dataPropertyInReducer);
    const comparableStateObject                                 = _.omit(stateObject, ignoredCompareField);
    const stateHash                                             = objectHash(comparableStateObject);
    const isHashEquals                                          = _.isEqual(
        referenceHash,
        stateHash,
    );
    const nothingChanged                                        = (
        referenceHash ?
            isHashEquals :
            true
    );

    yield put(changedAction({
        changed: !nothingChanged,
    }));
}

function* saveReferenceObject(action) {
    const { payload }                            = action;
    const { key, configuration }                 = payload;
    const { ignoredCompareField, changedAction } = configuration;

    yield put(ChangeTrackerActions.checkChanges({
        key,
        changedAction,
        configuration,
        ignoredCompareField,
    }));
}

export default {
    checkChanges,
    saveReferenceObject,
};
// TODO-Skeleton end
