//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                    from 'i18next';
import { NotificationManager } from 'react-notifications';

export default class Notification {
    static translationBasePath = 'notifications.';

    static getMessageForKey(key, options = {}) {
        return I18n.t(Notification.translationBasePath + key, options);
    }

    static translationExistsForKey(key) {
        return I18n.exists(Notification.translationBasePath + key);
    }

    static success(key) {
        NotificationManager.success(this.getMessageForKey(key));
    }

    static error(key, options) {
        NotificationManager.error(this.getMessageForKey(key, options));
    }

    static warning(key) {
        NotificationManager.warning(this.getMessageForKey(key));
    }

    static info(key) {
        NotificationManager.info(this.getMessageForKey(key));
    }
}
