//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { AlertBoxReducer }              from '@slices/alertBox';
import { CalculationReducer }           from '@slices/calculation';
import { ChangeTrackerReducer }         from '@slices/changeTracker';
import { ConfigurationReducer }         from '@slices/configuration';
import { CustomerReducer }              from '@slices/customer';
import { DashboardReducer }             from '@slices/dashboard';
import { ExampleReducer }               from '@slices/example';
import { InventoryReducer }             from '@slices/inventory';
import { LoadingReducer }               from '@slices/loading';
import { ObjectReducer }                from '@slices/object';
import { SmartUpdaterReducer }          from '@slices/smartUpdater';
import { StagingAuthenticationReducer } from '@slices/stagingAuthentication';
import { SubscriptionOptionReducer }    from '@slices/subscriptionOption';
import { TranslationsReducer }          from '@slices/translations';
import { UnitReducer }                  from '@slices/unit';
import { UserReducer }                  from '@slices/user';
import { WebAppBannerReducer }          from '@slices/webAppBanner';
import { WidgetReducer }                from '@slices/widget';
import { WindowReducer }                from '@slices/window';

// TODO-Skeleton end

export default (history) => withReduxStateSync(combineReducers({
    // TODO-Skeleton start
    alertBox:              AlertBoxReducer,
    changeTracker:         ChangeTrackerReducer,
    configuration:         ConfigurationReducer,
    example:               ExampleReducer,
    inventory:             InventoryReducer,
    loading:               LoadingReducer,
    smartUpdater:          SmartUpdaterReducer,
    stagingAuthentication: StagingAuthenticationReducer,
    translations:          TranslationsReducer,
    user:                  UserReducer,
    webAppBanner:          WebAppBannerReducer,
    window:                WindowReducer,
    // TODO-Skeleton end
    widget:             WidgetReducer,
    object:             ObjectReducer,
    unit:               UnitReducer,
    customer:           CustomerReducer,
    calculation:        CalculationReducer,
    dashboard:          DashboardReducer,
    subscriptionOption: SubscriptionOptionReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
