//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes              from '@components/PropTypes';
import TableBodyCellAlignment from '@stateless/atomic/TableBodyCell/TableBodyCellAlignment';

import styles from './styles.module.scss';

const propTypes = {
    alignment:  PropTypes.oneOfObjectValues(TableBodyCellAlignment),
    children:   PropTypes.children,
    className:  PropTypes.string,
    columnSpan: PropTypes.number,
};

const TableBodyCell = ({
    alignment  = TableBodyCellAlignment.left,
    children   = null,
    columnSpan = 1,
    className  = '',
}) => {
    const gridColumnSpanStyle = {
        gridColumn: `span ${columnSpan}`,
    };

    return (
        <td
            className={classNames(
                styles.tableBodyCell,
                className,
                {
                    [styles.tableBodyCellAlignmentLeft]:  alignment === TableBodyCellAlignment.left,
                    [styles.tableBodyCellAlignmentRight]: alignment === TableBodyCellAlignment.right,
                },
            )}
            style={gridColumnSpanStyle}
        >
            {children}
        </td>
    );
};

TableBodyCell.propTypes = propTypes;

export default TableBodyCell;
