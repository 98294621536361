//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import CivilStatusType    from '@constants/CivilStatusType';
import CustomerFields     from '@constants/CustomerFields';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const initialContactPerson = {
    [CustomerFields.contactPersonSalutation]:   null,
    [CustomerFields.contactPersonTitle]:        null,
    [CustomerFields.contactPersonFirstname]:    null,
    [CustomerFields.contactPersonLastname]:     null,
    [CustomerFields.contactPersonAddress]:      {
        [_.last(CustomerFields.contactPersonStreet)]:      null,
        [_.last(CustomerFields.contactPersonHouseNumber)]: null,
        [_.last(CustomerFields.contactPersonPostalCode)]:  null,
        [_.last(CustomerFields.contactPersonCity)]:        null,
    },
    [CustomerFields.contactPersonEmail]:        null,
    [CustomerFields.contactPersonMobile]:       null,
    [CustomerFields.contactPersonPhoneWork]:    null,
    [CustomerFields.contactPersonPhonePrivate]: null,
};

const initialState = Object.freeze({
    edit:      {
        [CustomerFields.contactPersonOne]:         {
            ...initialContactPerson,
        },
        [CustomerFields.contactPersonTwo]:         {
            ...initialContactPerson,
        },
        [CustomerFields.civilStatus]:              CivilStatusType.single,
        [CustomerFields.annualTaxableIncome]:      null,
        [CustomerFields.increaseInIncomeInterval]: 1,
        [CustomerFields.increaseInIncome]:         2,
        [CustomerFields.churchTax]:                false,
    },
    customers: [],
});

const customerSlice = createSlice({
    name:     'customer',
    initialState,
    reducers: {
        createNewCustomer:       (state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        },
        editCustomer:            (state, action) => {
            return update(state, {
                edit: {
                    $set: _.get(action, 'payload.customer', initialState.edit),
                },
            });
        },
        editCustomerData:        (state, action) => {
            return update(state, {
                edit: {
                    $set: Merge.mergeWith(
                        _.get(state, 'edit', initialState.edit),
                        _.get(action, 'payload.edit', {}),
                    ),
                },
            });
        },
        saveCustomer:            LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.customerSave')),
        saveCustomerFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveCustomerSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        }),
        deleteCustomer:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.customerDelete')),
        deleteCustomerFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteCustomerSucceeded: LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchCustomers:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.customers')),
        fetchCustomersFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchCustomersSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                customers: {
                    $set: _.get(action, 'payload.customers', []),
                },
            });
        }),
        reset:                   () => {
            return {
                ...initialState,
            };
        },
    },
});

export const CustomerActions = customerSlice.actions;

export const CustomerReducer = customerSlice.reducer;

export const useCustomer = (dispatch) => bindActionCreators(CustomerActions, dispatch);

export default customerSlice;
