//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import PropTypes                      from 'prop-types';
import { useSelector }                from 'react-redux';
import { useDispatch }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';

import CustomerFields                            from '@constants/CustomerFields';
import InputRenderHelper                         from '@helper/InputRenderHelper';
import Notification                              from '@helper/Notification';
import ValidationHelper                          from '@helper/ValidationHelper';
import useContextTranslator                      from '@hooks/ContextTranslator';
import { useCalculation }                        from '@slices/calculation';
import IconType                                  from '@stateless/atomic/Icon/IconType';
import TextInputType                             from '@stateless/atomic/TextInput/TextInputType';
import ConfirmModal                              from '@stateless/composed/ConfirmModal';
import { selectCurrentCalculationCustomerEmail } from '@store/selectors/calculation';

const propTypes = {
    triggerOpen: PropTypes.any,
};

const CalculationSendEmailModal = ({
    triggerOpen = null,
}) => {
    const translator                        = useContextTranslator('screens.calculationScreen');
    const customerEmail                     = useSelector(selectCurrentCalculationCustomerEmail);
    const [showMailModal, setShowMailModal] = useState(false);
    const [validations, setValidations]     = useState({});
    const [email, setEmail]                 = useState(customerEmail);
    const emailData                         = {
        [CustomerFields.contactPersonEmail]: email,
    };
    const emailInputRenderer                = new InputRenderHelper({
        validations,
        data: emailData,
    });
    const emailSchema                       = objectValidation({
        [CustomerFields.contactPersonEmail]: string().email().required().test(
            'is-valid-email',
            ValidationHelper.isValidEmail,
        ),
    });
    const dispatch                          = useDispatch();
    const calculationActions                = useCalculation(dispatch);

    useEffect(
        () => {
            if (triggerOpen) {
                setShowMailModal(false);
                setShowMailModal(true);
            }
        },
        [triggerOpen],
    );

    function onEmailSendMailChanged(value) {
        setEmail(value);
    }

    function onMailModalConfirmed() {
        const currentValidations = ValidationHelper.getValidations(emailSchema, emailData, setValidations);

        if (ValidationHelper.hasNoValidations(currentValidations)) {
            setShowMailModal(false);

            calculationActions.sendMail({
                email,
            });
        } else {
            Notification.error('calculationMailValidationError');
        }
    }

    function onMailModalClosed() {
        setShowMailModal(false);
    }

    return (
        <ConfirmModal
            show={showMailModal}
            onConfirm={onMailModalConfirmed}
            confirmButtonText={'sendMail'}
            confirmButtonIcon={IconType.send}
            title={translator.t('sendMailTitle')}
            text={translator.t('sendMailText')}
            onClose={onMailModalClosed}
            disableAutoClose={true}
            fullHeight={false}
        >
            {emailInputRenderer.renderTextInput({
                label:         translator.t('email'),
                field:         CustomerFields.contactPersonEmail,
                required:      true,
                textInputType: TextInputType.email,
                onChange:      onEmailSendMailChanged,
            })}
        </ConfirmModal>
    );
};

CalculationSendEmailModal.propTypes = propTypes;

export default CalculationSendEmailModal;
