//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ApiUrls from '@constants/ApiUrls';

const calculationApi = (api) => {
    return {
        fetchCalculationResult: (data) => {
            return api.post(
                ApiUrls.API_CALCULATION_WIDGET_URL,
                data,
            );
        },
        saveCalculation:        (data) => {
            const iri = _.get(data, 'iri');

            if (iri) {
                return api.put(
                    iri,
                    data,
                );
            }

            return api.post(ApiUrls.API_CALCULATIONS, data);
        },
        sendCalculationEmail:   (id, parameter) => {
            return api.get(
                ApiUrls.API_CALCULATIONS_SEND_MAIL(id),
                parameter,
            );
        },
        delete:                 (iri) => {
            return api.delete(iri);
        },
        fetch:                  (iri) => {
            return api.get(iri);
        },
    };
};

export default (api) => calculationApi(api);
