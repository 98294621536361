//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes            from '@components/PropTypes';
import Routes               from '@constants/Routes';
import useContextTranslator from '@hooks/ContextTranslator';
import BreadCrumbItem       from '@stateless/atomic/BreadBrumbItem';
import IconType             from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    pathname: PropTypes.string,
};

const BreadCrumbBar = ({
    pathname = null,
}) => {
    const translator      = useContextTranslator('components.breadCrumbBar');
    const breadCrumbSteps = [
        {
            text:      translator.t('customer'),
            iconType:  IconType.circlePlus,
            active:    pathname === Routes.calculationCustomer,
            route:     Routes.calculationCustomer,
            firstItem: true,
        },
        {
            text:     translator.t('object'),
            iconType: IconType.circlePlus,
            active:   pathname === Routes.calculationObject,
            route:    Routes.calculationObject,
        },
        {
            text:     translator.t('finance'),
            iconType: IconType.circlePlus,
            active:   pathname === Routes.calculationFinance,
            route:    Routes.calculationFinance,
        },
        {
            text:     translator.t('result'),
            iconType: IconType.circlePlus,
            active:   pathname === Routes.calculationResult,
            route:    Routes.calculationResult,
        },
    ];

    function renderBreadCrumbItems() {
        return _.map(breadCrumbSteps, (step) => {
            return (
                <BreadCrumbItem
                    key={step.route}
                    text={step.text}
                    iconType={step.iconType}
                    active={step.active}
                    firstItem={step.firstItem}
                />
            );
        });
    }

    return (
        <div className={styles.breadCrumbBar}>
            {renderBreadCrumbItems()}
        </div>
    );
};

BreadCrumbBar.propTypes = propTypes;

export default BreadCrumbBar;
