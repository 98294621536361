//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                from '@components/PropTypes';
import CalculationFields        from '@constants/CalculationFields';
import UnitFields               from '@constants/UnitFields';
import UnitStatus               from '@constants/UnitStatus';
import Formatter                from '@helper/Formatter';
import { useCalculation }       from '@slices/calculation';
import Button                   from '@stateless/atomic/Button';
import ButtonColor              from '@stateless/atomic/Button/ButtonColor';
import Expandable               from '@stateless/atomic/Expandable';
import Headline                 from '@stateless/atomic/Headline';
import HeadlineType             from '@stateless/atomic/Headline/HeadlineType';
import IconType                 from '@stateless/atomic/Icon/IconType';
import TrafficLight             from '@stateless/atomic/TrafficLight';
import LightColor               from '@stateless/atomic/TrafficLight/LightColor';
import GroupedTable             from '@stateless/composed/GroupedTable';
import ObjectStatus             from '@stateless/composed/ObjectStatus';
import selectCurrentCalculation from '@store/selectors/calculation';

const propTypes = {
    data: PropTypes.object,
};

const ResidentialUnitTable = ({
    data = {},
}) => {
    const objectUnits        = data.units;
    const dispatch           = useDispatch();
    const calculationActions = useCalculation(dispatch);
    const calculation        = useSelector(selectCurrentCalculation);

    function renderStatus(item) {
        const status          = _.get(item, UnitFields.status);
        const lowerCaseStatus = _.toLower(status);
        let color;

        switch (status) {
            case UnitStatus.sold:
                color = LightColor.red;

                break;

            case UnitStatus.reserved:
                color = LightColor.yellow;

                break;

            case UnitStatus.available:
                color = LightColor.green;

                break;
        }

        return (
            <TrafficLight
                color={color}
                infoText={I18n.t(`unitStatus.${lowerCaseStatus}`)}
            />
        );
    }

    function sumUpUnitField(units, field) {
        return _.sumBy(units, (unit) => {
            return _.get(unit, field);
        });
    }

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            calculationActions.updateCalculationData({
                calculation: changeSet,
            });
        };
    }

    function onSelectedClicked(item) {
        return () => {
            onUpdateData(CalculationFields.unit)(item);
        };
    }

    function renderSelectButton(item) {
        let text     = I18n.t('selectUnit');
        let iconType = IconType.circlePlus;
        let color    = ButtonColor.white;

        if (_.get(calculation, 'unit.iri') === item.iri) {
            text     = I18n.t('selected');
            iconType = IconType.circleCheck;
            color    = ButtonColor.default;
        }
        return (
            <Button
                text={text}
                color={color}
                iconLeft={iconType}
                onClick={onSelectedClicked(item)}
            />
        );
    }

    const tableGroups = [
        {
            id:         1,
            columnSpan: 5,
            children:   I18n.t('data'),
        },
        {
            id:         2,
            columnSpan: 6,
            children:   I18n.t('purchasePrice'),
        },
        {
            id:         3,
            columnSpan: 8,
            children:   I18n.t('rent'),
        },
        {
            id:         4,
            columnSpan: 6,
            children:   null,
        },
    ];
    const tableCells  = [
        {
            id:           1,
            columnSpan:   1,
            children:     I18n.t('number'),
            propertyName: UnitFields.number,
        },
        {
            id:           2,
            columnSpan:   2,
            children:     I18n.t('rooms'),
            propertyName: UnitFields.roomCount,

        },
        {
            id:           3,
            columnSpan:   2,
            children:     I18n.t('area'),
            propertyName: UnitFields.spaceInSquareMeters,
            formatter:    Formatter.formatToSquareMeter,
        },
        {
            id:           4,
            columnSpan:   2,
            children:     I18n.t('unit'),
            propertyName: UnitFields.singlePurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           5,
            columnSpan:   2,
            children:     I18n.t('kfz'),
            propertyName: UnitFields.unitCarParkingPurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           6,
            columnSpan:   2,
            children:     I18n.t('perSquareMeter'),
            propertyName: UnitFields.purchasePricePerSquareMeter,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           7,
            columnSpan:   2,
            children:     I18n.t('cold'),
            propertyName: UnitFields.singleMonthlyRent,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           8,
            columnSpan:   2,
            children:     I18n.t('kfz'),
            propertyName: UnitFields.unitCarParkingMonthlyRent,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           9,
            columnSpan:   2,
            children:     I18n.t('perSquareMeter'),
            propertyName: UnitFields.monthlyRentPerSquareMeter,
            formatter:    Formatter.formatToCurrency(2),
        },
        {
            id:           10,
            columnSpan:   2,
            children:     I18n.t('rentalYield'),
            propertyName: UnitFields.returnYield,
            formatter:    Formatter.formatToPercent,
        },
        {
            id:           11,
            columnSpan:   2,
            children:     I18n.t('status'),
            customRender: renderStatus,
        },
        {
            id:           12,
            columnSpan:   4,
            children:     null,
            customRender: renderSelectButton,
        },
    ];
    const rowColumns  = [
        {
            columns: 3,
            items:   [
                {
                    title:    data.description,
                    subtitle: data.addressString,
                },
            ],
        },
        {
            columns: 7,
            items:   [
                {
                    prefixLine: true,
                    title:      I18n.t('sumPurchasePrice'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.singlePurchasePrice),
                    formatter:  Formatter.formatToCurrency(0),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('sumKfz'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.unitCarParkingPurchasePrice),
                    formatter:  Formatter.formatToCurrency(0),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('pricePerSquareMeter'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.purchasePricePerSquareMeter),
                    formatter:  Formatter.formatToCurrency(0),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('rentalYield'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.returnYield),
                    formatter:  Formatter.formatToPercentDigits(2),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('status'),
                    subtitle:   <ObjectStatus object={data} />,
                },
            ],
        },
    ];

    return (
        <Expandable
            expandButtonText={I18n.t('showUnits')}
            expandButtonColumns={2}
            rowColumns={rowColumns}
        >
            <Headline
                title={I18n.t('units')}
                type={HeadlineType.headline4}
            />
            <GroupedTable
                tableCells={tableCells}
                tableGroups={tableGroups}
                dataItems={data.units}
            />
        </Expandable>
    );
};

ResidentialUnitTable.propTypes = propTypes;

export default ResidentialUnitTable;
