//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    translations: [],
});

const translationsSlice = createSlice({
    name:     'translations',
    initialState,
    reducers: {
        fetchTranslations:          LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingTranslations'),
        ),
        fetchTranslationsFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        fetchTranslationsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            const { translations } = action.payload;

            return update(state, {
                translations: {
                    $set:  translations,
                },
            });
        }),
    },
});

export const TranslationsActions = translationsSlice.actions;

export const TranslationsReducer = translationsSlice.reducer;

export default translationsSlice;
// TODO-Skeleton end
