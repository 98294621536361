//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';

import PropTypes  from '@components/PropTypes';
import LightColor from '@stateless/atomic/TrafficLight/LightColor';

import styles from './styles.module.scss';

const propTypes = {
    color:     PropTypes.oneOfObjectValues(LightColor),
    infoText:  PropTypes.string,
    innerText: PropTypes.string,
    size:      PropTypes.number,
};

const TrafficLight = ({
    color     = LightColor.green,
    innerText = '',
    infoText  = '',
    size      = 18,
}) => {
    const sizeStyle = useMemo(() => (
        {
            width:  `${size}px`,
            height: `${size}px`,
        }
    ), [size]);

    return (
        <div className={styles.trafficLight}>
            <div
                className={classNames(
                    styles.light,
                    {
                        [styles.red]:    color === LightColor.red,
                        [styles.yellow]: color === LightColor.yellow,
                        [styles.green]:  color === LightColor.green,
                    },
                )}
                style={sizeStyle}
            >
                {innerText}
            </div>
            <div className={styles.infoText}>
                {infoText}
            </div>
        </div>
    );
};

TrafficLight.propTypes = propTypes;

export default TrafficLight;
