//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _ from 'lodash';

import PropTypes          from '@components/PropTypes';
import Table              from '@stateless/atomic/Table';
import TableBody          from '@stateless/atomic/TableBody';
import TableBodyCell      from '@stateless/atomic/TableBodyCell';
import TableBodyRow       from '@stateless/atomic/TableBodyRow';
import TableHead          from '@stateless/atomic/TableHead';
import TableHeaderCell    from '@stateless/atomic/TableHeaderCell';
import TableHeaderRow     from '@stateless/atomic/TableHeaderRow';
import TableHeaderRowType from '@stateless/atomic/TableHeaderRow/TableHeaderRowType';

import styles from './styles.module.scss';

const propTypes = {
    dataItems:   PropTypes.arrayOf(PropTypes.object),
    tableCells:  PropTypes.arrayOf(PropTypes.object),
    tableGroups: PropTypes.arrayOf(PropTypes.object),
};

const GroupedTable = ({
    dataItems   = [],
    tableGroups = [],
    tableCells  = [],
}) => {
    const sumOfCellColumns = useMemo(
        () => tableCells.reduce((sum, cell) => sum + cell.columnSpan, 0),
        [tableCells],
    );

    function renderTableHeaderCells(cells) {
        return cells.map((cell) => (
            <TableHeaderCell
                key={cell.id}
                columnSpan={cell.columnSpan}
            >
                {cell.children}
            </TableHeaderCell>
        ));
    }

    function renderTableHeaderRow(cells, type) {
        return (
            <TableHeaderRow
                columns={sumOfCellColumns}
                type={type}
            >
                {renderTableHeaderCells(cells)}
            </TableHeaderRow>
        );
    }

    function getCellClassName(cell, item) {
        let disabled = _.get(cell, 'disabled', false);

        if (_.isFunction(cell.disabled)) {
            disabled = cell.disabled(item);
        }

        if (disabled) {
            return styles.disabled;
        }

        return null;
    }

    function renderTableBodyCells(item) {
        return _.map(tableCells, (cell) => {
            function renderContent() {
                if (_.isFunction(cell.customRender)) {
                    return cell.customRender(item);
                }

                const formatter = _.get(cell, 'formatter');
                const itemValue = _.get(item, cell.propertyName);

                if (formatter) {
                    return formatter(itemValue);
                }

                return itemValue;
            }

            return (
                <TableBodyCell
                    key={cell.id}
                    columnSpan={cell.columnSpan}
                    className={getCellClassName(cell, item)}
                >
                    {renderContent()}
                </TableBodyCell>
            );
        });
    }

    function renderTableBodyRows() {
        return _.map(dataItems, (item) => {
            return (
                <TableBodyRow
                    key={item.id}
                    columns={sumOfCellColumns}
                >
                    {renderTableBodyCells(item)}
                </TableBodyRow>
            );
        });
    }

    return (
        <div className={styles.groupedTable}>
            <Table>
                <TableHead>
                    {renderTableHeaderRow(tableGroups, TableHeaderRowType.filled)}
                    {renderTableHeaderRow(tableCells)}
                </TableHead>
                <TableBody>
                    {renderTableBodyRows()}
                </TableBody>
            </Table>
        </div>
    );
};

GroupedTable.propTypes = propTypes;

export default GroupedTable;
