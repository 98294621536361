//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import CalculationFields  from '@constants/CalculationFields';
import CalculationSteps   from '@constants/CalculationSteps';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const initialState = Object.freeze({
    currentCalculation: {
        [CalculationFields.equityCapital]:     null,
        [CalculationFields.calculationFields]: {
            [CalculationFields.interest]:                         null,
            [CalculationFields.redemption]:                       null,
            [CalculationFields.increaseInIncome]:                 null,
            [CalculationFields.increaseInIncomeInterval]:         null,
            [CalculationFields.rentIncrease]:                     null,
            [CalculationFields.rentIncreaseInterval]:             null,
            [CalculationFields.increaseInOperatingCosts]:         null,
            [CalculationFields.increaseInOperatingCostsInterval]: null,
            [CalculationFields.increaseInValue]:                  null,
            [CalculationFields.increaseInValueInterval]:          null,
        },
        [CalculationFields.unit]:              null,
        [CalculationFields.customer]:          null,
        [CalculationFields.step]:              CalculationSteps.customer,
    },
});

const calculationSlice = createSlice({
    name:     'calculation',
    initialState,
    reducers: {
        openCalculation:                 (state, action) => {
            return update(state, {
                currentCalculation: {
                    $set: _.get(action, 'payload.calculation', initialState.currentCalculation),
                },
            });
        },
        setStep:                         (state, action) => {
            return update(state, {
                currentCalculation: {
                    [CalculationFields.step]: {
                        $set: _.get(action, 'payload.step', _.get(state, 'currentCalculation.step', 0)),
                    },
                },
            });
        },
        updateCalculationData:           (state, action) => {
            const calculationChangeSet = _.get(action, 'payload.calculation', {});
            const hasCustomerChange    = _.has(calculationChangeSet, CalculationFields.customer);
            const currentCalculation   = (
                hasCustomerChange ?
                    initialState.currentCalculation :
                    _.get(state, 'currentCalculation', initialState.currentCalculation)
            );

            return update(state, {
                currentCalculation: {
                    $set: Merge.mergeWith(
                        currentCalculation,
                        calculationChangeSet,
                    ),
                },
            });
        },
        saveCalculation:                 LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.calculationSave')),
        saveCalculationFailed:           LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveCalculationSucceeded:        LoadingLevelHelper.decreaseLoading((state, actions) => {
            return update(state, {
                currentCalculation: {
                    $set: {
                        ..._.get(actions, 'payload.calculation', initialState.currentCalculation),
                        [CalculationFields.calculationResult]: _.get(state, ['currentCalculation', CalculationFields.calculationResult]),
                    },
                },
            });
        }),
        fetchCalculationResult:          () => {
        },
        fetchCalculationResultFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchCalculationResultSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            const calculationResult        = _.get(action, 'payload.calculationResult');
            const fieldsToSet              = [
                CalculationFields.interest,
                CalculationFields.redemption,
                CalculationFields.increaseInIncome,
                CalculationFields.increaseInIncomeInterval,
                CalculationFields.rentIncrease,
                CalculationFields.rentIncreaseInterval,
                CalculationFields.increaseInOperatingCosts,
                CalculationFields.increaseInOperatingCostsInterval,
                CalculationFields.increaseInValue,
                CalculationFields.increaseInValueInterval,
            ];
            const calculationFieldsUpdates = {};

            _.forEach(fieldsToSet, (field) => {
                calculationFieldsUpdates[field] = {
                    $set: _.get(calculationResult, field),
                };
            });

            return update(state, {
                currentCalculation: {
                    calculationResult:                     {
                        $set: {
                            ...calculationResult,
                        },
                    },
                    [CalculationFields.calculationFields]: {
                        ...calculationFieldsUpdates,
                    },
                    [CalculationFields.equityCapital]:     {
                        $set: _.get(calculationResult, CalculationFields.equity),
                    },
                },
            });
        }),
        fetchCalculation:                LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.fetchCalculation')),
        fetchCalculationFailed:          LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchCalculationSucceeded:       LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                currentCalculation: {
                    $set: _.get(action, 'payload.calculation', initialState.currentCalculation),
                },
            });
        }),
        deleteCalculation:               LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.calculationDelete')),
        deleteCalculationFailed:         LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteCalculationSucceeded:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        sendMail:                        () => {
        },
        sendMailFailed:                  LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        sendMailSucceeded:               LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        logout:                          (state, action) => {
            return update(state, {
                $set: initialState,
            });
        },
        downloadCalculationPDF:          () => {
        },
    },
});

export const CalculationActions = calculationSlice.actions;

export const CalculationReducer = calculationSlice.reducer;

export const useCalculation = (dispatch) => bindActionCreators(CalculationActions, dispatch);

export default calculationSlice;
