//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children:     PropTypes.children,
    columns:      PropTypes.number,
    onRowClicked: PropTypes.func,
    selectable:   PropTypes.bool,
    selected:     PropTypes.bool,
};

const TableBodyRow = ({
    children     = null,
    onRowClicked = DefaultTypes.func,
    selectable   = false,
    selected     = false,
    columns      = 1,
}) => {
    const gridColumnStyle = {
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
    };

    return (
        <tr
            className={classNames(
                styles.tableBodyRow,
                {
                    [styles.tableBodyRowSelected]:   selected,
                    [styles.tableBodyRowSelectable]: selectable,
                },
            )}
            onClick={onRowClicked}
            style={gridColumnStyle}
        >
            {children}
        </tr>
    );
};

TableBodyRow.propTypes = propTypes;

export default TableBodyRow;
