//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes    from '@components/PropTypes';
import UnitStatus   from '@constants/UnitStatus';
import TrafficLight from '@stateless/atomic/TrafficLight';
import LightColor   from '@stateless/atomic/TrafficLight/LightColor';

import styles from './styles.module.scss';

const propTypes = {
    object: PropTypes.object,
};

const ObjectStatus = ({
    object = {},
}) => {
    const defaultTrafficLightSize                 = 18;
    const [trafficLightSize, setTrafficLightSize] = useState(defaultTrafficLightSize);
    const [unitStatusCount, setUnitStatusCount]   = useState({});

    function countUnitsByStatus(status) {
        return _.size(_.filter(object.units, {
            status,
        }));
    }

    useEffect(() => {
        const soldCount      = countUnitsByStatus(UnitStatus.sold);
        const reservedCount  = countUnitsByStatus(UnitStatus.reserved);
        const availableCount = countUnitsByStatus(UnitStatus.available);
        const biggestCount   = Math.max(soldCount, reservedCount, availableCount);
        let newSize          = defaultTrafficLightSize;

        if (biggestCount >= 100) {
            newSize = 28;
        } else if (biggestCount >= 10) {
            newSize = 21;
        }

        setTrafficLightSize(newSize);
        setUnitStatusCount({
            [UnitStatus.sold]:      soldCount,
            [UnitStatus.reserved]:  reservedCount,
            [UnitStatus.available]: availableCount,
        });
    }, [object]);

    function getColor(status) {
        switch (status) {
            case UnitStatus.sold:
                return LightColor.red;
            case UnitStatus.reserved:
                return LightColor.yellow;
            case UnitStatus.available:
                return LightColor.green;
            default:
                return LightColor.green;
        }
    }

    function renderTrafficLightForStatus(status) {
        const lowerCaseStatus = _.toLower(status);
        const countForStatus  = _.get(unitStatusCount, status, 0);

        return (
            <TrafficLight
                size={trafficLightSize}
                color={getColor(status)}
                innerText={countForStatus}
                infoText={I18n.t(`unitStatus.${lowerCaseStatus}`)}
            />
        );
    }

    return (
        <div className={styles.objectStatus}>
            {renderTrafficLightForStatus(UnitStatus.sold)}
            {renderTrafficLightForStatus(UnitStatus.reserved)}
            {renderTrafficLightForStatus(UnitStatus.available)}
        </div>
    );
};

ObjectStatus.propTypes = propTypes;

export default ObjectStatus;
