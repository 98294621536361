//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import Logo         from '@assets/images/logo.png';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import LayoutScreen from '@stateless/composed/LayoutScreen';
import PageTitle    from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const NotFound = () => {
    return (
        <>
            <PageTitle
                title={I18n.t('pageNotFound')}
            />
            <LayoutScreen>
                <div className={styles.notFoundScreen}>
                    <img
                        src={Logo}
                        alt={'Logo'}
                    />
                    <Headline
                        title={I18n.t('error_404')}
                        type={HeadlineType.headline1}
                    />
                    <p>
                        {I18n.t('pageNotFound')}
                    </p>
                </div>
            </LayoutScreen>
        </>
    );
};

export default NotFound;
