//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import _              from 'lodash';
import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api            from '@api/index';
import CustomerFields      from '@constants/CustomerFields';
import Routes              from '@constants/Routes';
import Hydra               from '@helper/Hydra';
import Notification        from '@helper/Notification';
import SagaStateHelper     from '@helper/SagaStateHelper';
import { CustomerActions } from '@slices/customer';
import NumberMapper        from '@store/helper/NumberMapper';
import selectEditCustomer  from '@store/selectors/customer';

function* saveCustomer() {
    const customer     = yield SagaStateHelper.selector(selectEditCustomer);
    const response     = yield call(
        Api.context.customer.saveCustomer,
        NumberMapper.mapFieldsToNumber(
            _.omit(customer, ['calculations']),
            [
                CustomerFields.annualTaxableIncome,
                CustomerFields.increaseInIncome,
                CustomerFields.increaseInIncomeInterval,
            ],
        ),
    );
    const isNewPayload = {
        isNew: !_.get(customer, 'iri'),
    };

    if (response.ok) {
        yield put(CustomerActions.saveCustomerSucceeded(isNewPayload));
    } else {
        yield put(CustomerActions.saveCustomerFailed(isNewPayload));
    }
}

function getTranslation(action, translationKey) {
    const prefix = (
        _.get(action, 'payload.isNew') ?
            'createCustomer' :
            'updateCustomer'
    );

    return `${prefix}.${translationKey}`;
}

function* saveCustomerFailed(action) {
    Notification.error(getTranslation(action, 'error'));
}

function* saveCustomerSucceeded(action) {
    Notification.success(getTranslation(action, 'success'));
    yield put(push(Routes.customers));
}

function* deleteCustomer(action) {
    const customer = _.get(action, 'payload.customer');
    const response = yield call(
        Api.context.customer.deleteCustomer,
        _.get(customer, 'iri'),
    );

    if (response.ok) {
        yield put(CustomerActions.deleteCustomerSucceeded());
    } else {
        yield put(CustomerActions.deleteCustomerFailed());
    }
}

function* deleteCustomerFailed() {
    Notification.error('deleteCustomer.error');
}

function* deleteCustomerSucceeded() {
    Notification.success('deleteCustomer.success');
    yield put(CustomerActions.fetchCustomers());
}

function* fetchCustomers() {
    const response = yield call(Api.context.customer.fetchCustomers);

    if (response.ok) {
        const customers = Hydra.getMembersFromResponse(response.data);

        yield put(CustomerActions.fetchCustomersSucceeded({
            customers,
        }));
    } else {
        yield put(CustomerActions.fetchCustomersFailed());
    }
}

function* fetchCustomersFailed() {
    Notification.error('fetchCustomers.error');
}

function* editCustomer() {
    yield put(push(Routes.editCustomer));
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([CustomerActions.saveCustomer().type],            saveCustomer),
        takeLatest([CustomerActions.saveCustomerFailed().type],      saveCustomerFailed),
        takeLatest([CustomerActions.saveCustomerSucceeded().type],   saveCustomerSucceeded),
        takeLatest([CustomerActions.deleteCustomer().type],          deleteCustomer),
        takeLatest([CustomerActions.deleteCustomerFailed().type],    deleteCustomerFailed),
        takeLatest([CustomerActions.deleteCustomerSucceeded().type], deleteCustomerSucceeded),
        takeLatest([CustomerActions.fetchCustomers().type],          fetchCustomers),
        takeLatest([CustomerActions.fetchCustomersFailed().type],    fetchCustomersFailed),
        takeLatest([CustomerActions.editCustomer().type],            editCustomer),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
