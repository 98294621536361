//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes  from '@components/PropTypes';
import TopBar     from '@connected/TopBar';
import Footer     from '@stateless/composed/Footer';
import MobileMenu from '@stateless/composed/MobileMenu';
import Sidebar    from '@stateless/composed/Sidebar';
import TrialModal from '@stateless/composed/TrialModal';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const LayoutScreen = ({
    children = null,
}) => {
    return (
        <div className={styles.screen}>
            <Sidebar />
            <MobileMenu />
            <div className={styles.contentWrapper}>
                <TopBar />
                <div className={styles.contentContainer}>
                    <div className={styles.childrenContainer}>
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
            <TrialModal />
        </div>
    );
};

LayoutScreen.propTypes = propTypes;

export default LayoutScreen;
