//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ImageStorage from '@helper/ImageStorage';

class FileUpload {
    static addFiles = async (uploadedFiles, database) => {
        const fileNames = Object.keys(uploadedFiles);
        const files     = [];

        for (let fileNameIndex = 0; fileNameIndex < fileNames.length; fileNameIndex++) {
            const key  = fileNames[fileNameIndex];
            const file = await this.processUploadedFile(key, uploadedFiles, database);

            files.push(file);
        }

        return files;
    };

    static processUploadedFile = (key, uploadedFiles, database) => {
        return new Promise((resolve) => {
            const reader       = new FileReader();
            const uploadedFile = uploadedFiles[key];

            reader.onloadend = () => {
                this.onFileLoadEnd(reader, uploadedFile, database, (file) => {
                    resolve(file);
                });
            };

            reader.readAsDataURL(uploadedFile);
        });
    };

    static onFileLoadEnd = (reader, uploadedFile, database, callback) => {
        const fileInformation = this.getFileInformation(uploadedFile);

        this.saveFile(reader, fileInformation, database, callback);
    };

    static getFileInformation = (uploadedFile) => {
        return {
            name:         uploadedFile.name,
            lastModified: uploadedFile.lastModified,
        };
    };

    static saveFile = (reader, fileInformation, database, callback) => {
        ImageStorage.saveImage(reader.result, database).then((id) => {
            /* eslint-disable no-param-reassign */
            const file        = _.clone(reader.result);
            const fileContent = file.split('base64,');

            fileContent.shift();

            const decodedFile      = atob(fileContent.join());
            fileInformation.fileId = id;
            fileInformation.size   = decodedFile.length;
            fileInformation.type   = file.split(';')[0].split('/')[1];

            console.log('FileUpload: saveFile', fileInformation);
            this.onLoadingFileComplete(fileInformation, callback);
            /* eslint-enable no-param-reassign */
        }, (error) => {
            console.log('FileUpload: saveFile', error);
        });
    };

    static onLoadingFileComplete = (file, callback) => {
        callback(_.cloneDeep(file));
    };
}

export default FileUpload;
