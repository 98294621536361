//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api                from '@api/index';
import { TranslationsActions } from '@slices/translations';

function* fetchTranslations(args) {
    const { domain } = args.payload;
    const response   = yield call(Api.context.translations.fetch, domain);

    if (response.ok) {
        const translations = response.data;

        yield put(TranslationsActions.fetchTranslationsSucceeded({
            translations,
            domain,
        }));
    } else {
        yield put(TranslationsActions.fetchTranslationsFailed());
    }
}

export default {
    fetchTranslations,
};
// TODO-Skeleton end
