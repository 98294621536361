//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styled from 'styled-components';

import PropTypes   from '@components/PropTypes';
import ColorHelper from '@helper/Color';
import ProfileMenu from '@stateless/composed/ProfileMenu';

import styles from './styles.module.scss';

const propTypes = {
    avatar:    PropTypes.string,
    menuItems: PropTypes.array,
    name:      PropTypes.string,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const TopBarWrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor} !important;
    border-bottom:    1px solid ${(props) => ColorHelper.getDarkerColor(props.theme.backgroundColor)} !important;
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const TopBar = ({
    menuItems,
    name,
    avatar,
}) => {
    return (
        <TopBarWrapper
            className={styles.topBar}
        >
            <div className={styles.topBarContent}>
                <ProfileMenu
                    menuItems={menuItems}
                    name={name}
                    avatar={avatar}
                />
            </div>
        </TopBarWrapper>
    );
};

TopBar.propTypes = propTypes;

export default TopBar;
