//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import useContextTranslator from '@hooks/ContextTranslator';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import Icon                 from '@stateless/atomic/Icon';
import IconType             from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {};

const SignupFeatures = () => {
    const translator = useContextTranslator('screens.signup');

    function renderFeature(textKey) {
        return (
            <ul>
                <Icon
                    iconType={IconType.circleCheck}
                />
                {translator.t(textKey)}
            </ul>
        );
    }

    return (
        <div className={styles.signupFeatures}>
            <Headline
                title={translator.t('featureHeadline')}
                type={HeadlineType.headline2}
            />
            {renderFeature('feature1')}
            {renderFeature('feature2')}
            {renderFeature('feature3')}
            {renderFeature('feature4')}
        </div>
    );
};

SignupFeatures.propTypes = propTypes;

export default SignupFeatures;
