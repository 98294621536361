//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useMemo }   from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import _                        from 'lodash';
import { useSelector }          from 'react-redux';
import { Redirect }             from 'react-router';
import { useHistory }           from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

import Routes                 from '@constants/Routes';
import Notification           from '@helper/Notification';
import { selectUserContract } from '@store/selectors/user';

const ContractWatcher = () => {
    const contract                        = useSelector(selectUserContract);
    const history                         = useHistory();
    const [currentRoute, setCurrentRoute] = useState(history.location.pathname);
    const debouncedNotification           = useDebouncedCallback((key) => {
        Notification.error(key);
    }, 500);

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            setCurrentRoute(location.pathname);
        });

        return () => {
            unlisten();
        };
    }, []);

    return useMemo(
        () => {
            const isContractActive = _.get(contract, 'active', false);

            if (
                currentRoute !== Routes.paymentData &&
                contract &&
                !isContractActive
            ) {
                debouncedNotification('trialExpired');

                return (
                    <Redirect to={Routes.paymentData} />
                );
            }

            return null;
        },
        [contract, currentRoute],
    );
};

export default ContractWatcher;
