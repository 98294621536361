//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n   from 'i18next';
import _      from 'lodash';
import styled from 'styled-components';

import PropTypes     from '@components/PropTypes';
import CurrencyLabel from '@stateless/atomic/CurrencyLabel';
import RoomCount     from '@stateless/atomic/RoomCount';
import SpaceLabel    from '@stateless/atomic/SpaceLabel';
import Colors        from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    active:              PropTypes.bool,
    fontColor:           PropTypes.string,
    onClick:             PropTypes.func,
    primaryColor:        PropTypes.string,
    propertyDescription: PropTypes.string,
    purchasePrice:       PropTypes.number,
    roomCount:           PropTypes.number,
    spaceInSquareMeters: PropTypes.number,
};

const getActiveColor = (active, primaryColor) => {
    return (
        active ?
            primaryColor :
            Colors.grayChicago
    );
};

const getPrimaryColor = (props) => {
    return props.$primaryColor;
};

const WidgetUnitWrapper = styled.div`
    color:            ${(props) => props.$fontColor};
    background-color: ${(props) => getActiveColor(props.$active, getPrimaryColor(props))};

    &:hover
    {
        background-color: ${getPrimaryColor};
    }
`;

const WidgetUnit = ({
    propertyDescription = '',
    purchasePrice       = 0,
    roomCount           = 0,
    spaceInSquareMeters = 0,
    fontColor           = Colors.white,
    primaryColor        = Colors.darkGreen,
    active              = false,
    onClick             = _.noop,
}) => {
    return (
        <WidgetUnitWrapper
            className={styles.widgetUnit}
            onClick={onClick}
            $fontColor={fontColor}
            $primaryColor={primaryColor}
            $active={active}
        >
            <div className={styles.unitInformation}>
                <strong>
                    {propertyDescription}
                </strong>
                <div className={styles.unitInformationDetails}>
                    <SpaceLabel
                        value={spaceInSquareMeters}
                    />
                    <span>
                        {I18n.t('components.widgetUnit.spaceAndRoomSeparator')}
                    </span>
                    <RoomCount
                        value={roomCount}
                    />
                </div>
            </div>
            <div>
                <CurrencyLabel
                    value={purchasePrice}
                />
            </div>
        </WidgetUnitWrapper>
    );
};

WidgetUnit.propTypes = propTypes;

export default WidgetUnit;
