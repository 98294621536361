//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import Routes                 from '@constants/Routes';
import UnitStatus             from '@constants/UnitStatus';
import CustomerHelper         from '@helper/CustomerHelper';
import Formatter              from '@helper/Formatter';
import useContextTranslator   from '@hooks/ContextTranslator';
import { useDashboard }       from '@slices/dashboard';
import DashboardWidget        from '@stateless/atomic/DashboardWidget';
import Headline               from '@stateless/atomic/Headline';
import HeadlineType           from '@stateless/atomic/Headline/HeadlineType';
import IconType               from '@stateless/atomic/Icon/IconType';
import ListWidget             from '@stateless/atomic/ListWidget';
import SplitWidget            from '@stateless/atomic/SplitWidget';
import DashboardFrameChart    from '@stateless/composed/DashboardFrameChart';
import FaqInfoWidget          from '@stateless/composed/FaqInfoWidget';
import LayoutScreen           from '@stateless/composed/LayoutScreen';
import PageTitle              from '@stateless/composed/PageTitle';
import { selectCustomers }    from '@store/selectors/customer';
import { selectUserFullName } from '@store/selectors/user';

import styles from './styles.module.scss';

const Dashboard = () => {
    const history            = useHistory();
    const translator         = useContextTranslator('screens.dashboardScreen');
    const userName           = useSelector(selectUserFullName);
    const dispatch           = useDispatch();
    const dashboardActions   = useDashboard(dispatch);
    const dashboard          = useSelector((state) => state.dashboard);
    const squareMeter        = Formatter.formatToSquareMeter(_.get(dashboard, 'availableTotalSquareMeters', 0));
    const calculationsAmount = _.get(dashboard, 'calculationAmount', 0);
    const customers          = useSelector(selectCustomers);

    useEffect(() => {
        dashboardActions.fetchDashboard();
    }, []);

    function onNavigateToRoute(route) {
        return () => {
            history.push(route);
        };
    }

    const newRequestsItems = _.map(customers, (customer) => {
        return {
            text:    CustomerHelper.getNameByContactPerson(customer),
            onClick: onNavigateToRoute(Routes.customers),
        };
    });
    const profileItems     = [
        {
            text:    I18n.t('profile'),
            icon:    IconType.user,
            onClick: onNavigateToRoute(Routes.profile),
        },
        {
            text:    I18n.t('settings'),
            icon:    IconType.settings,
            onClick: onNavigateToRoute(Routes.settings),
        },
        {
            text:    I18n.t('customerOnlineCalculator'),
            icon:    IconType.settingsBig,
            onClick: onNavigateToRoute(Routes.customerOnlineCalculator),
        },
        {
            text:    I18n.t('paymentData'),
            icon:    IconType.card,
            onClick: onNavigateToRoute(Routes.paymentData),
        },
    ];

    return (
        <>
            <PageTitle
                title={I18n.t('dashboard')}
            />
            <LayoutScreen>
                <div className={styles.dashboardScreen}>
                    <Headline
                        title={translator.t('welcomeText', {
                            userName,
                        })}
                        type={HeadlineType.headline1}
                    />
                    <div className={styles.dashboardContainer}>
                        <DashboardWidget
                            title={translator.t('unitsOverview')}
                        >
                            <DashboardFrameChart
                                chartName={'units-overview'}
                                parameters={{
                                    'reservedValue':  _.get(dashboard, ['unitsOverview', UnitStatus.reserved], 0),
                                    'soldValue':      _.get(dashboard, ['unitsOverview', UnitStatus.sold], 0),
                                    'availableValue': _.get(dashboard, ['unitsOverview', UnitStatus.available], 0),
                                }}
                            />
                        </DashboardWidget>
                        <DashboardWidget
                            title={translator.t('totalVolume')}
                        >
                            <DashboardFrameChart
                                chartName={'total-volume'}
                                parameters={{
                                    'reservedValue':  _.get(dashboard, ['totalVolume', UnitStatus.reserved], 0),
                                    'soldValue':      _.get(dashboard, ['totalVolume', UnitStatus.sold], 0),
                                    'availableValue': _.get(dashboard, ['totalVolume', UnitStatus.available], 0),
                                }}
                            />
                        </DashboardWidget>
                        <SplitWidget
                            upperTitle={I18n.t('calculations')}
                            upperValue={calculationsAmount}
                            lowerTitle={I18n.t('availableTotalArea')}
                            lowerValue={squareMeter}
                        />
                        <ListWidget
                            title={I18n.t('newRequests')}
                            items={newRequestsItems}
                        />
                        <ListWidget
                            title={I18n.t('profile')}
                            items={profileItems}
                        />
                        <FaqInfoWidget />
                    </div>
                </div>
            </LayoutScreen>
        </>
    );
};

export default Dashboard;
