//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styled from 'styled-components';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const LabelWidgetWrapper = styled.div`
    background: ${(props) => props.theme.primaryColor};
    color:      ${(props) => props.theme.textColor};
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const LabelWidget = ({
    title = '',
    value = '',
}) => {
    return (
        <LabelWidgetWrapper className={styles.labelWidget}>
            <div>
                {title}
            </div>
            <div>
                {value}
            </div>
        </LabelWidgetWrapper>
    );
};

LabelWidget.propTypes = propTypes;

export default LabelWidget;
