//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Routes    from '@constants/Routes';
import Link      from '@stateless/atomic/Link';

import styles from './styles.module.scss';

const propTypes = {
    target:     PropTypes.string,
    targetText: PropTypes.string,
    text:       PropTypes.string,
};

const SignupLoginSwitcher = ({
    text       = '',
    targetText = '',
    target     = Routes.signup,
}) => {
    return (
        <div className={styles.signupLoginSwitcher}>
            <span>
                {text}
            </span>
            <Link
                to={target}
            >
                {targetText}
            </Link>
        </div>
    );
};

SignupLoginSwitcher.propTypes = propTypes;

export default SignupLoginSwitcher;
