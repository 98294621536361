//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useCallback } from 'react';
import { useEffect }   from 'react';
import { useState }    from 'react';

import I18n from 'i18next';

const useContextTranslator = (basePath) => {
    const [language, setLanguage] = useState(I18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setLanguage(I18n.language);
        };

        I18n.on('languageChanged', handleLanguageChange);

        return () => {
            I18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const translate = useCallback(
        (key, options) => {
            return I18n.t(`${basePath}.${key}`, options);
        },
        [
            basePath,
            language,
        ],
    );

    return {
        t: translate,
    };
};

export default useContextTranslator;
