//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import Avatar                 from '@assets/images/avatar.webp';
import Routes                 from '@constants/Routes';
import UserFields             from '@constants/UserFields';
import ImageHelper            from '@helper/ImageHelper';
import useContextTranslator   from '@hooks/ContextTranslator';
import { UserActions }        from '@slices/user';
import IconType               from '@stateless/atomic/Icon/IconType';
import StatelessTopBar        from '@stateless/composed/TopBar';
import { selectUser }         from '@store/selectors/user';
import { selectUserFullName } from '@store/selectors/user';

const TopBar = () => {
    const history    = useHistory();
    const dispatch   = useDispatch();
    const user       = useSelector(selectUser);
    const name       = useSelector(selectUserFullName);
    const translator = useContextTranslator('components.profileMenu');
    let avatar       = Avatar;
    const image      = _.get(user, UserFields.image);

    if (
        image &&
        _.isObject(image)
    ) {
        avatar = ImageHelper.getImagePath(image);
    }

    function handleLogout() {
        dispatch(UserActions.logout());
    }

    function navigateToRoute(route) {
        return () => {
            history.push(route);
        };
    }

    const menuItems = [
        {
            text:     translator.t('profile'),
            iconType: IconType.user,
            onClick:  navigateToRoute(Routes.profile),
        },
        {
            text:     translator.t('settings'),
            iconType: IconType.settings,
            onClick:  navigateToRoute(Routes.settings),
        },
        {
            text:     translator.t('customerOnlineCalculator'),
            iconType: IconType.settingsBig,
            onClick:  navigateToRoute(Routes.customerOnlineCalculator),
        },
        {
            text:     translator.t('paymentData'),
            iconType: IconType.contract,
            onClick:  navigateToRoute(Routes.paymentData),
        },
        {
            text:     translator.t('logout'),
            iconType: IconType.logout,
            onClick:  handleLogout,
        },
    ];

    return (
        <StatelessTopBar
            menuItems={menuItems}
            name={name}
            avatar={avatar}
        />
    );
};

export default TopBar;
