//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Logo                 from '@assets/images/logo.png';
import AlertBoxManager      from '@connected/AlertBoxManager';
import Routes               from '@constants/Routes';
import Environment          from '@helper/Environment';
import useContextTranslator from '@hooks/ContextTranslator';
import { useAlertBox }      from '@slices/alertBox';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonSize           from '@stateless/atomic/Button/ButtonSize';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import LabeledInput         from '@stateless/atomic/LabeledInput';
import LabeledInputType     from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextInputType        from '@stateless/atomic/TextInput/TextInputType';
import PageTitle            from '@stateless/composed/PageTitle';
import SignupLoginSwitcher  from '@stateless/composed/SignupLoginSwitcher';

import styles from './styles.module.scss';

const Login = () => {
    const translator      = useContextTranslator('screens.login');
    const dispatch        = useDispatch();
    const userActions     = useUser(dispatch);
    const alertBoxActions = useAlertBox(dispatch);
    const username        = useSelector((state) => state.user.username);
    const password        = useSelector((state) => state.user.password);

    useEffect(() => {
        alertBoxActions.clearAlerts();
    }, []);

    function onAutoLoginButtonClicked(event) {
        event.preventDefault();

        const defaultUsername = 'info@lulububu.de';
        const defaultPassword = 'lulububu';

        userActions.loginWithCredentials({
            username: defaultUsername,
            password: defaultPassword,
        });
    }

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('LoginScreen: loginFormSubmitted');
        alertBoxActions.clearAlerts();
        userActions.login();
    }

    function passwordChanged(value) {
        userActions.setPassword({
            password: value,
        });
    }

    function usernameChanged(value) {
        userActions.setUsername({
            username: value,
        });
    }

    function renderAutoLoginButto() {
        if (Environment.isDevelopment()) {
            return (
                <Button
                    text={I18n.t('autoLogin')}
                    size={ButtonSize.big}
                    onClick={onAutoLoginButtonClicked}
                />
            );
        }

        return null;
    }

    return (
        <div className={styles.loginWrapper}>
            <PageTitle
                title={I18n.t('login')}
            />
            <img
                src={Logo}
                alt={'Logo'}
            />
            <Headline
                title={I18n.t('welcome')}
                type={HeadlineType.headline1}
            />
            <p>
                {I18n.t('loginText')}
            </p>
            <AlertBoxManager />
            <form onSubmit={onFormSubmit}>
                <LabeledInput
                    label={I18n.t('email')}
                    textInputType={TextInputType.email}
                    type={LabeledInputType.light}
                    onChange={usernameChanged}
                    value={username}
                />
                <LabeledInput
                    label={I18n.t('password')}
                    type={LabeledInputType.light}
                    textInputType={TextInputType.password}
                    onChange={passwordChanged}
                    value={password}
                />
                <Button
                    text={I18n.t('login')}
                    size={ButtonSize.big}
                />
                <SignupLoginSwitcher
                    text={translator.t('noAccount')}
                    targetText={translator.t('signupNow')}
                    target={Routes.signup}
                />
                <SignupLoginSwitcher
                    text={translator.t('passwordReset')}
                    targetText={translator.t('passwordResetNow')}
                    target={Routes.passwordReset}
                />
                {renderAutoLoginButto()}
            </form>
        </div>
    );
};

export default Login;
