//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import { useIndexedDB } from 'react-indexed-db-hook';

import ImageStorage from '@helper/ImageStorage';

const ImageStorageProvider = () => {
    const database = useIndexedDB(ImageStorage.getDefaultStoreName());

    useEffect(() => {
        ImageStorage.initializeDefaultDatabase(database);
    }, []);

    return null;
};

export default ImageStorageProvider;
