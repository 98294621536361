//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n   from 'i18next';
import styled from 'styled-components';

import styles from './styles.module.scss';

const propTypes = {};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const LinkWrapper = styled.div`
    a
    {
        &::after
        {
            background: ${(props) => props.theme.secondaryColor} !important;
        }

        &:hover
        {
            color: ${(props) => props.theme.secondaryColor} !important;
        }
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.footer}>
            <LinkWrapper className={styles.linkWrapper}>
                <a
                    href={I18n.t('impressUrl')}
                    target="_blank"
                    rel="noreferrer"
                >
                    {I18n.t('imprint')}
                </a>
                <a
                    href={I18n.t('privacyUrl')}
                    target="_blank"
                    rel="noreferrer"
                >
                    {I18n.t('privacy')}
                </a>
            </LinkWrapper>
            <div className={styles.copyright}>
                {I18n.t('copyRight', {
                    year: currentYear,
                })}
            </div>
        </div>
    );
};

Footer.propTypes = propTypes;

export default Footer;
