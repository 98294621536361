//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import chroma from 'chroma-js';

export default class ColorHelper {
    static getDarkerColor(color, factor) {
        if (!color) {
            return null;
        }

        return chroma(color).darken(factor).hex();
    }
}
