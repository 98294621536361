//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { Api }           from '@api';
import CalculationWidget from '@stateless/composed/CalculationWidget';

const WidgetScreen = () => {
    useEffect(() => {
        const script  = document.createElement('script');
        script.src    = Api.getUrl('iframeResizer.contentWindow.min.js');
        script.async  = true;
        script.onload = () => {
            console.log('WidgetScreen: Iframe resizer script loaded.');
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <CalculationWidget />
    );
};

export default WidgetScreen;
