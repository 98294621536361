//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

const civilStatus = Object.freeze({
    single:  'SINGLE',
    married: 'MARRIED',
});

export const getCivilStatusOptions = () => {
    return [
        {
            label: I18n.t('familyStatusOptions.single'),
            value: civilStatus.single,
        },
        {
            label: I18n.t('familyStatusOptions.married'),
            value: civilStatus.married,
        },
    ];
};

export default civilStatus;
