//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { push } from 'connected-react-router';
import _        from 'lodash';
import { put }  from 'redux-saga/effects';

import Routes                from '@constants/Routes';
import SagaStateHelper       from '@helper/SagaStateHelper';
import { AlertBoxActions }   from '@slices/alertBox';
import { BrowserActions }    from '@slices/browser';
import { NavigationActions } from '@slices/navigation';

function* locationChange() {
    const route = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );

    if (route === Routes.login) {
        yield put(NavigationActions.resetState());
    }

    yield put(AlertBoxActions.alertBoxLocationChange());
    yield put(BrowserActions.scrollToTop());
}

function* redirect(action) {
    const payload = _.get(action, 'payload', {});

    yield put(push(payload.route));
}

export default {
    locationChange,
    redirect,
};
// TODO-Skeleton end
