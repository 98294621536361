//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import PropTypes from '@components/PropTypes';
import styles    from '@stateless/atomic/TableHead/styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const TableHead = ({
    children = null,
}) => {
    return (
        <thead className={styles.tableHead}>
            {children}
        </thead>
    );
};

TableHead.propTypes = propTypes;

export default TableHead;
// TODO-Skeleton end
