//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames                     from 'classnames';
import { Link as ReactRouterDomLink } from 'react-router-dom';

import PropTypes  from '@components/PropTypes';
import Navigation from '@helper/Navigation';

import styles from './styles.module.scss';

const propTypes = {
    children:        PropTypes.children,
    className:       PropTypes.string,
    hash:            PropTypes.string,
    onClick:         PropTypes.func,
    search:          PropTypes.object,
    stopPropagation: PropTypes.bool,
    target:          PropTypes.string,
    to:              PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

const Link = ({
    children        = [],
    className       = '',
    hash            = '',
    onClick         = null,
    search          = {},
    stopPropagation = true,
    target          = null,
    to              = null,
}) => {
    const toParameter = Navigation.getToFromRouteObject(to, search, hash);

    function stopPropagationAndExecuteOnClick(event) {
        if (stopPropagation) {
            event.stopPropagation();
        }

        if (onClick) {
            onClick(event);
        }
    }

    return (
        <ReactRouterDomLink
            className={classNames(styles.link, className)}
            to={toParameter}
            target={target}
            onClick={stopPropagationAndExecuteOnClick}
        >
            {children}
        </ReactRouterDomLink>
    );
};

Link.propTypes = propTypes;

export default Link;
