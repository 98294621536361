//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class Merge {
    static keepUndefinedInMerge(objectValue, sourceValue) {
        if (sourceValue === undefined) {
            return '';
        }

        return undefined;
    }

    static mergeWith(objectOne, objectTwo) {
        return _.mergeWith(
            _.cloneDeep(objectOne),
            _.cloneDeep(objectTwo),
            Merge.keepUndefinedInMerge,
        );
    }
}

export default Merge;
