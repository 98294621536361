//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';

import StatelessBreadCrumbBar from '@stateless/atomic/BreadCrumbBar';
import selectPathname         from '@store/selectors/router';

const BreadCrumbBar = () => {
    const pathname = useSelector(selectPathname);

    return (
        <StatelessBreadCrumbBar pathname={pathname} />
    );
};

export default BreadCrumbBar;
