//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Notification from '@helper/Notification';

class ValidationHelper {
    static getValidations = (schema, object, validationsSetter) => {
        try {
            schema.validateSync(
                object,
                {
                    abortEarly: false,
                },
            );

            if (validationsSetter) {
                validationsSetter({});
            }

            return {};
        } catch (validationError) {
            return validationError.inner.reduce((errors, current) => {
                const validations = {
                    ...errors,
                    [current.path]: current.message,
                };

                if (validationsSetter) {
                    validationsSetter(validations);
                }

                return validations;
            }, {});
        }
    };

    static hasNoValidations = (validations) => {
        if (_.isObject(validations)) {
            return _.isEmpty(Object.keys(validations));
        }

        return _.isEmpty(validations);
    };

    static isValidEmail = (email) => {
        if (!email) {
            return false;
        }

        return email.match(/^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    static showCustomError = (validations, errorMessages) => {
        const values = Object.values(validations);

        for (const field in errorMessages) {
            if (_.includes(values, field)) {
                Notification.error(errorMessages[field]);

                break;
            }

            if (_.has(validations, field)) {
                Notification.error(errorMessages[field]);

                break;
            }
        }
    };
}

export default ValidationHelper;
