//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Cast         from '@helper/Cast';
import NumberFormat from '@helper/NumberFormat';

class NumberMapper {
    static mapFieldsToNumber(object, fields) {
        const mappedNumbers = {};

        _.forEach(fields, (field) => {
            const formattedString = _.get(object, field);

            if (_.isNil(formattedString)) {
                return;
            }

            const correctNumber = NumberFormat.getValueString(formattedString);

            if (correctNumber) {
                _.set(mappedNumbers, field, Cast.float(formattedString));
            }
        });

        return {
            ...object,
            ...mappedNumbers,
        };
    }
}

export default NumberMapper;
