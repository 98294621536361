//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Redirect }    from 'react-router';
import { useHistory }  from 'react-router';

import Routes       from '@constants/Routes';
import Notification from '@helper/Notification';
import selectToken  from '@store/selectors/user';

const EmailConfirmedScreen = () => {
    const history = useHistory();
    const token   = useSelector(selectToken);
    const target  = (
        token ?
            Routes.dashboard :
            Routes.login
    );

    useEffect(
        () => {
            Notification.success('emailConfirmed');
        },
        [history],
    );

    return (
        <Redirect to={target} />
    );
};

export default EmailConfirmedScreen;
