//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _                        from 'lodash';
import { useSelector }          from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import PropTypes                from '@components/PropTypes';
import CalculationFields        from '@constants/CalculationFields';
import CalculationHelper        from '@helper/CalculationHelper';
import IFrameHelper             from '@helper/IFrameHelper';
import useAppTheme              from '@hooks/AppTheme';
import useElementDimensions     from '@hooks/element-dimension';
import Icon                     from '@stateless/atomic/Icon';
import IconType                 from '@stateless/atomic/Icon/IconType';
import LabeledWrapper           from '@stateless/composed/LableledWrapper';
import OverlayModal             from '@stateless/composed/OverlayModal';
import selectCurrentCalculation from '@store/selectors/calculation';

import styles from './styles.module.scss';

const propTypes = {
    chartName:         PropTypes.string,
    columns:           PropTypes.number,
    contentFullHeight: PropTypes.bool,
    label:             PropTypes.string,
    renderModal:       PropTypes.bool,
    wrapperFullHeight: PropTypes.bool,
};

const CalculationFrameChart = ({
    label             = '',
    columns           = 1,
    chartName         = null,
    wrapperFullHeight = false,
    contentFullHeight = true,
    renderModal       = true,
}) => {
    const calculation               = useSelector(selectCurrentCalculation);
    const [showModal, setShowModal] = useState(false);
    const reference                 = React.createRef();
    const { width, height }         = useElementDimensions(reference);
    const equityCapital             = _.get(calculation, CalculationFields.equityCapital);
    const [iFrameUrl, setIFrameUrl] = useState(null);
    const appTheme                  = useAppTheme();

    function getFrameUrl() {
        if (_.isNil(equityCapital)) {
            return null;
        }

        return IFrameHelper.getIFrameChartUrl(
            chartName,
            CalculationHelper.mapCalculationToCalculationSource(calculation),
            width,
            height,
            appTheme,
        );
    }

    const debouncedUpdateIFrameUrl = useDebouncedCallback(() => {
        setIFrameUrl(getFrameUrl());
    });

    useEffect(
        () => {
            debouncedUpdateIFrameUrl();
        },
        [
            calculation,
            width,
            height,
        ],
    );

    function renderChart() {
        return (
            <LabeledWrapper
                label={label}
                columns={columns}
                reference={reference}
                minHeight={250}
                contentFullHeight={contentFullHeight}
                fullHeight={wrapperFullHeight}
            >
                <iframe
                    src={iFrameUrl}
                    width={`${width}px`}
                    height={`${height}px`}
                    className={styles.frameChart}
                    allowFullScreen={false}
                    loading={'lazy'}
                />
            </LabeledWrapper>
        );
    }

    function onModalClose() {
        setShowModal(false);
    }

    function onZoomClicked() {
        setShowModal(true);
    }

    function renderModalChart() {
        if (!renderModal) {
            return null;
        }

        return (
            <OverlayModal
                show={showModal}
                onClose={onModalClose}
            >
                <CalculationFrameChart
                    label={label}
                    chartName={chartName}
                    renderModal={false}
                    wrapperFullHeight={true}
                />
            </OverlayModal>
        );
    }

    function renderZoom() {
        if (!renderModal) {
            return null;
        }

        return (
            <div
                className={styles.zoom}
                onClick={onZoomClicked}
            >
                <Icon
                    iconType={IconType.zoomBox}
                />
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            {renderModalChart()}
            <div className={styles.frameChartWrapper}>
                {renderChart()}
            </div>
            {renderZoom()}
        </div>
    );
};

CalculationFrameChart.propTypes = propTypes;

export default CalculationFrameChart;
