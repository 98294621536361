//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import UnitStatus         from '@constants/UnitStatus';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    availableTotalSquareMeters: 0,
    calculationAmount:          0,
    totalVolume:                {
        [UnitStatus.available]: 0,
        [UnitStatus.sold]:      0,
        [UnitStatus.reserved]:  0,
    },
    unitsOverview:              {
        [UnitStatus.available]: 0,
        [UnitStatus.sold]:      0,
        [UnitStatus.reserved]:  0,
    },
});

const dashboardSlice = createSlice({
    name:     'dashboard',
    initialState,
    reducers: {
        fetchDashboard:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.dashboard')),
        fetchDashboardFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchDashboardSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                $set: action.payload.dashboard,
            });
        }),
        reset:                   () => {
            return {
                ...initialState,
            };
        },
    },
});

export const DashboardActions = dashboardSlice.actions;

export const DashboardReducer = dashboardSlice.reducer;

export const useDashboard = (dispatch) => bindActionCreators(DashboardActions, dispatch);

export default dashboardSlice;
