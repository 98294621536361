//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import UserFields           from '@constants/UserFields';
import Formatter            from '@helper/Formatter';
import InputRenderHelper    from '@helper/InputRenderHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import LabeledWrapper       from '@stateless/composed/LableledWrapper';
import LayoutScreen         from '@stateless/composed/LayoutScreen';
import PageTitle            from '@stateless/composed/PageTitle';
import TextSliderType       from '@stateless/composed/TextSliderInput/TextSliderType';
import { selectUser }       from '@store/selectors/user';

import styles from './styles.module.scss';

const SettingsScreen = () => {
    const translator  = useContextTranslator('screens.settingsScreen');
    const user        = useSelector(selectUser);
    const dispatch    = useDispatch();
    const userActions = useUser(dispatch);

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            userActions.updateUserData({
                user: changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations: [],
        data:        user,
        onUpdateData,
    });

    function getPath(field) {
        return [UserFields.baseSettings, UserFields.calculationFields, field];
    }

    function onSaveSettingsClicked() {
        userActions.saveSettings();
    }

    function renderIntervalSlider({
        label,
        field,
    }) {
        const path = getPath(field);

        return inputRenderer.renderTextSlider({
            label:        translator.t(label),
            field:        path,
            formatter:    Formatter.formatToInterval,
            maximumValue: 10,
            step:         1,
            minimumValue: 1,
            type:         TextSliderType.intervalInYears,
        });
    }

    function renderPercentSlider({
        label,
        field,
        maximumValue = 10,
        step = 0.5,
        minimumValue = 0,
    }) {
        const path = getPath(field);

        return inputRenderer.renderTextSlider({
            label: translator.t(label),
            field: path,
            maximumValue,
            step,
            minimumValue,
        });
    }

    return (
        <>
            <PageTitle
                title={I18n.t('settings')}
            />
            <LayoutScreen>
                <div className={styles.settingsScreen}>
                    <Headline
                        title={translator.t('headline')}
                        type={HeadlineType.headline1}
                    />
                    <p>
                        {translator.t('text')}
                    </p>
                    <div className={styles.settingsInputWrapper}>
                        <LabeledWrapper
                            label={translator.t('settings')}
                            columns={1}
                        >
                            {renderPercentSlider({
                                label:        'interest',
                                field:        UserFields.interest,
                                maximumValue: 5,
                                step:         0.05,
                                minimumValue: 0.5,
                            })}
                            {renderPercentSlider({
                                label:        'redemption',
                                field:        UserFields.redemption,
                                maximumValue: 4,
                                minimumValue: 0.5,
                                step:         0.05,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label: translator.t('toBeTaxedYearlyIncome'),
                                field: [UserFields.baseSettings, UserFields.annualTaxableIncome],
                            })}
                            {renderPercentSlider({
                                label: 'increaseIncomeInPercent',
                                field: UserFields.increaseInIncome,
                            })}
                            {renderIntervalSlider({
                                label: 'increaseIncomeIntervalInYears',
                                field: UserFields.increaseInIncomeInterval,
                            })}
                            {renderPercentSlider({
                                label: 'increaseRentInPercent',
                                field: UserFields.rentIncrease,
                            })}
                            {renderIntervalSlider({
                                label: 'increaseRentIntervalInYears',
                                field: UserFields.rentIncreaseInterval,
                            })}
                            {renderPercentSlider({
                                label: 'increaseOperatingCostsInPercent',
                                field: UserFields.increaseInOperatingCosts,
                            })}
                            {renderIntervalSlider({
                                label: 'increaseOperatingCostsInterval',
                                field: UserFields.increaseInOperatingCostsInterval,
                            })}
                            {renderPercentSlider({
                                label: 'increaseObjectValueInPercent',
                                field: UserFields.increaseInValue,
                            })}
                            {renderIntervalSlider({
                                label: 'increaseObjectValueInterval',
                                field: UserFields.increaseInValueInterval,
                            })}
                            <Button
                                type={ButtonType.edgy}
                                text={translator.t('saveSettings')}
                                onClick={onSaveSettingsClicked}
                            />
                        </LabeledWrapper>
                    </div>
                </div>
            </LayoutScreen>
        </>
    );
};

export default SettingsScreen;
