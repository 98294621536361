//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    checked:        PropTypes.bool,
    noMarginBottom: PropTypes.bool,
    onClick:        PropTypes.func,
    text:           PropTypes.string,
};

const CheckBox = ({
    checked        = false,
    noMarginBottom = false,
    onClick        = DefaultTypes.func,
    text           = '',
}) => {
    function renderIcon() {
        if (checked) {
            return (
                <Icon
                    iconType={IconType.check}
                />
            );
        }

        return null;
    }

    function renderCheckBox() {
        return (
            <div
                className={
                    classNames(
                        styles.checkBox,
                        {
                            [styles.checkBoxChecked]: checked,
                        },
                    )
                }
            >
                {renderIcon()}
            </div>
        );
    }

    return (
        <div
            className={
                classNames(
                    styles.checkBoxWrapper,
                    {
                        [styles.checkBoxWrapperNoMarginBottom]: noMarginBottom,
                    },
                )
            }
        >
            <div
                className={styles.checkBoxWrapperClickArea}
                onClick={onClick}
            >
                {renderCheckBox()}
                <div className={styles.checkBoxWrapperLabel}>
                    {text}
                </div>
            </div>
        </div>
    );
};

CheckBox.propTypes = propTypes;

export default CheckBox;
// TODO-Skeleton end
