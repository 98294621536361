//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useSelector } from 'react-redux';

import Colors from '@styles/colors.scss';

const fallbackAppTheme = {
    backgroundColor: Colors.darkerAbyss,
    primaryColor:    Colors.darkAbyss,
    secondaryColor:  Colors.lightGreen,
    textColor:       Colors.white,
    buttonTextColor: Colors.black,
};

const useAppTheme = () => {
    const customAppTheme = useSelector((state) => state.user.user.appTheme);

    return customAppTheme ?? fallbackAppTheme;
};

export default useAppTheme;
