//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    dashboard:                '/',
    login:                    '/login',
    signup:                   '/signup',
    guide:                    '/guide',
    profile:                  '/profile',
    settings:                 '/settings',
    customerOnlineCalculator: '/customer-online-calculator',
    paymentData:              '/payment-data',
    passwordReset:            '/password-reset',
    lostPassword:             '/lost-password',
    calculation:              '/calculation',
    calculationCustomer:      '/calculation/customer',
    calculationObject:        '/calculation/object',
    calculationFinance:       '/calculation/finance',
    calculationResult:        '/calculation/result',
    objects:                  '/objects',
    editObjects:              '/objects/edit',
    editUnit:                 '/objects/unit/edit',
    customers:                '/customers',
    editCustomer:             '/customers/edit',
    widget:                   '/widget',
    orderResponse:            '/bestellung-antwort',
    paymentFailed:            '/zahlung-fehlgeschlagen',
    emailConfirmed:           '/email_validated/:userId',
};
