//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes            from '@components/PropTypes';
import { useAlertBox }      from '@slices/alertBox';
import AlertBoxType         from '@stateless/atomic/AlertBox/AlertBoxType';
import AlertBoxWrapper      from '@stateless/composed/AlertBoxWrapper';
import { selectAlertBoxes } from '@store/selectors/alertBox';

const propTypes = {
    filterByType: PropTypes.oneOfObjectValues(AlertBoxType),
};

const AlertBoxManager = ({
    filterByType = null,
}) => {
    const dispatch        = useDispatch();
    const alertBoxes      = useSelector(selectAlertBoxes);
    const alertBoxActions = useAlertBox(dispatch);

    function getAlertBoxes() {
        if (filterByType) {
            return _.filter(
                alertBoxes,
                {
                    type: filterByType,
                },
            );
        }

        return alertBoxes;
    }

    function hideButtonPressed(index) {
        return () => {
            alertBoxActions.clearSingleAlert({
                index,
            });
        };
    }

    return (
        <AlertBoxWrapper
            alertBoxes={getAlertBoxes()}
            hideButtonPressed={hideButtonPressed}
        />
    );
};

AlertBoxManager.propTypes = propTypes;

export default AlertBoxManager;
// TODO-Skeleton end
