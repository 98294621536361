//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import PropTypes            from '@components/PropTypes';
import IFrameHelper         from '@helper/IFrameHelper';
import useAppTheme          from '@hooks/AppTheme';
import useElementDimensions from '@hooks/element-dimension';

import styles from './styles.module.scss';

const propTypes = {
    chartName:  PropTypes.string,
    parameters: PropTypes.object,
};

const DashboardFrameChart = ({
    chartName  = '',
    parameters = {},
}) => {
    const reference         = React.createRef();
    const { width, height } = useElementDimensions(reference);
    const appTheme          = useAppTheme();

    function getFrameUrl() {
        return IFrameHelper.getIFrameChartUrl(
            chartName,
            parameters,
            width,
            height,
            appTheme,
        );
    }

    const chart = useMemo(
        () => {
            if (
                !width ||
                !height
            ) {
                return null;
            }

            return (
                <iframe
                    src={getFrameUrl()}
                    width={'100%'}
                    height={'100%'}
                    className={styles.frameChart}
                    allowFullScreen={false}
                    loading={'lazy'}
                />
            );
        },
        [width, height, parameters],
    );

    return (
        <div
            ref={reference}
            className={styles.wrapper}
        >
            {chart}
        </div>
    );
};

DashboardFrameChart.propTypes = propTypes;

export default DashboardFrameChart;
