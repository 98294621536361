//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { css } from 'styled-components';

export const svgColor = (color) => css`
    svg
    {
        .fill
        {
            fill: ${color} !important;
        }

        .stroke
        {
            stroke: ${color} !important;
        }
    }
`;

export default {
    svgColor,
};
