//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    configuration: {},
});

const configurationSlice = createSlice({
    name:     'configuration',
    initialState,
    reducers: {
        fetchConfiguration:          LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loading.configuration'),
        ),
        fetchConfigurationFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        fetchConfigurationSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                configuration: {
                    $set: _.get(action, 'payload.configuration', {}),
                },
            });
        }),
    },
});

export const ConfigurationActions = configurationSlice.actions;

export const ConfigurationReducer = configurationSlice.reducer;

export default configurationSlice;
