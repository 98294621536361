//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import PropTypes from '@components/PropTypes';
import Number    from '@stateless/atomic/Number';

const propTypes = {
    value: PropTypes.number,
};

const CurrencyLabel = ({
    value = 0,
}) => {
    return (
        <Number
            suffix={I18n.t('format.priceSuffix')}
            value={value}
        />
    );
};

CurrencyLabel.propTypes = propTypes;

export default CurrencyLabel;
