//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';

import CalculationHelper        from '@helper/CalculationHelper';
import Formatter                from '@helper/Formatter';
import LabelWidget              from '@stateless/atomic/LabelWidget';
import selectCurrentCalculation from '@store/selectors/calculation';

const propTypes = {};

const CalculationResultWidget = () => {
    const calculation     = useSelector(selectCurrentCalculation);
    const monthlyCashflow = CalculationHelper.getMonthlyCashflow(calculation);
    const cashflowTitle   = (
        monthlyCashflow < 0 ?
            I18n.t('resultMonthlyEffort') :
            I18n.t('resultMonthlyProfit')
    );

    return (
        <LabelWidget
            title={I18n.t(cashflowTitle)}
            value={Formatter.formatToCurrency()(Math.abs(monthlyCashflow))}
        />
    );
};

CalculationResultWidget.propTypes = propTypes;

export default CalculationResultWidget;
