//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n      from 'i18next';
import _         from 'lodash';
import { Trans } from 'react-i18next';
import styled    from 'styled-components';

import VideoPoster          from '@assets/images/video_poster.png';
import Tutorial1            from '@assets/videos/tutorial_1.mp4';
import Tutorial2            from '@assets/videos/tutorial_2.mp4';
import Routes               from '@constants/Routes';
import useContextTranslator from '@hooks/ContextTranslator';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import LayoutScreen         from '@stateless/composed/LayoutScreen';
import PageTitle            from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const GuideScreenWrapper = styled.div`
    a
    {
        color: ${(props) => props.theme.secondaryColor};
    }
`;

const GuideScreen = () => {
    const translator = useContextTranslator('screens.guideScreen');
    const guideSteps = [
        {
            header: translator.t('setupProfile'),
            text:   (
                <Trans i18nKey={'screens.guideScreen.setupProfileText'}>
                    <a href={Routes.profile} />
                </Trans>
            ),
        },
        {
            header: translator.t('setupSettings'),
            text:   (
                <Trans i18nKey={'screens.guideScreen.setupSettingsText'}>
                    <a href={Routes.settings} />
                </Trans>
            ),
        },
        {
            header: translator.t('createObject'),
            text:   (
                <Trans i18nKey={'screens.guideScreen.createObjectText'}>
                    <a href={Routes.objects} />
                </Trans>
            ),
        },
        {
            header: translator.t('createCustomer'),
            text:   (
                <Trans i18nKey={'screens.guideScreen.createCustomerText'}>
                    <a href={Routes.customers} />
                </Trans>
            ),
        },
        {
            header: translator.t('executeCalculation'),
            text:   (
                <Trans i18nKey={'screens.guideScreen.executeCalculationText'}>
                    <a href={Routes.calculationCustomer} />
                </Trans>
            ),
        },
        {
            text: (
                <Trans i18nKey={'screens.guideScreen.faqText'}>
                    <a href={I18n.t('components.faqInfoWidget.faqUrl')} />
                </Trans>
            ),
        },
        {
            text: (
                <Trans i18nKey={'screens.guideScreen.contactText'}>
                    <a href={`mailto:${I18n.t('supportEmail')}`} />
                </Trans>
            ),
        },
    ];
    const videoData  = [
        {
            header: translator.t('tutorialVideo1'),
            src:    Tutorial1,
            poster: VideoPoster,
        },
        {
            header: translator.t('tutorialVideo1'),
            src:    Tutorial2,
            poster: VideoPoster,
        },
    ];

    function renderGuideSteps() {
        return _.map(guideSteps, (step, index) => {
            function renderHeadline() {
                if (!step.header) {
                    return null;
                }

                const headline = `${index + 1}. ${step.header}`;

                return (
                    <Headline
                        type={HeadlineType.headline3}
                        title={headline}
                    />
                );
            }

            return (
                <li key={step.header}>
                    {renderHeadline()}
                    <div>
                        {step.text}
                    </div>
                </li>
            );
        });
    }

    function renderVideos() {
        return _.map(videoData, (video) => {
            return (
                <>
                    <Headline
                        type={HeadlineType.headline3}
                        title={video.header}
                    />
                    <video
                        controls
                        poster={video.poster}
                        key={video.header}
                    >
                        <source
                            src={video.src}
                            type="video/mp4"
                        />
                    </video>
                </>
            );
        });
    }

    return (
        <>
            <PageTitle
                title={I18n.t('guide')}
            />
            <LayoutScreen>
                <GuideScreenWrapper className={styles.guideScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('headline')}
                    />
                    <Headline
                        type={HeadlineType.headline2}
                        title={translator.t('subTitle')}
                    />
                    <div className={styles.divider}>
                        <ul>
                            {renderGuideSteps()}
                        </ul>
                        <div>
                            {renderVideos()}
                        </div>
                    </div>
                </GuideScreenWrapper>
            </LayoutScreen>
        </>
    );
};

export default GuideScreen;
