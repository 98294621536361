//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import { Trans } from 'react-i18next';

import PropTypes from '@components/PropTypes';

const propTypes = {
    translationKey: PropTypes.string,
    values:         PropTypes.object,
};

const MarkupTranslation = ({
    translationKey = null,
    values         = {},
}) => {
    const bold       = (
        <strong />
    );
    const components = {
        bold,
    };

    return (
        <Trans
            i18nKey={translationKey}
            values={values}
            components={components}
        />
    );
};

MarkupTranslation.propTypes = propTypes;

export default MarkupTranslation;
// TODO-Skeleton end
