//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import { Api }                from '@api';
import Routes                 from '@constants/Routes';
import Notification           from '@helper/Notification';
import useContextTranslator   from '@hooks/ContextTranslator';
import Button                 from '@stateless/atomic/Button';
import ButtonType             from '@stateless/atomic/Button/ButtonType';
import Headline               from '@stateless/atomic/Headline';
import HeadlineType           from '@stateless/atomic/Headline/HeadlineType';
import IconType               from '@stateless/atomic/Icon/IconType';
import Spacer                 from '@stateless/atomic/Spacer';
import ModalOverlay           from '@stateless/composed/Modal';
import selectLoading          from '@store/selectors/loading';
import { selectUserIri }      from '@store/selectors/user';
import { selectUserContract } from '@store/selectors/user';
import { selectToken }        from '@store/selectors/user';

import styles from './styles.module.scss';

const TrialModal = () => {
    const translator     = useContextTranslator('components.trialBanner');
    const userIri        = useSelector(selectUserIri);
    const token          = useSelector(selectToken);
    const contract       = useSelector(selectUserContract);
    const isLoading      = useSelector(selectLoading);
    const history        = useHistory();
    const showTrialModal = (
        _.isNil(contract) &&
        token &&
        !isLoading
    );

    function onSignupClicked() {
        window.location.href = Api.getUrl(`${userIri}/start-trial`);
    }

    useEffect(() => {
        const unblock = history.block((location) => {
            if (
                showTrialModal &&
                location.pathname !== Routes.login
            ) {
                Notification.error('pleaseStartTrial');

                return false;
            }

            return true;
        });

        return () => unblock();
    }, [history, showTrialModal]);

    return (
        <ModalOverlay
            disableAutoClose={true}
            show={showTrialModal}
        >
            <Headline
                title={translator.t('headline')}
                headlineType={HeadlineType.headline2}
            />
            <Spacer height={20} />
            <p className={styles.bannerText}>
                {translator.t('text')}
            </p>
            <Spacer height={20} />
            <Button
                type={ButtonType.edgy}
                text={translator.t('button')}
                iconLeft={IconType.circlePlus}
                onClick={onSignupClicked}
            />
        </ModalOverlay>
    );
};

export default TrialModal;
