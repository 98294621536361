//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _             from 'lodash';
import CurrencyInput from 'react-currency-input-field';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import Cast                   from '@helper/Cast';
import InputClassHelper       from '@helper/InputClassHelper';
import InputWrapper           from '@stateless/atomic/InputWrapper';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';

import styles from './styles.module.scss';

const propTypes = {
    decimalsLimit:   PropTypes.number,
    disabled:        PropTypes.bool,
    formatToFixed:   PropTypes.bool,
    intlConfig:      PropTypes.object,
    minLength:       PropTypes.number,
    multiline:       PropTypes.bool,
    onBlur:          PropTypes.func,
    onChange:        PropTypes.func,
    onFocus:         PropTypes.func,
    onKeyDown:       PropTypes.func,
    placeholderText: PropTypes.string,
    postIconType:    PropTypes.iconType,
    prefix:          PropTypes.string,
    size:            PropTypes.oneOfObjectValues(TextInputStyle),
    suffix:          PropTypes.string,
    textAlignment:   PropTypes.oneOfObjectValues(TextInputTextAlignment),
    value:           PropTypes.date,
};

const CurrencyInputWrapper = ({
    decimalsLimit   = 2,
    disabled        = false,
    formatToFixed   = false,
    intlConfig      = {
        locale:   'de-DE',
        currency: 'EUR',
    },
    minLength       = 1,
    multiline       = false,
    onBlur          = DefaultTypes.func,
    onChange        = DefaultTypes.func,
    onFocus         = DefaultTypes.func,
    onKeyDown       = DefaultTypes.func,
    placeholderText = null,
    postIconType    = null,
    prefix          = null,
    size            = TextInputStyle.default,
    suffix          = null,
    textAlignment   = TextInputTextAlignment.left,
    value           = null,
}) => {
    let valueToUse = value;

    function onChangeModeCurrency(newValue, name, values) {
        const targetFloatValue  = _.get(values, ['float']);
        const targetStringValue = _.get(values, ['value']);

        onChange(newValue, targetFloatValue, targetStringValue);
    }

    if (
        decimalsLimit > 0 &&
        _.isNil(value) === false &&
        (
            formatToFixed ||
            disabled
        )
    ) {
        valueToUse = Cast.float(value).toFixed(decimalsLimit);
    }

    function renderInputModeCurrency() {
        const className = InputClassHelper.getInputClassNames(
            styles,
            disabled,
            multiline,
            postIconType,
        );

        return (
            <CurrencyInput
                className={className}
                disabled={disabled}
                minLength={minLength}
                onBlur={onBlur}
                onFocus={onFocus}
                onValueChange={onChangeModeCurrency}
                onKeyDown={onKeyDown}
                placeholder={placeholderText}
                allowNegative={true}
                intlConfig={intlConfig}
                decimalsLimit={decimalsLimit}
                suffix={suffix}
                defaultValue={valueToUse}
                value={valueToUse}
                prefix={prefix}
            />
        );
    }

    return (
        <InputWrapper
            postIconType={postIconType}
            textAlignment={textAlignment}
            size={size}
        >
            {renderInputModeCurrency()}
        </InputWrapper>
    );
};

CurrencyInputWrapper.propTypes = propTypes;

export default CurrencyInputWrapper;
