//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes    from '@components/PropTypes';
import Number       from '@stateless/atomic/Number';
import WidgetButton from '@stateless/composed/WidgetButton';
import Colors       from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    accentColor:  PropTypes.string,
    fontColor:    PropTypes.string,
    onClick:      PropTypes.func,
    primaryColor: PropTypes.string,
    resultInEuro: PropTypes.number,
};

const WidgetResult = ({
    accentColor  = Colors.lightGreen,
    fontColor    = Colors.white,
    onClick      = _.noop,
    primaryColor = Colors.darkGreen,
    resultInEuro = 0,
}) => {
    const backgroundStyle      = {
        backgroundColor: primaryColor,
    };
    const fontColorStyle       = {
        color: fontColor,
    };
    const effortOrSurplusTitle = (
        resultInEuro < 0 ?
            I18n.t('components.widgetResult.monthlyEffort') :
            I18n.t('components.widgetResult.monthlySurplus')
    );

    return (
        <div
            className={styles.widgetResult}
            style={backgroundStyle}
        >
            <span
                className={styles.resultHeadline}
                style={fontColorStyle}
            >
                {effortOrSurplusTitle}
            </span>
            <div
                className={styles.resultValue}
                style={fontColorStyle}
            >
                <Number
                    value={Math.abs(resultInEuro)}
                    suffix={I18n.t('format.priceSuffix')}
                    decimalScale={0}
                />
            </div>
            <WidgetButton
                $fontColor={fontColor}
                $primaryColor={primaryColor}
                $accentColor={accentColor}
                onClick={onClick}
            >
                {I18n.t('components.widgetResult.download')}
            </WidgetButton>
            <div className={styles.poweredBy}>
                <span>
                    {I18n.t('components.widgetResult.poweredBy')}
                </span>
                <a
                    href={I18n.t('components.widgetResult.poweredByLink')}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    {I18n.t('components.widgetResult.poweredByLinkName')}
                </a>
            </div>
        </div>
    );
};

WidgetResult.propTypes = propTypes;

export default WidgetResult;
