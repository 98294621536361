//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import BreadCrumbBar            from '@connected/BreadCrumbBar';
import ResidentialUnitTable     from '@connected/ResidentialUnitTable';
import useContextTranslator     from '@hooks/ContextTranslator';
import Headline                 from '@stateless/atomic/Headline';
import HeadlineType             from '@stateless/atomic/Headline/HeadlineType';
import Spacer                   from '@stateless/atomic/Spacer';
import CalculationNavigationBar from '@stateless/composed/CalculationNavigationBar';
import LayoutScreen             from '@stateless/composed/LayoutScreen';
import PageTitle                from '@stateless/composed/PageTitle';
import { selectObjects }        from '@store/selectors/object';

import styles from './styles.module.scss';

const CalculationObjectScreen = () => {
    const translator = useContextTranslator('screens.calculationScreen');
    const objects    = useSelector(selectObjects);

    function renderResidentialUnitTables() {
        return _.map(objects, (object) => {
            return (
                <ResidentialUnitTable
                    key={object.id}
                    data={object}
                />
            );
        });
    }

    return (
        <>
            <PageTitle
                title={I18n.t('calculation')}
            />
            <LayoutScreen>
                <BreadCrumbBar />
                <div className={styles.calculationObjectScreen}>
                    <div className={styles.headerNavigationRow}>
                        <Headline
                            type={HeadlineType.headline2}
                            title={translator.t('calculationObjectTitle')}
                        />
                        <CalculationNavigationBar />
                    </div>
                    <Spacer height={10} />
                    {renderResidentialUnitTables()}
                    <CalculationNavigationBar />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CalculationObjectScreen;
