//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import CalculationFields from '@constants/CalculationFields';
import CustomerFields    from '@constants/CustomerFields';
import NumberMapper      from '@store/helper/NumberMapper';

class CalculationHelper {
    static getMonthlyCashflow(calculation) {
        const resultCashflow      = _.get(calculation, [CalculationFields.calculationResult, CalculationFields.cashflowPerMonth]);
        const calculationCashflow = _.get(calculation, CalculationFields.cashflowPerMonth, 0);

        if (!_.isNil(resultCashflow)) {
            return resultCashflow;
        }

        return calculationCashflow;
    }

    static mapCalculationToCalculationSource(calculation) {
        const customer = _.get(calculation, CalculationFields.customer, {});

        return NumberMapper.mapFieldsToNumber(
            {
                ..._.omit(calculation, [
                    CalculationFields.calculationFields,
                    CalculationFields.equityCapital,
                    CalculationFields.customer,
                    CalculationFields.calculationResult,
                    CalculationFields.step,
                ]),
                ..._.get(calculation, CalculationFields.calculationFields, {}),
                equity:                       _.get(calculation, CalculationFields.equityCapital, 0),
                income:                       _.get(customer, CustomerFields.annualTaxableIncome, null),
                [CustomerFields.civilStatus]: _.get(customer, CustomerFields.civilStatus, 0),
                [CustomerFields.churchTax]:   _.get(customer, CustomerFields.churchTax, false),
                unit:                         _.get(calculation, 'unit.iri', null),
            },
            [
                CalculationFields.equity,
                CalculationFields.income,
                CalculationFields.interest,
                CalculationFields.redemption,
                CalculationFields.increaseInIncome,
                CalculationFields.increaseInIncomeInterval,
                CalculationFields.increaseInValue,
                CalculationFields.increaseInValueInterval,
                CalculationFields.increaseInOperatingCosts,
                CalculationFields.increaseInOperatingCostsInterval,
                CalculationFields.rentIncrease,
                CalculationFields.rentIncreaseInterval,
            ],
        );
    }

    static getEMailAddress(calculation) {
        const customer      = _.get(calculation, CalculationFields.customer, {});
        const contactPerson = _.get(customer, CustomerFields.contactPersonOne);
        let email           = _.get(contactPerson, CustomerFields.contactPersonEmail);

        if (!email) {
            const contactPersonTwo = _.get(customer, CustomerFields.contactPersonTwo);

            email = _.get(contactPersonTwo, CustomerFields.contactPersonEmail);
        }

        return email;
    }
}

export default CalculationHelper;
