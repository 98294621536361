//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import styled     from 'styled-components';

import PropTypes    from '@components/PropTypes';
import { svgColor } from '@helper/StyledMixins';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    active:    PropTypes.bool,
    firstItem: PropTypes.bool,
    iconType:  PropTypes.iconType,
    text:      PropTypes.string,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const BreadCrumbItemWrapper = styled.div`
    &.active
    {
        > span
        {
            ${(props) => svgColor(props.theme.secondaryColor)}
        }

        .content
        {
            background-color: ${(props) => props.theme.secondaryColor};
        }
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const BreadCrumbItem = ({
    firstItem = false,
    iconType  = null,
    text      = '',
    active    = false,
}) => {
    function renderIcon() {
        if (!iconType) {
            return null;
        }

        return (
            <Icon iconType={iconType} />
        );
    }

    function renderBreadCrumbEnd() {
        if (firstItem) {
            return null;
        }

        return (
            <Icon iconType={IconType.breadCrumbEnd} />
        );
    }

    return (
        <BreadCrumbItemWrapper
            className={classNames(
                styles.breadcrumbItem,
                {
                    'active': active,
                },
            )}
        >
            {renderBreadCrumbEnd()}
            <div className={'content'}>
                {renderIcon()}
                {text}
            </div>
            <Icon iconType={IconType.breadCrumbStart} />
        </BreadCrumbItemWrapper>
    );
};

BreadCrumbItem.propTypes = propTypes;

export default BreadCrumbItem;
