//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames                     from 'classnames';
import _                              from 'lodash';
import { Trans }                      from 'react-i18next';
import { useDispatch }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';
import { ref }                        from 'yup';
import { boolean }                    from 'yup';

import Logo                 from '@assets/images/logo.png';
import Routes               from '@constants/Routes';
import Security             from '@constants/Security';
import UserFields           from '@constants/UserFields';
import ValidationError      from '@constants/ValidationError';
import InputRenderHelper    from '@helper/InputRenderHelper';
import ValidationHelper     from '@helper/ValidationHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonSize           from '@stateless/atomic/Button/ButtonSize';
import CheckBox             from '@stateless/atomic/CheckBox';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import TextInputType        from '@stateless/atomic/TextInput/TextInputType';
import PageTitle            from '@stateless/composed/PageTitle';
import SignupFeatures       from '@stateless/composed/SignupFeatures';
import SignupLoginSwitcher  from '@stateless/composed/SignupLoginSwitcher';

import styles from './styles.module.scss';

const Signup = () => {
    const translator                      = useContextTranslator('screens.signup');
    const dispatch                        = useDispatch();
    const userActions                     = useUser(dispatch);
    const [signupUser, setSignupUser]     = useState({});
    const [validations, setValidations]   = useState([]);
    const [termsChecked, setTermsChecked] = useState(false);
    const userFormSchema                  = objectValidation({
        [UserFields.firstname]:      string().required(),
        [UserFields.lastname]:       string().required(),
        [UserFields.email]:          string().email().required().test(
            'is-valid-email',
            ValidationHelper.isValidEmail,
        ),
        [UserFields.password]:       string().required().min(Security.minimumPasswordLength).required('Password is required'),
        [UserFields.passwordRepeat]: string().required().min(Security.minimumPasswordLength).oneOf([ref(UserFields.password), null], ValidationError.passwordRepeatError),
        [UserFields.termsAccepted]:  boolean().required().oneOf([true], 'You must accept the terms and conditions'),
    });

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            setSignupUser({
                ...signupUser,
                ...changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations,
        data: signupUser,
        onUpdateData,
    });

    function onTermsClicked() {
        setTermsChecked(!termsChecked);
        onUpdateData(UserFields.termsAccepted)(!termsChecked);
    }

    function onFormSubmit(event) {
        event.preventDefault();

        const currentValidation = ValidationHelper.getValidations(
            userFormSchema,
            signupUser,
            setValidations,
        );
        const errorMessages     = {
            [UserFields.email]:                    'signupEmailError',
            [UserFields.password]:                 'signupPasswordError',
            [ValidationError.passwordRepeatError]: 'signupPasswordRepeatError',
            [UserFields.passwordRepeat]:           'signupPasswordError',
            [UserFields.firstname]:                'signupFirstnameError',
            [UserFields.lastname]:                 'signupLastnameError',
            [UserFields.termsAccepted]:            'signupTermsError',
        };

        ValidationHelper.showCustomError(currentValidation, errorMessages);

        if (ValidationHelper.hasNoValidations(currentValidation)) {
            userActions.signup({
                user: signupUser,
            });
        }
    }

    function renderLink(urlKey) {
        return (
            <a
                className={styles.link}
                href={translator.t(urlKey)}
                target={'_blank'}
                rel={'noopener noreferrer'}
            />
        );
    }

    return (
        <div className={styles.loginWrapper}>
            <PageTitle
                title={translator.t('pageTitle')}
            />
            <img
                src={Logo}
                alt={'Logo'}
            />
            <Headline
                title={translator.t('headline')}
                type={HeadlineType.headline1}
            />
            <form onSubmit={onFormSubmit}>
                <div className={styles.gridWrapper}>
                    <div className={styles.signupFormWrapper}>
                        <Headline
                            title={translator.t('subHeadline')}
                            type={HeadlineType.headline2}
                        />
                        <div className={styles.signupForm}>
                            {inputRenderer.renderTextInput({
                                label:         translator.t('email'),
                                required:      true,
                                textInputType: TextInputType.email,
                                field:         UserFields.email,
                            })}
                            {inputRenderer.renderTextInput({
                                label:         translator.t('password'),
                                required:      true,
                                textInputType: TextInputType.password,
                                field:         UserFields.password,
                            })}
                            {inputRenderer.renderTextInput({
                                label:         translator.t('passwordRepeat'),
                                required:      true,
                                textInputType: TextInputType.password,
                                field:         UserFields.passwordRepeat,
                            })}
                            {inputRenderer.renderTextInput({
                                label:    translator.t('firstname'),
                                required: true,
                                field:    UserFields.firstname,
                            })}
                            {inputRenderer.renderTextInput({
                                label:    translator.t('lastname'),
                                required: true,
                                field:    UserFields.lastname,
                            })}
                            <div
                                className={classNames(
                                    styles.agreementRow,
                                    {
                                        [styles.agreementRowError]: _.get(validations, UserFields.termsAccepted),
                                    },
                                )}
                            >
                                <CheckBox
                                    checked={_.get(signupUser, UserFields.termsAccepted)}
                                    onClick={onTermsClicked}
                                />
                                <div>
                                    <Trans
                                        i18nKey={'screens.signup.terms'}
                                    >
                                        {renderLink('privacyPolicyUrl')}
                                        {renderLink('termsAndConditionsUrl')}
                                    </Trans>
                                </div>
                            </div>
                            <Button
                                text={translator.t('signupButton')}
                                size={ButtonSize.big}
                            />
                            <SignupLoginSwitcher
                                text={translator.t('alreadySignedUp')}
                                targetText={translator.t('loginNow')}
                                target={Routes.login}
                            />
                        </div>
                    </div>
                    <SignupFeatures />
                </div>
            </form>
        </div>
    );
};

export default Signup;
