//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import { Api }      from '@api';
import ImageStorage from '@helper/ImageStorage';

class ImageHelper {
    static getImagePath = (image) => {
        const previewPath = _.get(image, 'preview.url', null);
        const path        = _.get(image, 'path', null);
        const imageField  = _.get(image, 'image', null);

        if (previewPath) {
            return previewPath;
        }

        if (
            imageField &&
            _.startsWith(imageField, 'data:image/')
        ) {
            return imageField;
        }

        if (!path) {
            return null;
        }

        if (_.startsWith(path, 'http')) {
            return path;
        }

        return Api.getUrl(path);
    };

    static mapImageObjectForApiSave = async (objectContainsImage, fieldName = 'image') => {
        const image              = _.get(objectContainsImage, fieldName, null);
        const objectWithoutImage = _.omit(objectContainsImage, fieldName);

        if (!image) {
            return {
                ...objectWithoutImage,
                [fieldName]: null,
            };
        }

        if (_.isNumber(image)) {
            const imageObject = await ImageStorage.loadImage(image);

            return {
                [fieldName]: _.get(imageObject, 'image', null),
            };
        }

        return objectWithoutImage;
    };
}

export default ImageHelper;
