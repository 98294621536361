//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import classNames      from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';
import styled          from 'styled-components';

import { svgColor }       from '@helper/StyledMixins';
import useNavigationItems from '@hooks/NavigationItems';
import Button             from '@stateless/atomic/Button';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';
import { selectAppTheme } from '@store/selectors/user';

import styles from './styles.module.scss';

const MobileMenuContentWrapper = styled.div`
    background-color: ${(props) => props.$primaryColor} !important;
`;

// eslint-disable-next-line rulesdir/no-shortcut-variable-name
const MobileMenuItemWrapper = styled.li`
    &:hover
    {
        background: ${(props) => props.$backgroundColor} !important;
        color:      ${(props) => props.$secondaryColor} !important;
    }

    &:hover,
    &.active
    {
        ${(props) => svgColor(props.theme.secondaryColor)}
    }
`;

const MobileMenu = () => {
    const [open, setOpen]                            = useState(false);
    const reference                                  = useRef(null);
    const history                                    = useHistory();
    const { navigationItems, bottomNavigationItems } = useNavigationItems(history);
    const appTheme                                   = useSelector(selectAppTheme);
    const handleClickOutside                         = (event) => {
        if (
            reference.current &&
            !reference.current.contains(event.target)
        ) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function onMobileMenuToggleClicked() {
        setOpen((current) => !current);
    }

    function renderDashboardItems(items) {
        return items.map((item) => {
            const { route, isActive, onClick, iconType, text } = item;

            return (
                <MobileMenuItemWrapper
                    $backgroundColor={appTheme?.backgroundColor}
                    $secondaryColor={appTheme?.secondaryColor}
                    key={route}
                    className={classNames(
                        styles.mobileMenuItem,
                        {
                            [styles.active]: isActive,
                        },
                    )}
                    onClick={onClick}
                >
                    <Icon
                        iconType={iconType}
                    />
                    <span>
                        {text}
                    </span>
                </MobileMenuItemWrapper>
            );
        });
    }

    return (
        <nav
            className={classNames(
                styles.mobileMenu,
                {
                    [styles.open]: open,
                },
            )}
            ref={reference}
        >
            <div className={styles.mobileMenuHeader}>
                <Button
                    onClick={onMobileMenuToggleClicked}
                    type={ButtonType.icon}
                    iconLeft={IconType.menu}
                />
            </div>
            <MobileMenuContentWrapper
                $primaryColor={appTheme?.primaryColor}
                className={styles.mobileMenuContent}
            >
                <ul>
                    {renderDashboardItems(navigationItems)}
                </ul>
                <ul>
                    {renderDashboardItems(bottomNavigationItems)}
                </ul>
            </MobileMenuContentWrapper>
        </nav>
    );
};

export default MobileMenu;
