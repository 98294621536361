//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames      from 'classnames';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import Formatter                 from '@helper/Formatter';
import useContextTranslator      from '@hooks/ContextTranslator';
import Headline                  from '@stateless/atomic/Headline';
import HeadlineType              from '@stateless/atomic/Headline/HeadlineType';
import Icon                      from '@stateless/atomic/Icon';
import IconType                  from '@stateless/atomic/Icon/IconType';
import Spacer                    from '@stateless/atomic/Spacer';
import selectSubscriptionOptions from '@store/selectors/subscriptionOption';

import styles from './styles.module.scss';

const SubscriptionPackages = () => {
    const translator          = useContextTranslator('screens.paymentDataScreen.subscriptionOption');
    const subscriptionOptions = useSelector(selectSubscriptionOptions);

    function onBuyButtonClicked(subscriptionOption) {
        return () => {
            window.open(subscriptionOption.billingUrl);
        };
    }

    function renderOption(subscriptionOption, index) {
        const billingInterval   = _.toLower(subscriptionOption.billingInterval);
        const taxInformationKey = _.camelCase(`taxInformation ${billingInterval}`);
        const title             = _.camelCase(`title ${billingInterval}`);
        const formattedPrice    = Formatter.formatToCurrency(0)(subscriptionOption.price);
        const priceText         = translator.t('priceMonthly', {
            price: formattedPrice,
        });

        return (
            <div
                key={_.uniqueId(index)}
                className={classNames(
                    styles.packageCard,
                    {
                        [styles.highlight]: subscriptionOption.recommendation,
                    },
                )}
            >
                {subscriptionOption.recommendation && (
                    <div className={styles.highlightLabel}>
                        {subscriptionOption.recommendationText}
                    </div>
                )}
                <h2 className={styles.packageTitle}>
                    {translator.t(title)}
                </h2>
                <p className={styles.packagePrice}>
                    {priceText}
                </p>
                <p className={styles.packageTaxInformation}>
                    {translator.t(taxInformationKey)}
                </p>
                <Spacer height={20} />
                <ul className={styles.packageFeatures}>
                    {subscriptionOption.facts.map((fact, factIndex) => (

                        <li
                            key={_.uniqueId(factIndex)}
                            className={styles.featureItem}
                        >
                            <span>
                                <Icon iconType={IconType.circleCheck} />
                            </span>
                            <span>
                                {fact}
                            </span>
                        </li>
                    ))}
                </ul>
                <Spacer height={50} />
                <button
                    className={styles.buyButton}
                    onClick={onBuyButtonClicked(subscriptionOption)}
                >
                    {translator.t('buttonLabel')}
                </button>
            </div>
        );
    }

    return (
        <div className={styles.subscriptionPackages}>
            <Spacer height={20} />
            <Headline
                type={HeadlineType.headline2}
                title={translator.t('headline')}
            />
            <Spacer height={20} />
            <p className={styles.infoText}>
                {translator.t('infoText')}
            </p>
            <Spacer height={20} />
            <p className={styles.trialPhaseInformation}>
                {translator.t('trialPhaseInformation')}
            </p>
            <div className={styles.packagesContainer}>
                {_.map(subscriptionOptions, renderOption)}
            </div>
        </div>
    );
};

export default SubscriptionPackages;
