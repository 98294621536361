//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { call }  from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { put }   from 'redux-saga/effects';

import * as Api             from '@api/index';
import HydraHelper          from '@helper/Hydra';
import { InventoryActions } from '@slices/inventory';

function* fetchItems() {
    const items = [
        {
            highlighted: false,
            id:          '312e2f4c-edb6-11ed-a05b-0242ac120003',
            iri:         '/api/inventory/312e2f4c-edb6-11ed-a05b-0242ac120003',
            name:        'MacBook',
        },
        {
            highlighted: true,
            id:          '312e3794-edb6-11ed-a05b-0242ac120003',
            iri:         '/api/inventory/312e3794-edb6-11ed-a05b-0242ac120003',
            name:        'iPhone X',
        },
        {
            highlighted: false,
            id:          '312e39f6-edb6-11ed-a05b-0242ac120003',
            iri:         '/api/inventory/312e39f6-edb6-11ed-a05b-0242ac120003',
            name:        'Alexa',
        },
        {
            highlighted: false,
            id:          '312e3cbc-edb6-11ed-a05b-0242ac120003',
            iri:         '/api/inventory/312e3cbc-edb6-11ed-a05b-0242ac120003',
            name:        'LG Monitor',
        },
    ];

    // Fake a real api call to get the chance to see the loading overlay
    yield delay(1000);

    yield put(InventoryActions.fetchItemsSucceeded({
        items,
    }));
}

function* fetchItemsFromApi() {
    const response = yield call(Api.context.items.fetch);

    if (response.ok) {
        const items = HydraHelper.getMembersFromResponse(response.data);

        yield put(InventoryActions.fetchItemsSucceeded({
            items,
        }));
    } else {
        yield put(InventoryActions.fetchItemsFailed());
    }
}

export default {
    fetchItems,
    fetchItemsFromApi,
};
// TODO-Skeleton end
