//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useCallback } from 'react';
import { useEffect }   from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes       from '@components/PropTypes';
import Number          from '@stateless/atomic/Number';
import RangeSliderType from '@stateless/atomic/RangeSlider/RangeSliderType';
import Colors          from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    fontColor: PropTypes.string,
    label:     PropTypes.string,
    max:       PropTypes.number,
    min:       PropTypes.number,
    onChange:  PropTypes.func,
    step:      PropTypes.number,
    type:      PropTypes.oneOfObjectValues(RangeSliderType),
    value:     PropTypes.number,
};

const RangeSlider = ({
    max       = 10,
    min       = 0,
    onChange  = _.noop,
    step      = 1,
    value     = 5,
    label     = '',
    fontColor = Colors.white,
    type      = RangeSliderType.currency,
}) => {
    const valueToUse                  = (
        _.isNil(value) ?
            0 :
            value
    );
    const [rangeValue, setRangeValue] = React.useState(valueToUse);
    const fixedDecimalScale           = max < 10;
    const suffix                      = (
        type === RangeSliderType.currency ?
            'format.priceSuffix' :
            'format.percentSuffix'
    );
    const debouncedOnChange           = useCallback(
        _.debounce(onChange, 300),
        [],
    );
    const updateRangeValue            = (event) => {
        const targetValue = event.target.valueAsNumber;

        setRangeValue(targetValue);
        debouncedOnChange(targetValue);
    };
    const style                       = {
        color: fontColor,
    };

    useEffect(
        () => {
            if (
                value &&
                value !== rangeValue
            ) {
                setRangeValue(value);
            }
        },
        [value],
    );

    return (
        <div
            className={styles.rangeSliderWrapper}
            style={style}
        >
            <span>
                {label}
            </span>
            <input
                type="range"
                className={styles.rangeSliderTest}
                min={min}
                max={max}
                step={step}
                value={rangeValue}
                onChange={updateRangeValue}
            />
            <span className={styles.number}>
                <Number
                    value={rangeValue}
                    suffix={I18n.t(suffix)}
                    fixedDecimalScale={fixedDecimalScale}
                />
            </span>
        </div>
    );
};

RangeSlider.propTypes = propTypes;

export default RangeSlider;
