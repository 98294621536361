//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import { Modal }  from 'react-overlays';
import styled     from 'styled-components';

import PropTypes            from '@components/PropTypes';
import useContextTranslator from '@hooks/ContextTranslator';
import Button               from '@stateless/atomic/Button';
import ButtonColor          from '@stateless/atomic/Button/ButtonColor';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import IconType             from '@stateless/atomic/Icon/IconType';
import Spacer               from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const Backdrop = styled('div')`
    position:         fixed;
    z-index:          1040;
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: #000000;
    opacity:          0.5;
`;

const PositionedModal = styled(Modal)`
    position:         fixed;
    width:            20%;
    min-width:        450px;
    aspect-ratio:     2 / 1;
    z-index:          1040;
    outline:          none;
    top:              50%;
    left:             50%;
    border-radius:    5px;
    transform:        translate(-50%, -50%);
    border:           1px solid #e5e5e5;
    background-color: white;
    box-shadow:       0 5px 15px rgba(0, 0, 0, 0.5);
    padding:          20px;

    @media (max-width: 768px)
    {
        min-width: 300px;
    }
`;

const propTypes = {
    cancelButtonText:  PropTypes.string,
    children:          PropTypes.children,
    confirmButtonIcon: PropTypes.iconType,
    confirmButtonText: PropTypes.string,
    disableAutoClose:  PropTypes.bool,
    fullHeight:        PropTypes.bool,
    onClose:           PropTypes.func,
    onConfirm:         PropTypes.func,
    show:              PropTypes.bool,
    text:              PropTypes.string,
    title:             PropTypes.string,
};

const ConfirmModal = ({
    cancelButtonText  = 'cancel',
    children          = null,
    confirmButtonIcon = IconType.trash,
    confirmButtonText = 'confirmDelete',
    disableAutoClose  = false,
    fullHeight        = true,
    onClose           = _.noop,
    onConfirm         = _.noop,
    show              = false,
    text              = null,
    title             = null,
}) => {
    const [internalShow, setInternalShow] = useState(show);
    const translator                      = useContextTranslator('modal');
    const renderBackdrop                  = (props) => (
        <Backdrop
            {...props}
        />
    );

    useEffect(
        () => {
            setInternalShow(show);
        },
        [show],
    );

    function onHide() {
        if (!disableAutoClose) {
            setInternalShow(false);
        }

        onClose(true);
    }

    function onCloseClicked(event) {
        if (!disableAutoClose) {
            setInternalShow(false);
        }

        onClose(false);
    }

    function onConfirmClicked(event) {
        if (!disableAutoClose) {
            setInternalShow(false);
        }

        onConfirm(event);
    }

    return (
        <div className={styles.confirmModal}>
            <PositionedModal
                show={internalShow}
                onHide={onHide}
                onBackdropClick={onHide}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <div
                    className={classNames(
                        styles.modalContent,
                        {
                            [styles.fullHeight]: fullHeight,
                        },
                    )}
                >
                    <div>
                        <Headline
                            title={title}
                            type={HeadlineType.headline3}
                        />
                        <Spacer
                            height={20}
                        />
                        <div>
                            <p>
                                {text}
                            </p>
                            {
                                (
                                    children &&
                                    text
                                ) &&
                                <Spacer height={20} />
                            }
                            {children}
                            {
                                children &&
                                <Spacer height={20} />
                            }
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        <Button
                            type={ButtonType.edgy}
                            color={ButtonColor.primary}
                            iconLeft={confirmButtonIcon}
                            text={translator.t(confirmButtonText)}
                            onClick={onConfirmClicked}
                        />
                        <Button
                            type={ButtonType.edgy}
                            color={ButtonColor.primary}
                            iconLeft={IconType.cross}
                            text={translator.t(cancelButtonText)}
                            onClick={onCloseClicked}
                        />
                    </div>
                </div>
            </PositionedModal>
        </div>
    );
};

ConfirmModal.propTypes = propTypes;

export default ConfirmModal;
