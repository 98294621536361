//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                  from 'lodash';
import { transparentize } from 'polished';
import { Trans }          from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';
import styled             from 'styled-components';
import { object }         from 'yup';
import { string }         from 'yup';
import { boolean }        from 'yup';

import PropTypes                  from '@components/PropTypes';
import ContactFormFields          from '@constants/ContactFormFields';
import Notification               from '@helper/Notification';
import useContextTranslator       from '@hooks/ContextTranslator';
import { WidgetActions }          from '@slices/widget';
import CheckBox                   from '@stateless/atomic/CheckBox';
import TextInput                  from '@stateless/atomic/TextInput';
import WidgetButton               from '@stateless/composed/WidgetButton';
import { selectContactForm }      from '@store/selectors/widget';
import { selectShowThankYouPage } from '@store/selectors/widget';
import Colors                     from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    accentColor:  PropTypes.string,
    fontColor:    PropTypes.string,
    primaryColor: PropTypes.string,
};

const getPrimaryColor = (props) => {
    return props.$primaryColor;
};

const getErrorBorder = (props) => {
    return (
        props.$error ?
            `2px solid ${Colors.red}` :
            'none'
    );
};

const Link = styled.a`
    color: ${(props) => props.$accentColor};
`;

const InputWrapper = styled.div`
    :focus
    {
        border:     2px solid ${getPrimaryColor};
        box-shadow: 0 0 0 0.2rem ${(props) => transparentize(0.75, getPrimaryColor(props))};
    }


    input:not(:focus)
    {
        border: ${(props) => getErrorBorder(props)};
    }
`;

const WidgetContactForm = ({
    fontColor    = PropTypes.string,
    accentColor  = PropTypes.string,
    primaryColor = PropTypes.string,
}) => {
    const translator                    = useContextTranslator('components.widgetContactForm');
    const [validations, setValidations] = useState({});
    const showThankYouPage              = useSelector(selectShowThankYouPage);
    const dispatch                      = useDispatch();
    const contactForm                   = useSelector(selectContactForm);
    const fontColorStyle                = {
        color: fontColor,
    };
    const getFontColorStyle             = (uppercase) => {
        return {
            color:         fontColor,
            textTransform: (
                uppercase ?
                    'uppercase' :
                    'none'
            ),
        };
    };
    const contactFormSchema             = object({
        [ContactFormFields.firstname]:   string(),
        [ContactFormFields.lastname]:    string(),
        [ContactFormFields.email]:       string().email().required(),
        [ContactFormFields.phoneNumber]: string(),
        [ContactFormFields.street]:      string(),
        [ContactFormFields.postalCode]:  string(),
        [ContactFormFields.city]:        string(),
        [ContactFormFields.terms]:       boolean().required().oneOf([true]),
    });
    const termsChecked                  = _.get(contactForm, ContactFormFields.terms, false);

    function isFormValid() {
        try {
            contactFormSchema.validateSync(
                contactForm,
                {
                    abortEarly: false,
                },
            );
            setValidations({});
            return true;
        } catch (validationError) {
            setValidations(validationError.inner.reduce((errors, current) => {
                return {
                    ...errors,
                    [current.path]: current.message,
                };
            }, {}));

            return false;
        }
    }

    function getCheckBoxStyle() {
        if (_.has(validations, ContactFormFields.terms)) {
            return {
                color: Colors.red,
            };
        }

        return fontColorStyle;
    }

    function resetValidations() {
        setValidations({});
    }

    function onTermsClicked() {
        resetValidations();
        dispatch(WidgetActions.setContactForm({
            [ContactFormFields.terms]: !termsChecked,
        }));
    }

    function onInputChanged(field) {
        return (value) => {
            resetValidations();
            dispatch(WidgetActions.setContactForm({
                [field]: value,
            }));
        };
    }

    function renderInput(field) {
        return (
            <InputWrapper
                className={styles.contactFormInput}
                $primaryColor={primaryColor}
                $error={_.has(validations, field)}
            >
                <label style={fontColorStyle}>
                    {translator.t(field)}
                </label>
                <TextInput
                    onChange={onInputChanged(field)}
                    value={_.get(contactForm, field, '')}
                />
            </InputWrapper>
        );
    }

    function renderLink(urlKey) {
        return (
            <Link
                href={translator.t(urlKey)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                $accentColor={accentColor}
            />
        );
    }

    function onSubmitButtonClicked() {
        if (isFormValid()) {
            dispatch(WidgetActions.sendContactForm({
                contactForm,
            }));
        } else {
            Notification.error('widgetContactForm.validationError');
        }
    }

    function renderHeadlineText() {
        let headlineKey = 'headline';
        let textKey     = 'text';

        if (showThankYouPage) {
            headlineKey = 'thankYouHeadline';
            textKey     = 'thankYouText';
        }

        return (
            <div
                className={styles.contactForm}
                style={fontColorStyle}
            >
                <h1 style={getFontColorStyle(!showThankYouPage)}>
                    {translator.t(headlineKey)}
                </h1>
                <p style={fontColorStyle}>
                    {translator.t(textKey)}
                </p>
            </div>
        );
    }

    function renderContactForm() {
        if (showThankYouPage) {
            return null;
        }

        return (
            <>
                <div className={styles.emailRow}>
                    {renderInput(ContactFormFields.email)}
                </div>
                <div className={styles.contactFormInputs}>
                    {renderInput(ContactFormFields.firstname)}
                    {renderInput(ContactFormFields.lastname)}
                    {renderInput(ContactFormFields.street)}
                    {renderInput(ContactFormFields.houseNumber)}
                    {renderInput(ContactFormFields.postalCode)}
                    {renderInput(ContactFormFields.city)}
                    {renderInput(ContactFormFields.phoneNumber)}
                </div>
                <div
                    className={styles.agreementRow}
                    style={getCheckBoxStyle()}
                >
                    <CheckBox
                        checked={termsChecked}
                        onClick={onTermsClicked}
                    />
                    <div>
                        <Trans i18nKey="components.widgetContactForm.terms">
                            {renderLink('privacyPolicyUrl')}
                            {renderLink('termsAndConditionsUrl')}
                        </Trans>
                    </div>
                </div>
                <div
                    className={styles.buttonRow}
                    style={fontColorStyle}
                >
                    <WidgetButton
                        $fontColor={fontColor}
                        $primaryColor={primaryColor}
                        $accentColor={accentColor}
                        onClick={onSubmitButtonClicked}
                    >
                        {translator.t('submit')}
                    </WidgetButton>
                </div>
            </>
        );
    }

    return (
        <div className={styles.widgetContactForm}>
            {renderHeadlineText()}
            {renderContactForm()}
        </div>
    );
};

WidgetContactForm.propTypes = propTypes;

export default WidgetContactForm;
