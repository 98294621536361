//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import ParkingType        from '@constants/ParkingType';
import UnitFields         from '@constants/UnitFields';
import UnitStatus         from '@constants/UnitStatus';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const emptyParkingPlace = {
    [UnitFields.carParkingSpaceType]:          ParkingType.garage,
    [UnitFields.carParkingSpacePurchasePrice]: null,
    [UnitFields.carParkingSpaceMonthlyRent]:   null,
};

const initialState = Object.freeze({
    edit: {
        [UnitFields.object]:               null,
        [UnitFields.number]:               null,
        [UnitFields.locationOnProperty]:   null,
        [UnitFields.spaceInSquareMeters]:  null,
        [UnitFields.roomCount]:            null,
        [UnitFields.status]:               UnitStatus.available,
        [UnitFields.showUnitInCalculator]: false,
        [UnitFields.purchasePrice]:        null,
        [UnitFields.calculationData]:      {
            [UnitFields.purchasePriceField]: null,
            [UnitFields.monthlyRentField]:   null,
        },
        [UnitFields.carParkingSpaces]:     [],
    },
});

const unitSlice = createSlice({
    name:     'unit',
    initialState,
    reducers: {
        createNewUnit:         (state, action) => {
            return update(state, {
                edit: {
                    $set: {
                        ...initialState.edit,
                        [UnitFields.object]: _.get(action, 'payload.object.iri', null),
                    },
                },
            });
        },
        editUnit:              (state, action) => {
            return update(state, {
                edit: {
                    $set: _.get(action, 'payload.unit', initialState.edit),
                },
            });
        },
        editUnitData:          (state, action) => {
            return update(state, {
                edit: {
                    $set: Merge.mergeWith(
                        _.get(state, 'edit', initialState.edit),
                        _.get(action, 'payload.edit', {}),
                    ),
                },
            });
        },
        addCarParkingSpace:    (state, action) => {
            return update(state, {
                edit: {
                    [UnitFields.carParkingSpaces]: {
                        $push: [
                            {
                                ...emptyParkingPlace,
                            },
                        ],
                    },
                },
            });
        },
        deleteCarParkingSpace: (state, action) => {
            return update(state, {
                edit: {
                    [UnitFields.carParkingSpaces]: {
                        $removeArrayItemAtIndex: _.get(action, 'payload.index', null),
                    },
                },
            });
        },
        saveUnit:              LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.unitSave')),
        saveUnitFailed:        LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveUnitSucceeded:     LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        }),
        deleteUnit:            LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.unitDelete')),
        deleteUnitFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteUnitSucceeded:   LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        reset:                 () => {
            return {
                ...initialState,
            };
        },
    },
});

export const UnitActions = unitSlice.actions;

export const UnitReducer = unitSlice.reducer;

export const useUnit = (dispatch) => bindActionCreators(UnitActions, dispatch);

export default unitSlice;
