//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    subscriptionOptions: [],
});

const subscriptionOptionSlice = createSlice({
    name:     'subscriptionOption',
    initialState,
    reducers: {
        fetchSubscriptionOptions:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.subscriptionOptions')),
        fetchSubscriptionOptionsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchSubscriptionOptionsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                subscriptionOptions: {
                    $set: _.get(action, 'payload.subscriptionOptions', []),
                },
            });
        }),
    },
});

export const SubscriptionOptionActions = subscriptionOptionSlice.actions;

export const SubscriptionOptionReducer = subscriptionOptionSlice.reducer;

export const useSubscriptionOption = (dispatch) => bindActionCreators(SubscriptionOptionActions, dispatch);

export default subscriptionOptionSlice;
