//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styled from 'styled-components';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    children:   PropTypes.node,
    hoverColor: PropTypes.string,
};

const getAccentColor = (props) => {
    return props.$accentColor;
};

const Button = styled.button`
    border-color: ${getAccentColor};
    color:        ${getAccentColor};

    &:hover
    {
        background-color: ${getAccentColor};
        color:            ${(props) => props.$hoverColor};
    }
`;

const WidgetButton = ({
    children   = null,
    hoverColor = Colors.white,
    ...props
}) => {
    return (
        <Button
            {...props}
            className={styles.widgetButton}
            $hoverColor={hoverColor}
        >
            {children}
        </Button>
    );
};

WidgetButton.propTypes = propTypes;

export default WidgetButton;
