//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import { Api }                from '@api';
import ContractStatus         from '@constants/ContractStatus';
import DateTime               from '@helper/DateTime';
import useContextTranslator   from '@hooks/ContextTranslator';
import Button                 from '@stateless/atomic/Button';
import ButtonType             from '@stateless/atomic/Button/ButtonType';
import Headline               from '@stateless/atomic/Headline';
import HeadlineType           from '@stateless/atomic/Headline/HeadlineType';
import MarkupTranslation      from '@stateless/atomic/MarkupTranslation';
import LayoutScreen           from '@stateless/composed/LayoutScreen';
import PageTitle              from '@stateless/composed/PageTitle';
import SubscriptionPackages   from '@stateless/composed/SubscriptionPackages';
import { selectUserContract } from '@store/selectors/user';

import styles from './styles.module.scss';

const PaymentDataScreen = () => {
    const translator                     = useContextTranslator('screens.paymentDataScreen');
    const contract                       = useSelector(selectUserContract);
    const contractStatus                 = _.get(contract, 'status');
    const nextBillingDateTime            = _.get(contract, 'nextBillingDate');
    const endDate                        = _.get(contract, 'endDate');
    const nextBillingDateTimeString      = DateTime.getTimeString(nextBillingDateTime);
    const nextBillingDateTimeStringParts = _.split(nextBillingDateTimeString, ' ');
    const nextBillingDateString          = nextBillingDateTimeStringParts[0];
    const nextBillingTimeString          = nextBillingDateTimeStringParts[1];

    function onManageContractClicked() {
        window.open(Api.getUrl(`${contract.iri}/self-service`), '_blank');
    }

    function renderSubscriptionPackages() {
        if (
            contractStatus !== ContractStatus.active ||
            endDate
        ) {
            return (
                <SubscriptionPackages />
            );
        }

        return null;
    }

    function getContractStatusMessageKey() {
        if (
            nextBillingDateTime &&
            !endDate
        ) {
            return 'nextBillingDate';
        }

        if (
            endDate &&
            contractStatus !== ContractStatus.inTrial
        ) {
            return 'nextBillingDateCancelled';
        }

        return 'nextBillingDateTrial';
    }

    return (
        <>
            <PageTitle
                title={I18n.t('paymentData')}
            />
            <LayoutScreen>
                <div className={styles.paymentDataScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('contractData')}
                    />
                    <div className={styles.grid}>
                        <div className={styles.gridItem}>
                            <Headline
                                type={HeadlineType.headline3}
                                title={translator.t('contractStatus')}
                            />
                            <p>
                                <MarkupTranslation
                                    translationKey={`screens.paymentDataScreen.${getContractStatusMessageKey()}`}
                                    values={{
                                        date: nextBillingDateString,
                                        time: nextBillingTimeString,
                                    }}
                                />
                            </p>
                        </div>
                        <div className={styles.gridItem}>
                            <Headline
                                type={HeadlineType.headline3}
                                title={translator.t('management')}
                            />
                            <p>
                                {translator.t('managementText')}
                            </p>
                            <Button
                                type={ButtonType.edgy}
                                text={translator.t('manageContract')}
                                onClick={onManageContractClicked}
                                disabled={!_.get(contract, 'active', false)}
                            />
                        </div>
                    </div>
                </div>
                {renderSubscriptionPackages()}
            </LayoutScreen>
        </>
    );
};

export default PaymentDataScreen;
