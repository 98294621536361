//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    examples: [],
});

const exampleSlice = createSlice({
    name:     'example',
    initialState,
    reducers: {
        createExample:          LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('creatingExample'),
        ),
        createExampleFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        createExampleSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                examples: {
                    $push: [_.get(action, 'payload.example')],
                },
            });
        }),
    },
});

export const ExampleActions = exampleSlice.actions;

export const ExampleReducer = exampleSlice.reducer;

export const useExample = (dispatch) => bindActionCreators(ExampleActions, dispatch);

export default exampleSlice;
