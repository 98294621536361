//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import _        from 'lodash';
import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api           from '@api/index';
import { ExampleActions } from '@slices/example';

function* createExample(action) {
    const name     = _.get(action, 'payload.name');
    const image    = _.get(action, 'payload.uploadedImage.image');
    const response = yield call(
        Api.context.example.create,
        {
            name,
            uploadedImage: image,
        },
    );

    if (response.ok) {
        yield put(ExampleActions.createExampleSucceeded({
            example: response.data,
        }));
    } else {
        yield put(ExampleActions.createExampleFailed());
    }
}

export default {
    createExample,
};
// TODO-Skeleton end
