//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Icon         from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
};

const ListWidget = ({
    title = '',
    items = [],
}) => {
    function renderIcon(icon) {
        if (!icon) {
            return null;
        }

        return (
            <Icon iconType={icon} />
        );
    }

    function renderListItems() {
        return items.map((item, index) => {
            const { text, icon, onClick } = item;

            return (
                <a
                    key={text}
                    className={styles.listItem}
                    onClick={onClick}
                >
                    {renderIcon(icon)}
                    <span>
                        {text}
                    </span>
                </a>
            );
        });
    }

    return (
        <div className={styles.listWidget}>
            <Headline
                title={title}
                type={HeadlineType.headline4}
            />
            <div className={styles.listContainer}>
                {renderListItems()}
            </div>
        </div>
    );
};

ListWidget.propTypes = propTypes;

export default ListWidget;
