//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';
import { Redirect }    from 'react-router';

import Routes                         from '@constants/Routes';
import CalculationCustomerScreen      from '@screens/CalculationScreen/CalculationCustomerScreen';
import CalculationFinanceScreen       from '@screens/CalculationScreen/CalculationFinanceScreen';
import CalculationObjectScreen        from '@screens/CalculationScreen/CalculationObjectScreen';
import CalculationResultScreen        from '@screens/CalculationScreen/CalculationResultScreen';
import CustomerOnlineCalculatorScreen from '@screens/CustomerOnlineCalculatorScreen';
import CustomersScreen                from '@screens/CustomersScreen';
import DashboardScreen                from '@screens/DashboardScreen';
import EditCustomerScreen             from '@screens/EditCustomerScreen';
import EditObjectScreen               from '@screens/EditObjectScreen';
import EditUnitScreen                 from '@screens/EditUnitScreen';
import EmailConfirmedScreen           from '@screens/EmailConfirmedScreen';
import GuideScreen                    from '@screens/GuideScreen';
import Login                          from '@screens/Login';
import NotFound                       from '@screens/NotFound';
import ObjectsScreen                  from '@screens/ObjectsScreen';
import OrderResponseScreen            from '@screens/OrderResponseScreen';
import PasswordLostScreen             from '@screens/PasswordLostScreen';
import PasswordReset                  from '@screens/PasswordReset';
import PaymentDataScreen              from '@screens/PaymentDataScreen';
import PaymentFailedScreen            from '@screens/PaymentFailedScreen';
import ProfileScreen                  from '@screens/ProfileScreen';
import SettingsScreen                 from '@screens/SettingsScreen';
import Signup                         from '@screens/Signup';
import WidgetScreen                   from '@screens/WidgetScreen';
import selectToken                    from '@store/selectors/user';

const MainRouter = () => {
    const token = useSelector(selectToken);

    function renderLogin() {
        if (token) {
            return (
                <Redirect to={Routes.dashboard} />
            );
        }

        return (
            <Login />
        );
    }

    return (
        <Switch>
            <Route
                exact
                path={Routes.dashboard}
                component={DashboardScreen}
            />
            <Route
                exact
                path={Routes.paymentFailed}
                component={PaymentFailedScreen}
            />
            <Route
                exact
                path={Routes.emailConfirmed}
                component={EmailConfirmedScreen}
            />
            <Route
                exact
                path={Routes.orderResponse}
                component={OrderResponseScreen}
            />
            <Route
                exact
                path={Routes.dashboard}
                component={DashboardScreen}
            />
            <Route
                exact
                path={Routes.login}
                component={Login}
            >
                {renderLogin()}
            </Route>
            <Route
                exact
                path={Routes.signup}
                component={Signup}
            />
            <Route
                exact
                path={Routes.passwordReset}
                component={PasswordReset}
            />
            <Route
                exact
                path={Routes.lostPassword}
                component={PasswordLostScreen}
            />
            <Route
                exact
                path={Routes.editUnit}
                component={EditUnitScreen}
            />
            <Route
                exact
                path={Routes.editObjects}
                component={EditObjectScreen}
            />
            <Route
                exact
                path={Routes.objects}
                component={ObjectsScreen}
            />
            <Route
                exact
                path={Routes.editCustomer}
                component={EditCustomerScreen}
            />
            <Route
                exact
                path={Routes.customers}
                component={CustomersScreen}
            />
            <Route
                exact
                path={Routes.calculationCustomer}
                component={CalculationCustomerScreen}
            />
            <Route
                exact
                path={Routes.calculationFinance}
                component={CalculationFinanceScreen}
            />
            <Route
                exact
                path={Routes.calculationObject}
                component={CalculationObjectScreen}
            />
            <Route
                exact
                path={Routes.calculationResult}
                component={CalculationResultScreen}
            />
            <Route
                exact
                path={Routes.profile}
                component={ProfileScreen}
            />
            <Route
                exact
                path={Routes.settings}
                component={SettingsScreen}
            />
            <Route
                exact
                path={Routes.customerOnlineCalculator}
                component={CustomerOnlineCalculatorScreen}
            />
            <Route
                exact
                path={Routes.paymentData}
                component={PaymentDataScreen}
            />
            <Route
                exact
                path={Routes.guide}
                component={GuideScreen}
            />
            <Route
                exact
                path={Routes.widget}
                component={WidgetScreen}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default MainRouter;
