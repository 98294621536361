//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

const unitStatus = Object.freeze({
    available: 'AVAILABLE',
    reserved:  'RESERVED',
    sold:      'SOLD',
});

export const getUnitStatusOptions = () => {
    return _.map(unitStatus, (status) => {
        const toLowerStatus = _.toLower(status);

        return (
            {
                label: I18n.t(`unitStatus.${toLowerStatus}`),
                value: status,
            }
        );
    });
};

export default unitStatus;
