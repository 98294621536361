//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    fontColor:    PropTypes.string,
    label:        PropTypes.string,
    onChange:     PropTypes.func,
    options:      PropTypes.array,
    primaryColor: PropTypes.string,
    value:        PropTypes.any,
};

const RadioCheckBox = ({
    fontColor    = Colors.white,
    label        = '',
    primaryColor = Colors.darkGreen,
    options      = [],
    value        = null,
    onChange     = _.noop,
}) => {
    const [currentValue, setCurrentValue] = useState(value);
    const onClickOption                   = (optionValue) => () => {
        setCurrentValue(optionValue);
        onChange(optionValue);
    };
    const fontColorStyle                  = {
        color: fontColor,
    };

    function renderOption(index, option) {
        const optionValue  = _.get(option, 'value', null);
        const isChecked    = currentValue === optionValue;
        const checkedStyle = (
            isChecked ?
                {
                    backgroundColor: primaryColor,
                } :
                null
        );

        return (
            <div
                key={optionValue}
                className={styles.inputWrapper}
                onClick={onClickOption(optionValue)}
            >
                <input
                    className={styles.input}
                    type="radio"
                    checked={isChecked}
                    onChange={onClickOption(optionValue)}
                />
                <label
                    className={classNames(styles.label, styles.checkmark)}
                    style={checkedStyle}
                >
                    {_.get(option, 'label', '')}
                </label>
            </div>
        );
    }

    return (
        <div
            className={styles.radioCheckBox}
            style={fontColorStyle}
        >
            <div className={styles.radioLabel}>
                {label}
            </div>
            <div className={styles.radioOptions}>
                {_.map(options, (option, index) => renderOption(index, option))}
            </div>
        </div>
    );
};

RadioCheckBox.propTypes = propTypes;

export default RadioCheckBox;
