//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import ObjectTable          from '@connected/ObjectTable';
import useContextTranslator from '@hooks/ContextTranslator';
import { useObject }        from '@slices/object';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import IconType             from '@stateless/atomic/Icon/IconType';
import LayoutScreen         from '@stateless/composed/LayoutScreen';
import PageTitle            from '@stateless/composed/PageTitle';
import { selectObjects }    from '@store/selectors/object';

import styles from './styles.module.scss';

const ObjectsScreen = () => {
    const translator    = useContextTranslator('screens.objectScreen');
    const dispatch      = useDispatch();
    const objectActions = useObject(dispatch);
    const objects       = useSelector(selectObjects);

    useEffect(() => {
        objectActions.fetchObjects();
    }, []);

    function renderExpandableObjectItems() {
        return _.map(objects, (object) => {
            return (
                <ObjectTable
                    object={object}
                />
            );
        });
    }

    function onNewObjectClicked() {
        objectActions.editObject();
    }

    return (
        <>
            <PageTitle
                title={I18n.t('objects')}
            />
            <LayoutScreen>
                <div className={styles.objectsScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('headline')}
                    />
                    <p>
                        {translator.t('text')}
                    </p>
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('newObject')}
                        iconLeft={IconType.circlePlus}
                        onClick={onNewObjectClicked}
                    />
                    <div className={styles.objects}>
                        {renderExpandableObjectItems()}
                    </div>
                </div>
            </LayoutScreen>
        </>
    );
};

export default ObjectsScreen;
