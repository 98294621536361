//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes            from '@components/PropTypes';
import ParkingType          from '@constants/ParkingType';
import UnitFields           from '@constants/UnitFields';
import InputRenderHelper    from '@helper/InputRenderHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUnit }          from '@slices/unit';
import Button               from '@stateless/atomic/Button';
import ButtonColor          from '@stateless/atomic/Button/ButtonColor';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import DropDown             from '@stateless/atomic/DropDown';
import DropDownType         from '@stateless/atomic/DropDown/DropDownType';
import IconType             from '@stateless/atomic/Icon/IconType';
import LabeledInputType     from '@stateless/atomic/LabeledInput/LabeledInputType';
import Spacer               from '@stateless/atomic/Spacer';
import selectEditUnit       from '@store/selectors/unit';

import styles from './styles.module.scss';

const propTypes = {
    onUpdateData: PropTypes.func,
    validations:  PropTypes.object,
};

const ParkingSpaceList = ({
    validations,
    onUpdateData,
}) => {
    const dispatch              = useDispatch();
    const unitActions           = useUnit(dispatch);
    const unit                  = useSelector(selectEditUnit);
    const translator            = useContextTranslator('components.parkingSpaceList');
    const parkingTypesSelection = Object.keys(ParkingType).map((key) => {
        return {
            label: translator.t(key),
            value: _.get(ParkingType, key),
        };
    });
    const parkingSpaces         = _.get(unit, UnitFields.carParkingSpaces, []);
    const inputRenderer         = new InputRenderHelper({
        validations,
        data: unit,
        onUpdateData,
    });

    function addParkingSpaceClicked() {
        unitActions.addCarParkingSpace();
    }

    function deleteParkingSpaceClicked(index) {
        return () => {
            unitActions.deleteCarParkingSpace({
                index,
            });
        };
    }

    function renderEmptyText() {
        if (parkingSpaces.length === 0) {
            return (
                <div className={styles.emptyText}>
                    {translator.t('noParkingSpaces')}
                </div>
            );
        }

        return null;
    }

    function renderParkingSpaces() {
        return _.map(parkingSpaces, (parkingSpace, index) => {
            const typeField = [UnitFields.carParkingSpaces, index, UnitFields.carParkingSpaceType];

            return (
                <div
                    className={styles.tableRowContainer}
                    key={index}
                >
                    <DropDown
                        dropDownType={DropDownType.white}
                        options={parkingTypesSelection}
                        value={_.get(unit, typeField)}
                        onChange={onUpdateData(typeField)}
                    />
                    {inputRenderer.renderCurrencyInput({
                        type:  LabeledInputType.lightWithoutLabel,
                        field: [UnitFields.carParkingSpaces, index, UnitFields.carParkingSpacePurchasePrice],
                    })}
                    {inputRenderer.renderCurrencyInput({
                        type:  LabeledInputType.lightWithoutLabel,
                        field: [UnitFields.carParkingSpaces, index, UnitFields.carParkingSpaceMonthlyRent],
                    })}
                    <Button
                        type={ButtonType.icon}
                        iconLeft={IconType.trash}
                        onClick={deleteParkingSpaceClicked(index)}
                    />
                </div>
            );
        });
    }

    return (
        <div className={styles.parkingSpaceList}>
            <div className={styles.headerRow}>
                <div>
                    {translator.t('type')}
                </div>
                <div>
                    {translator.t('buyingPrice')}
                </div>
                <div>
                    {translator.t('rentingPrice')}
                </div>
            </div>
            <Spacer height={10} />
            {renderParkingSpaces()}
            {renderEmptyText()}
            <Spacer height={10} />
            <Button
                type={ButtonType.edgy}
                color={ButtonColor.white}
                text={translator.t('addParkingSpace')}
                onClick={addParkingSpaceClicked}
            />
        </div>
    );
};

ParkingSpaceList.propTypes = propTypes;

export default ParkingSpaceList;
