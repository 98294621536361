//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    company:                          'company',
    firstname:                        'firstname',
    lastname:                         'lastname',
    address:                          'address',
    street:                           'address.street',
    houseNumber:                      'address.houseNumber',
    postalCode:                       'address.postalCode',
    city:                             'address.city',
    phone:                            'phone',
    mobile:                           'mobile',
    website:                          'website',
    image:                            'image',
    newPassword:                      'newPassword',
    newPasswordRepeat:                'newPasswordRepeat',
    baseSettings:                     'baseSettings',
    email:                            'email',
    password:                         'password',
    passwordRepeat:                   'passwordRepeat',
    calculationFields:                'calculationFields',
    annualTaxableIncome:              'annualTaxableIncome',
    interest:                         'interest',
    redemption:                       'redemption',
    increaseInIncome:                 'increaseInIncome',
    increaseInIncomeInterval:         'increaseInIncomeInterval',
    rentIncrease:                     'rentIncrease',
    rentIncreaseInterval:             'rentIncreaseInterval',
    increaseInOperatingCosts:         'increaseInOperatingCosts',
    increaseInOperatingCostsInterval: 'increaseInOperatingCostsInterval',
    increaseInValue:                  'increaseInValue',
    increaseInValueInterval:          'increaseInValueInterval',
    termsAccepted:                    'termsAccepted',
});
