//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

const taxRates = Object.freeze([
    0,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
]);

export const getTaxOptions = () => {
    const percentage = I18n.t('format.percentSuffix');

    return taxRates.map((rate) => {
        const formattedRate = rate.toLocaleString('de-DE');

        return (
            {
                label: `${formattedRate}${percentage}`,
                value: rate,
            }
        );
    });
};

export default taxRates;
