//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useState }  from 'react';
import { useEffect } from 'react';

const useElementDimensions = (reference) => {
    const [width, setWidth]                 = useState(null);
    const [height, setHeight]               = useState(null);
    const [paddingHeight, setPaddingHeight] = useState(null);
    const [paddingWidth, setPaddingWidth]   = useState(null);
    const getDimensionWithoutPadding        = (element, dimensionWidth, dimensionHeight) => {
        if (
            paddingHeight === null ||
            paddingWidth === null
        ) {
            const style         = window.getComputedStyle(element);
            const paddingTop    = parseFloat(style.paddingTop);
            const paddingBottom = parseFloat(style.paddingBottom);
            const paddingLeft   = parseFloat(style.paddingLeft);
            const paddingRight  = parseFloat(style.paddingRight);

            setPaddingHeight(paddingTop + paddingBottom);
            setPaddingWidth(paddingLeft + paddingRight);
        }

        return {
            heightWithoutPadding: dimensionHeight - paddingHeight,
            widthWithoutPadding:  dimensionWidth - paddingWidth,
        };
    };

    useEffect(() => {
        const handleResize = () => {
            const current = reference.current;

            if (current) {
                const { clientWidth, clientHeight }                 = current;
                const { widthWithoutPadding, heightWithoutPadding } = getDimensionWithoutPadding(current, clientWidth, clientHeight);

                setWidth(widthWithoutPadding * 0.99);
                setHeight(heightWithoutPadding * 0.99);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [reference]);

    return {
        width,
        height,
    };
};

export default useElementDimensions;
