//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';

import PropTypes            from '@components/PropTypes';
import UnitFields           from '@constants/UnitFields';
import UnitStatus           from '@constants/UnitStatus';
import Formatter            from '@helper/Formatter';
import NumberFormat         from '@helper/NumberFormat';
import useContextTranslator from '@hooks/ContextTranslator';
import { useObject }        from '@slices/object';
import { useUnit }          from '@slices/unit';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Expandable           from '@stateless/atomic/Expandable';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import IconType             from '@stateless/atomic/Icon/IconType';
import RowMenu              from '@stateless/atomic/RowMenu';
import Spacer               from '@stateless/atomic/Spacer';
import TrafficLight         from '@stateless/atomic/TrafficLight';
import LightColor           from '@stateless/atomic/TrafficLight/LightColor';
import ConfirmModal         from '@stateless/composed/ConfirmModal';
import GroupedTable         from '@stateless/composed/GroupedTable';
import ObjectStatus         from '@stateless/composed/ObjectStatus';

const propTypes = {
    object: PropTypes.object,
};

const ObjectTable = ({
    object = null,
}) => {
    const translator                                        = useContextTranslator('screens.objectScreen');
    const dispatch                                          = useDispatch();
    const objectActions                                     = useObject(dispatch);
    const unitActions                                       = useUnit(dispatch);
    const objectUnits                                       = object.units;
    const [showDeleteUnitModal, setShowDeleteUnitModal]     = useState(false);
    const [showDeleteObjectModel, setShowDeleteObjectModel] = useState(false);
    const [currentUnit, setCurrentUnit]                     = useState(null);

    function onDeleteUnitConfirmed() {
        setShowDeleteUnitModal(false);
        unitActions.deleteUnit({
            unit: currentUnit,
        });
    }

    function onDeleteObjectConfirmed() {
        setShowDeleteObjectModel(false);
        objectActions.deleteObject({
            object,
        });
    }

    function onDeleteObjectClicked() {
        setShowDeleteObjectModel(true);
    }

    function onDeleteObjectModalClosed() {
        setShowDeleteObjectModel(false);
    }

    function onDeleteUnitModalClosed() {
        setShowDeleteUnitModal(false);
        setCurrentUnit(null);
    }

    function onDeleteUnitClicked(unit) {
        setShowDeleteUnitModal(true);
        setCurrentUnit(unit);
    }

    function onEditObjectClicked() {
        objectActions.editObject({
            object,
        });
    }

    function onEditUnitClicked(unit) {
        unitActions.editUnit({
            unit,
        });
    }

    function onNewUnitClicked() {
        unitActions.createNewUnit({
            object,
        });
    }

    const expandableMenuItems = [
        {
            id:      1,
            text:    I18n.t('edit'),
            icon:    IconType.pen,
            onClick: onEditObjectClicked,
        },
        {
            id:      2,
            text:    I18n.t('createUnit'),
            icon:    IconType.plus,
            onClick: onNewUnitClicked,
        },
        {
            id:      3,
            text:    I18n.t('delete'),
            icon:    IconType.trash,
            onClick: onDeleteObjectClicked,
        },
    ];
    const rowMenuItems        = [
        {
            id:      1,
            text:    I18n.t('edit'),
            icon:    IconType.pen,
            onClick: onEditUnitClicked,
        },
        {
            id:      3,
            text:    I18n.t('delete'),
            icon:    IconType.trash,
            onClick: onDeleteUnitClicked,
        },
    ];

    function renderOnline(item) {
        const online      = _.get(item, UnitFields.showUnitInCalculator);
        const color       = (
            online ?
                LightColor.green :
                LightColor.red
        );
        const infoTextKey = (
            online ?
                'online' :
                'offline'
        );

        return (
            <TrafficLight
                color={color}
                infoText={I18n.t(`unitStatus.${infoTextKey}`)}
            />
        );
    }

    function renderStatus(item) {
        const status          = _.get(item, UnitFields.status);
        const lowerCaseStatus = _.toLower(status);
        let color;

        switch (status) {
            case UnitStatus.sold:
                color = LightColor.red;

                break;

            case UnitStatus.reserved:
                color = LightColor.yellow;

                break;

            case UnitStatus.available:
                color = LightColor.green;

                break;
        }

        return (
            <TrafficLight
                color={color}
                infoText={I18n.t(`unitStatus.${lowerCaseStatus}`)}
            />
        );
    }

    function renderRowMenu(unit) {
        if (rowMenuItems.length === 0) {
            return null;
        }

        return (
            <RowMenu
                menuItems={rowMenuItems}
                targetData={unit}
            />
        );
    }

    function formatToCurrency(digits = 0) {
        return (value) => {
            return NumberFormat.formatToEuroString(value, digits);
        };
    }

    function formatToSquareMeter(value) {
        return NumberFormat.format(
            value,
            {
                suffix: I18n.t('format.squareMeter'),
            },
        );
    }

    function formatToPercent(value) {
        return Formatter.formatToPercentDigits(2)(value);
    }

    function sumUpUnitField(units, field) {
        return _.sumBy(units, (unit) => {
            return _.get(unit, field);
        });
    }

    const rowColumns  = [
        {
            columns: 3,
            items:   [
                {
                    title:    object.description,
                    subtitle: object.addressString,
                },
            ],
        },
        {
            columns: 7,
            items:   [
                {
                    prefixLine: true,
                    title:      I18n.t('sumPurchasePrice'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.singlePurchasePrice),
                    formatter:  formatToCurrency(),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('sumKfz'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.unitCarParkingPurchasePrice),
                    formatter:  formatToCurrency(),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('pricePerSquareMeter'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.purchasePricePerSquareMeter),
                    formatter:  formatToSquareMeter,
                },
                {
                    prefixLine: true,
                    title:      I18n.t('rentalYield'),
                    subtitle:   sumUpUnitField(objectUnits, UnitFields.returnYield),
                    formatter:  formatToPercent,
                },
                {
                    prefixLine: true,
                    title:      I18n.t('status'),
                    subtitle:   <ObjectStatus object={object} />,
                },
            ],
        },
    ];
    const tableGroups = [
        {
            id:         1,
            columnSpan: 5,
            children:   I18n.t('data'),
        },
        {
            id:         2,
            columnSpan: 6,
            children:   I18n.t('purchasePrice'),
        },
        {
            id:         3,
            columnSpan: 8,
            children:   I18n.t('rent'),
        },
        {
            id:         4,
            columnSpan: 6,
            children:   null,
        },
    ];
    const tableCells  = [
        {
            id:           1,
            columnSpan:   1,
            children:     I18n.t('number'),
            propertyName: UnitFields.number,
        },
        {
            id:           2,
            columnSpan:   2,
            children:     I18n.t('rooms'),
            propertyName: UnitFields.roomCount,
        },
        {
            id:           3,
            columnSpan:   2,
            children:     I18n.t('area'),
            propertyName: UnitFields.spaceInSquareMeters,
            formatter:    formatToSquareMeter,
        },
        {
            id:           4,
            columnSpan:   2,
            children:     I18n.t('unit'),
            propertyName: UnitFields.singlePurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           5,
            columnSpan:   2,
            children:     I18n.t('kfz'),
            propertyName: UnitFields.unitCarParkingPurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            id:           6,
            columnSpan:   2,
            children:     I18n.t('perSquareMeter'),
            propertyName: UnitFields.purchasePricePerSquareMeter,
            formatter:    Formatter.formatToCurrency(),

        },
        {
            id:           7,
            columnSpan:   2,
            children:     I18n.t('cold'),
            propertyName: UnitFields.singleMonthlyRent,
            formatter:    Formatter.formatToCurrency(),

        },
        {
            id:           8,
            columnSpan:   2,
            children:     I18n.t('kfz'),
            propertyName: UnitFields.unitCarParkingMonthlyRent,
            formatter:    Formatter.formatToCurrency(),

        },
        {
            id:           9,
            columnSpan:   2,
            children:     I18n.t('perSquareMeter'),
            propertyName: UnitFields.monthlyRentPerSquareMeter,
            formatter:    Formatter.formatToCurrency(2),
        },
        {
            id:           10,
            columnSpan:   2,
            children:     I18n.t('rentalYield'),
            propertyName: UnitFields.returnYield,
            formatter:    Formatter.formatToPercent,
        },
        {
            id:           11,
            columnSpan:   2,
            children:     I18n.t('status'),
            customRender: renderStatus,
        },
        {
            id:           12,
            columnSpan:   2,
            children:     I18n.t('online'),
            customRender: renderOnline,
        },
        {
            id:           13,
            columnSpan:   2,
            children:     null,
            customRender: renderRowMenu,
        },
    ];

    return (
        <Expandable
            expandButtonText={I18n.t('showUnits')}
            expandButtonColumns={2}
            rowColumns={rowColumns}
            menuItems={expandableMenuItems}
        >
            <Headline
                title={I18n.t('units')}
                type={HeadlineType.headline4}
            />
            <Spacer height={20} />
            <GroupedTable
                tableCells={tableCells}
                tableGroups={tableGroups}
                dataItems={objectUnits}
            />
            <Spacer height={20} />
            <Button
                type={ButtonType.edgy}
                text={I18n.t('newUnit')}
                iconLeft={IconType.circlePlus}
                onClick={onNewUnitClicked}
            />
            <ConfirmModal
                show={showDeleteUnitModal}
                onConfirm={onDeleteUnitConfirmed}
                title={translator.t('deleteUnitTitle')}
                text={translator.t('deleteUnitText')}
                onClose={onDeleteUnitModalClosed}
            />
            <ConfirmModal
                show={showDeleteObjectModel}
                onConfirm={onDeleteObjectConfirmed}
                title={translator.t('deleteObjectTitle')}
                text={translator.t('deleteObjectText')}
                onClose={onDeleteObjectModalClosed}
            />
        </Expandable>
    );
};

ObjectTable.propTypes = propTypes;

export default ObjectTable;
