//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const initialState = Object.freeze({
    edit:    {
        description:               null,
        address:                   {
            street:      null,
            houseNumber: null,
            addition:    null,
            postalCode:  null,
            city:        null,
            country:     null,
        },
        additionalPurchasingCosts: {
            realEstateTransferTax: 0,
            notaryFees:            0,
            mediationCosts:        0,
            unitCosts:             0,
        },
        ownerCosts:                {
            propertyManagerCosts: 0,
            unitManagerCosts:     0,
            reserves:             0,
        },
        keyTaxData:                {
            landShare: 0,
        },
    },
    objects: [],
});

const objectSlice = createSlice({
    name:     'object',
    initialState,
    reducers: {
        createNewObject:       (state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        },
        editObject:            (state, action) => {
            return update(state, {
                edit: {
                    $set: _.get(action, 'payload.object', initialState.edit),
                },
            });
        },
        editObjectData:        (state, action) => {
            return update(state, {
                edit: {
                    $set: Merge.mergeWith(
                        _.get(state, 'edit', initialState.edit),
                        _.get(action, 'payload.edit', {}),
                    ),
                },
            });
        },
        saveObject:            LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objectSave')),
        saveObjectFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveObjectSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        }),
        fetchObjects:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objects')),
        fetchObjectsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchObjectsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                objects: {
                    $set: _.get(action, 'payload.objects', []),
                },
            });
        }),
        deleteObject:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objectDelete')),
        deleteObjectFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteObjectSucceeded: LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        reset:                 () => {
            return {
                ...initialState,
            };
        },
    },
});

export const ObjectActions = objectSlice.actions;

export const ObjectReducer = objectSlice.reducer;

export const useObject = (dispatch) => bindActionCreators(ObjectActions, dispatch);

export default objectSlice;
