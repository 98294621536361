//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start

import _ from 'lodash';

import { ChangeTrackerActions } from '@slices/changeTracker';
import { InventoryActions }     from '@slices/inventory';

const changeTrackerConfigurations = {
    inventory: {
        actionsToTrack:        InventoryActions,
        changedAction:         InventoryActions.setChanged,
        dataPropertyInReducer: 'inventory',
        ignoredCompareField:   [
            'changed',
        ],
        saveReferenceActions:  InventoryActions.fetchItemsSucceeded().type,
    },
};

function handleSaveReference(dispatch, next, getState, action, configuration, reducerName) {
    const { dataPropertyInReducer } = configuration;
    const referenceObject           = _.get(getState(), dataPropertyInReducer);

    setTimeout(() => {
        dispatch(ChangeTrackerActions.saveReferenceObject({
            key: reducerName,
            referenceObject,
            configuration,
        }));
    }, 1);
}

function handleChangeCheck(dispatch, next, action, configuration, reducerName) {
    const changedActionType = configuration.changedAction().type;
    const allowedActions    = _.map(
        Object.values(configuration.actionsToTrack),
        (actionCreator) => {
            return actionCreator().type;
        },
    );

    if (
        action.type === changedActionType ||
        !_.includes(allowedActions, action.type)
    ) {
        return;
    }

    const { ignoredCompareField, changedAction } = configuration;

    setTimeout(() => {
        dispatch(ChangeTrackerActions.checkChanges({
            key: reducerName,
            ignoredCompareField,
            changedAction,
            configuration,
        }));
    }, 1);
}

function changeTrackerMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        const nextAction = next(action);

        for (const configurationKey in changeTrackerConfigurations) {
            const configuration            = changeTrackerConfigurations[configurationKey];
            const reducerName              = configurationKey;
            const { saveReferenceActions } = configuration;
            const actionType               = action.type;

            if (
                (
                    _.isArray(saveReferenceActions) &&
                    _.includes(saveReferenceActions, actionType)
                ) ||
                saveReferenceActions === actionType
            ) {
                handleSaveReference(dispatch, next, getState, action, configuration, reducerName);
            }

            handleChangeCheck(dispatch, next, action, configuration, reducerName);
        }

        return nextAction;
    };
}

export default changeTrackerMiddleware;
// TODO-Skeleton end
