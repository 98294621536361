//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    items:   [],
    changed: false,
});

const inventorySlice = createSlice({
    name:     'inventory',
    initialState,
    reducers: {
        fetchItems:          LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingInventory'),
        ),
        fetchItemsFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        fetchItemsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                items: {
                    $set: _.get(action, 'payload.items', []),
                },
            });
        }),
        setChanged:          (state, action) => {
            return update(state, {
                changed: {
                    $set: action.payload.changed,
                },
            });
        },
        shuffleItems:        (state, action) => {
            const items = _.shuffle(state.items);

            return update(state, {
                items: {
                    $set: items,
                },
            });
        },
    },
});

export const InventoryActions = inventorySlice.actions;

export const InventoryReducer = inventorySlice.reducer;

export const useInventory = (dispatch) => bindActionCreators(InventoryActions, dispatch);

export default inventorySlice;
