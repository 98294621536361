//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ApiUrls from '@constants/ApiUrls';

const customerApi = (api) => {
    return {
        saveCustomer:   (customer) => {
            const iri = _.get(customer, 'iri');

            if (iri) {
                return api.put(
                    iri,
                    customer,
                );
            }

            return api.post(ApiUrls.API_CUSTOMER_URL, customer);
        },
        deleteCustomer: (iri) => {
            return api.delete(iri);
        },
        fetchCustomers: () => {
            return api.get(ApiUrls.API_CUSTOMER_URL);
        },
    };
};

export default (api) => customerApi(api);
