//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CALL_HISTORY_METHOD } from 'connected-react-router';
import { LOCATION_CHANGE }     from 'connected-react-router';
import I18n                    from 'i18next';
import _                       from 'lodash';
import { REHYDRATE }           from 'redux-persist';
import { PERSIST }             from 'redux-persist';
import { FLUSH }               from 'redux-persist';
import { PAUSE }               from 'redux-persist';
import { PURGE }               from 'redux-persist';
import { REGISTER }            from 'redux-persist';

import Environment                      from '@helper/Environment';
import Token                            from '@helper/Token';
import { AlertBoxActions }              from '@slices/alertBox';
import { CalculationActions }           from '@slices/calculation';
import { CustomerActions }              from '@slices/customer';
import { DashboardActions }             from '@slices/dashboard';
import { InventoryActions }             from '@slices/inventory';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { ObjectActions }                from '@slices/object';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { UnitActions }                  from '@slices/unit';
import { UserActions }                  from '@slices/user';
import { WebAppBannerActions }          from '@slices/webAppBanner';
import { WidgetActions }                from '@slices/widget';
import { WindowActions }                from '@slices/window';

const whitelistedReactActions = [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    CALL_HISTORY_METHOD,
    LOCATION_CHANGE,
    LoadingActions.rehydrate().type,
    '&_INIT_MESSAGE_LISTENER',
    '&_INIT_SOCKET',
];

const actionBlockerConfigurations = {
    /* TODO-Skeleton Example for block actions if field in state is equals dataPropertyToBlockValue
    activeOffer:      {
        actionsToBlock:           ActiveOfferTypes,
        allowedActions:           [
            ActiveOfferTypes.SET_ACTIVE_OFFER,
        ],
        dataPropertyInReducer:    'activeOffer',
        dataPropertyToBlock:      'generalState',
        dataPropertyToBlockValue: OfferStatusHeaderStatus.completed,
    },
    */
    jwtTokenNotValid: {
        actionsToBlock:               [],
        blockAllActionsExceptAllowed: true,
        allowedActions:               [
            ObjectActions.reset().type,
            UnitActions.reset().type,
            CustomerActions.reset().type,
            DashboardActions.reset().type,
            CalculationActions.logout().type,
            LoadingActions.increaseLevel().type,
            LoadingActions.decreaseLevel().type,
            LoadingActions.resetClickCount().type,
            LoadingActions.resetOverlay().type,
            AlertBoxActions.clearAlerts().type,
            AlertBoxActions.clearSingleAlert().type,
            AlertBoxActions.showErrorAlert().type,
            AlertBoxActions.showErrorAlertTranslated().type,
            AlertBoxActions.showSuccessAlert().type,
            AlertBoxActions.showSuccessAlertTranslated().type,
            NavigationActions.redirect().type,
            InventoryActions.fetchItems().type,
            InventoryActions.fetchItemsSucceeded().type,
            InventoryActions.fetchItemsFailed().type,
            StagingAuthenticationActions.authenticate().type,
            StagingAuthenticationActions.authenticationPasswordChanged().type,
            StagingAuthenticationActions.authenticateSucceeded().type,
            UserActions.login().type,
            UserActions.loginWithCredentials().type,
            UserActions.loginFailed().type,
            UserActions.loginSucceeded().type,
            UserActions.logout().type,
            UserActions.setPassword().type,
            UserActions.setUsername().type,
            UserActions.tryRestoreToken().type,
            UserActions.tryRestoreTokenCompleted().type,
            UserActions.signup().type,
            UserActions.signupFailed().type,
            UserActions.signupSucceeded().type,
            UserActions.passwordReset().type,
            UserActions.passwordResetFailed().type,
            UserActions.passwordResetSucceeded().type,
            UserActions.setNewPassword().type,
            UserActions.setNewPasswordFailed().type,
            UserActions.setNewPasswordSucceeded().type,
            WebAppBannerActions.dismissInstallWebAppBanner().type,
            WindowActions.windowBecameHidden().type,
            WindowActions.windowBecameVisible().type,
            WindowActions.windowGotFocus().type,
            WindowActions.windowLostFocus().type,
            ..._.map(WidgetActions, (action) => action().type),
        ],
        shouldActionsBlockedFunction: (action, state) => {
            const token = _.get(state, ['user', 'token']);

            return (
                !token ||
                !Token.isValidJWTToken(token)
            );
        },
    },
};

function actionBlockerMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        let skipAction = false;

        for (const configurationKey in actionBlockerConfigurations) {
            const configuration                                                = actionBlockerConfigurations[configurationKey];
            const { allowedActions, actionsToBlock, dataPropertyToBlockValue } = configuration;
            const { dataPropertyInReducer, dataPropertyToBlock }               = configuration;
            const { shouldActionsBlockedFunction, blockedErrorMessage }        = configuration;
            const blockAllActionsExceptAllowed                                 = _.get(configuration, 'blockAllActionsExceptAllowed', false);
            const actionType                                                   = action.type;
            const actionTypeContainedInBlocked                                 = _.includes(actionsToBlock, actionType);
            const actionTypeContainedInAllowed                                 = _.includes(allowedActions, actionType);

            if (
                (
                    actionTypeContainedInBlocked ||
                    blockAllActionsExceptAllowed
                ) &&
                !actionTypeContainedInAllowed &&
                !_.includes(whitelistedReactActions, actionType)
            ) {
                if (shouldActionsBlockedFunction) {
                    const shouldBlockAction = shouldActionsBlockedFunction(action, getState());

                    if (shouldBlockAction) {
                        console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                        if (blockedErrorMessage) {
                            dispatch(AlertBoxActions.showErrorAlert({
                                text: I18n.t(blockedErrorMessage),
                            }));
                        }

                        skipAction = true;
                    }

                    break;
                }

                const stateDataPropertyValue = _.get(getState(), [dataPropertyInReducer, dataPropertyToBlock]);

                if (_.eq(stateDataPropertyValue, dataPropertyToBlockValue)) {
                    console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                    skipAction = true;
                }
            }
        }

        if (
            !skipAction ||
            Environment.isTest()
        ) {
            return next(action);
        }

        return next;
    };
}

export default actionBlockerMiddleware;
