//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n              from 'i18next';
import { NumericFormat } from 'react-number-format';

import PropTypes from '@components/PropTypes';

const propTypes = {
    decimalScale:      PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    suffix:            PropTypes.string,
    value:             PropTypes.number,
};

const Number = ({
    value             = null,
    suffix            = '',
    decimalScale      = 2,
    fixedDecimalScale = false,
}) => {
    return (
        <NumericFormat
            allowNegative={true}
            decimalSeparator={I18n.t('format.decimalSeparator')}
            displayType={'text'}
            suffix={suffix}
            thousandSeparator={I18n.t('format.thousandSeparator')}
            value={value}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
        />
    );
};

Number.propTypes = propTypes;

export default Number;
