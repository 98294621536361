//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import I18n           from 'i18next';
import _              from 'lodash';
import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { take }       from 'redux-saga/effects';

import * as Api                 from '@api/index';
import ApiUrls                  from '@constants/ApiUrls';
import CalculationFields        from '@constants/CalculationFields';
import Routes                   from '@constants/Routes';
import CalculationHelper        from '@helper/CalculationHelper';
import Notification             from '@helper/Notification';
import SagaStateHelper          from '@helper/SagaStateHelper';
import { CalculationActions }   from '@slices/calculation';
import { CustomerActions }      from '@slices/customer';
import { LoadingActions }       from '@slices/loading';
import { WidgetActions }        from '@slices/widget';
import NumberMapper             from '@store/helper/NumberMapper';
import selectCurrentCalculation from '@store/selectors/calculation';

function* saveCalculation() {
    const calculation  = yield SagaStateHelper.selector(selectCurrentCalculation);
    const response     = yield call(
        Api.context.calculation.saveCalculation,
        {
            ...NumberMapper.mapFieldsToNumber(
                calculation,
                [
                    CalculationFields.equityCapital,
                    [CalculationFields.calculationFields, CalculationFields.interest],
                    [CalculationFields.calculationFields, CalculationFields.redemption],
                    [CalculationFields.calculationFields, CalculationFields.increaseInIncome],
                    [CalculationFields.calculationFields, CalculationFields.increaseInIncomeInterval],
                    [CalculationFields.calculationFields, CalculationFields.increaseInValue],
                    [CalculationFields.calculationFields, CalculationFields.increaseInValueInterval],
                    [CalculationFields.calculationFields, CalculationFields.increaseInOperatingCosts],
                    [CalculationFields.calculationFields, CalculationFields.increaseInOperatingCostsInterval],
                    [CalculationFields.calculationFields, CalculationFields.rentIncrease],
                    [CalculationFields.calculationFields, CalculationFields.rentIncreaseInterval],
                ],
            ),
            unit:     _.get(calculation, [CalculationFields.unit, 'iri']),
            customer: _.get(calculation, [CalculationFields.customer, 'iri']),
        },
    );
    const isNewPayload = {
        isNew: !_.has(calculation, 'iri'),
    };

    if (response.ok) {
        yield put(CalculationActions.saveCalculationSucceeded({
            ...isNewPayload,
            calculation: response.data,
        }));
        yield put(CalculationActions.fetchCalculationResult());
    } else {
        yield put(CalculationActions.saveCalculationFailed(isNewPayload));
    }
}

function* fetchCalculationResult() {
    const calculation = yield SagaStateHelper.selector(selectCurrentCalculation);
    const unit        = _.get(calculation, 'unit', null);
    const customer    = _.get(calculation, 'customer', null);

    if (
        !unit ||
        !customer
    ) {
        yield put(LoadingActions.decreaseLevel({
            loadingAction: WidgetActions.fetchCalculationResult(),
        }));

        return;
    }

    const response = yield call(
        Api.context.calculation.fetchCalculationResult,
        CalculationHelper.mapCalculationToCalculationSource(calculation),
    );

    if (response.ok) {
        const data = response.data;

        yield put(CalculationActions.fetchCalculationResultSucceeded({
            calculationResult: data,
        }));
    } else {
        yield put(CalculationActions.fetchCalculationResultFailed());
    }
}

function getTranslation(action, translationKey) {
    const prefix = (
        _.get(action, 'payload.isNew') ?
            'createCalculation' :
            'updateCalculation'
    );

    return `${prefix}.${translationKey}`;
}

function* saveCalculationFailed(action) {
    Notification.error(getTranslation(action, 'error'));
}

function* saveCalculationSucceeded(action) {
    Notification.success(getTranslation(action, 'success'));
}

function* sendMail(action) {
    yield put(CalculationActions.saveCalculation());
    yield take([
        CalculationActions.saveCalculationSucceeded().type,
        CalculationActions.saveCalculationFailed().type,
    ]);
    yield take([
        CalculationActions.fetchCalculationResult().type,
    ]);
    yield put(LoadingActions.increaseLevel({
        loadingAction: action,
        text:          I18n.t('loading.calculationSendMail'),
    }));
    yield take([
        CalculationActions.fetchCalculationResultSucceeded().type,
        CalculationActions.fetchCalculationResultFailed().type,
    ]);

    const calculation   = yield SagaStateHelper.selector(selectCurrentCalculation);
    const calculationId = _.get(calculation, 'id');
    const email         = _.get(action, 'payload.email', null);
    const response      = yield call(
        Api.context.calculation.sendCalculationEmail,
        calculationId,
        {
            email,
        },
    );

    if (response.ok) {
        yield put(CalculationActions.sendMailSucceeded());
    } else {
        yield put(CalculationActions.sendMailFailed());
    }
}

function* sendMailFailed() {
    Notification.error('sendCalculationMail.error');
}

function* sendMailSucceeded() {
    Notification.success('sendCalculationMail.success');
}

function* fetchCalculation(action) {
    const { iri }  = action.payload;
    const response = yield call(
        Api.context.calculation.fetch,
        iri,
    );

    if (response.ok) {
        yield put(CalculationActions.fetchCalculationSucceeded({
            calculation: response.data,
        }));
    } else {
        yield put(CalculationActions.fetchCalculationFailed());
    }
}

function* fetchCalculationFailed() {
    Notification.error('fetchCalculation.error');
}

function* fetchCalculationSucceeded() {
    Notification.success('fetchCalculation.success');
}

function* deleteCalculation(action) {
    const { iri }  = action.payload;
    const response = yield call(
        Api.context.calculation.delete,
        iri,
    );

    if (response.ok) {
        yield put(CalculationActions.deleteCalculationSucceeded());
    } else {
        yield put(CalculationActions.deleteCalculationFailed());
    }
}

function* deleteCalculationFailed() {
    Notification.error('deleteCalculation.error');
}

function* deleteCalculationSucceeded() {
    Notification.success('deleteCalculation.success');
    yield put(CustomerActions.fetchCustomers());
}

function* openCalculation() {
    yield put(push(Routes.calculationCustomer));
}

function* downloadCalculationPDF() {
    yield put(CalculationActions.saveCalculation());
    yield take([
        CalculationActions.saveCalculationSucceeded().type,
        CalculationActions.saveCalculationFailed().type,
    ]);

    const calculation   = yield SagaStateHelper.selector(selectCurrentCalculation);
    const calculationId = _.get(calculation, 'id');

    Notification.success('downloadCalculationPDF.success');
    setTimeout(() => {
        window.open(Api.getUrl(ApiUrls.API_CALCULATIONS_DOWNLOAD(calculationId)), '_blank');
    }, 250);
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([CalculationActions.saveCalculation().type],            saveCalculation),
        takeLatest([CalculationActions.saveCalculationFailed().type],      saveCalculationFailed),
        takeLatest([CalculationActions.saveCalculationSucceeded().type],   saveCalculationSucceeded),
        takeLatest([CalculationActions.fetchCalculationResult().type],     fetchCalculationResult),
        takeLatest([CalculationActions.downloadCalculationPDF().type],     downloadCalculationPDF),
        takeLatest([CalculationActions.sendMail().type],                   sendMail),
        takeLatest([CalculationActions.sendMailFailed().type],             sendMailFailed),
        takeLatest([CalculationActions.sendMailSucceeded().type],          sendMailSucceeded),
        takeLatest([CalculationActions.openCalculation().type],            openCalculation),
        takeLatest([CalculationActions.fetchCalculation().type],           fetchCalculation),
        takeLatest([CalculationActions.fetchCalculationFailed().type],     fetchCalculationFailed),
        takeLatest([CalculationActions.fetchCalculationSucceeded().type],  fetchCalculationSucceeded),
        takeLatest([CalculationActions.deleteCalculation().type],          deleteCalculation),
        takeLatest([CalculationActions.deleteCalculationFailed().type],    deleteCalculationFailed),
        takeLatest([CalculationActions.deleteCalculationSucceeded().type], deleteCalculationSucceeded),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
