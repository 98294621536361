//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
    title:    PropTypes.string,
};

const DashboardWidget = ({
    title    = '',
    children = null,
}) => {
    return (
        <div className={styles.dashboardWidget}>
            <Headline
                title={title}
                type={HeadlineType.headline4}
            />
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

DashboardWidget.propTypes = propTypes;

export default DashboardWidget;
