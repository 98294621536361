//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { takeLatest } from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';

import * as Api             from '@api/index';
import Notification         from '@helper/Notification';
import { DashboardActions } from '@slices/dashboard';

function* fetchDashboard(action) {
    const response = yield call(Api.context.dashboard.fetchDashboard);

    if (response.ok) {
        yield put(DashboardActions.fetchDashboardSucceeded({
            dashboard: response.data,
        }));
    } else {
        yield put(DashboardActions.fetchDashboardFailed());
    }
}

function* fetchDashboardFailed() {
    Notification.error('dashboard.fetchFailed');
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([DashboardActions.fetchDashboard().type],       fetchDashboard),
        takeLatest([DashboardActions.fetchDashboardFailed().type], fetchDashboardFailed),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
