//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                              from 'lodash';
import { useDispatch }                from 'react-redux';
import { useSelector }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';
import { number }                     from 'yup';
import { boolean }                    from 'yup';

import { getCivilStatusOptions } from '@constants/CivilStatusType';
import ConfigurationValues       from '@constants/ConfigurationValues';
import CustomerFields            from '@constants/CustomerFields';
import Limit                     from '@constants/Limit';
import InputRenderHelper         from '@helper/InputRenderHelper';
import Notification              from '@helper/Notification';
import ValidationHelper          from '@helper/ValidationHelper';
import useContextTranslator      from '@hooks/ContextTranslator';
import { useCustomer }           from '@slices/customer';
import Button                    from '@stateless/atomic/Button';
import ButtonType                from '@stateless/atomic/Button/ButtonType';
import DropDown                  from '@stateless/atomic/DropDown';
import DropDownType              from '@stateless/atomic/DropDown/DropDownType';
import Headline                  from '@stateless/atomic/Headline';
import HeadlineType              from '@stateless/atomic/Headline/HeadlineType';
import IconType                  from '@stateless/atomic/Icon/IconType';
import LabeledWrapper            from '@stateless/composed/LableledWrapper';
import LayoutScreen              from '@stateless/composed/LayoutScreen';
import PageTitle                 from '@stateless/composed/PageTitle';
import TextSliderInput           from '@stateless/composed/TextSliderInput';
import TextSliderType            from '@stateless/composed/TextSliderInput/TextSliderType';
import selectEditCustomer        from '@store/selectors/customer';

import styles from './styles.module.scss';

const EditCustomerScreen = () => {
    const translator                    = useContextTranslator('screens.customersScreen');
    const calculationTranslator         = useContextTranslator('screens.calculationScreen');
    const dispatch                      = useDispatch();
    const customer                      = useSelector(selectEditCustomer);
    const customerActions               = useCustomer(dispatch);
    const isNewCustomer                 = !_.get(customer, 'iri');
    const buttonText                    = (
        isNewCustomer ?
            'createCustomer' :
            'saveCustomer'
    );
    const titleText                     = (
        isNewCustomer ?
            'createTitle' :
            'editTitle'
    );
    const pageTitle                     = (
        isNewCustomer ?
            'pageCreateTitle' :
            'pageEditTitle'
    );
    const [validations, setValidations] = useState({});
    const addressSchema                 = objectValidation({
        [_.last(CustomerFields.contactPersonStreet)]:      string().nullable().max(Limit.maxStringLength),
        [_.last(CustomerFields.contactPersonHouseNumber)]: string().nullable().max(Limit.maxStringLength),
        [_.last(CustomerFields.contactPersonPostalCode)]:  string().nullable().max(Limit.maxStringLength),
        [_.last(CustomerFields.contactPersonCity)]:        string().nullable().max(Limit.maxStringLength),
    });
    const contactPersonSchema           = (required = false) => {
        const conditionalStringRequired = (
            required ?
                string().required().max(Limit.maxStringLength) :
                string().nullable().max(Limit.maxStringLength)
        );

        return objectValidation({
            [CustomerFields.contactPersonLastname]:     conditionalStringRequired,
            [CustomerFields.contactPersonFirstname]:    conditionalStringRequired,
            [CustomerFields.contactPersonEmail]:        string().email().nullable(),
            [CustomerFields.contactPersonMobile]:       string().nullable().max(Limit.maxStringLength),
            [CustomerFields.contactPersonPhoneWork]:    string().nullable().max(Limit.maxStringLength),
            [CustomerFields.contactPersonPhonePrivate]: string().nullable().max(Limit.maxStringLength),
            [CustomerFields.contactPersonAddress]:      addressSchema,
        });
    };
    const customerFormSchema            = objectValidation({
        [CustomerFields.annualTaxableIncome]:      number().required(),
        [CustomerFields.increaseInIncomeInterval]: number(),
        [CustomerFields.increaseInIncome]:         number(),
        [CustomerFields.churchTax]:                boolean(),
        [CustomerFields.contactPersonOne]:         contactPersonSchema(true),
        [CustomerFields.contactPersonTwo]:         contactPersonSchema(false),
    });

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            if (_.has(validations, path)) {
                setValidations(_.omit(validations, path));
            }

            ValidationHelper.getValidations(customerFormSchema, customer, setValidations);
            _.set(changeSet, path, value);
            customerActions.editCustomerData({
                edit: changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations,
        data: customer,
        onUpdateData,
    });

    function onUpdateClicked() {
        const currentValidation = ValidationHelper.getValidations(customerFormSchema, customer, setValidations);

        if (ValidationHelper.hasNoValidations(currentValidation)) {
            customerActions.saveCustomer();
        } else {
            const prefix = (
                isNewCustomer ?
                    'createCustomer' :
                    'updateCustomer'
            );

            Notification.error(`${prefix}.validationError`);
        }
    }

    function renderSpacerRows(spacerAmount) {
        return _.times(spacerAmount, () => {
            return (
                <div />
            );
        });
    }

    function renderContactPerson(contactPersonField, required = false) {
        return (
            <LabeledWrapper
                label={calculationTranslator.t(contactPersonField)}
                columns={2}
            >
                {inputRenderer.renderTextInput({
                    label: translator.t('name'),
                    field: [contactPersonField, CustomerFields.contactPersonLastname],
                    required,
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('firstname'),
                    field: [contactPersonField, CustomerFields.contactPersonFirstname],
                    required,
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('street'),
                    field: [contactPersonField, ...CustomerFields.contactPersonStreet],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('houseNumber'),
                    field: [contactPersonField, ...CustomerFields.contactPersonHouseNumber],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('postalCode'),
                    field: [contactPersonField, ...CustomerFields.contactPersonPostalCode],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('city'),
                    field: [contactPersonField, ...CustomerFields.contactPersonCity],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('email'),
                    field: [contactPersonField, CustomerFields.contactPersonEmail],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('mobile'),
                    field: [contactPersonField, CustomerFields.contactPersonMobile],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('phone'),
                    field: [contactPersonField, CustomerFields.contactPersonPhonePrivate],
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('phoneBusiness'),
                    field: [contactPersonField, CustomerFields.contactPersonPhoneWork],
                })}
            </LabeledWrapper>
        );
    }

    function onSlideValueChanged(field) {
        return (value) => {
            onUpdateData(field)(_.toNumber(value));
        };
    }

    return (
        <>
            <PageTitle
                title={translator.t(pageTitle)}
            />
            <LayoutScreen>
                <div className={styles.editCustomerScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t(titleText)}
                    />
                    <div className={styles.divider}>
                        <div>
                            {renderContactPerson(CustomerFields.contactPersonOne, true)}
                            {renderContactPerson(CustomerFields.contactPersonTwo)}
                        </div>
                        <div>
                            <LabeledWrapper label={calculationTranslator.t('taxesAndFinance')}>
                                <DropDown
                                    label={calculationTranslator.t('maritalStatus')}
                                    dropDownType={DropDownType.whiteWithLabel}
                                    options={getCivilStatusOptions()}
                                    pickFirstOption={false}
                                    value={_.get(customer, CustomerFields.civilStatus)}
                                    onChange={onUpdateData(CustomerFields.civilStatus)}
                                />
                                <DropDown
                                    label={calculationTranslator.t('churchTax')}
                                    dropDownType={DropDownType.whiteWithLabel}
                                    options={[
                                        {
                                            value: true,
                                            label: translator.t('yes'),
                                        },
                                        {
                                            value: false,
                                            label: translator.t('no'),
                                        },
                                    ]}
                                    value={_.get(customer, CustomerFields.churchTax)}
                                    onChange={onUpdateData(CustomerFields.churchTax)}
                                />
                                {inputRenderer.renderCurrencyInput({
                                    label:    calculationTranslator.t('yearlyTaxableIncome'),
                                    field:    CustomerFields.annualTaxableIncome,
                                    required: true,
                                })}
                                {renderSpacerRows(1)}
                                <TextSliderInput
                                    label={calculationTranslator.t('incomeRaise')}
                                    minimumValue={ConfigurationValues.increaseInIncome.minimumValue}
                                    step={ConfigurationValues.increaseInIncome.step}
                                    maximumValue={ConfigurationValues.increaseInIncome.maximumValue}
                                    value={_.get(customer, CustomerFields.increaseInIncome, 0)}
                                    onChange={onSlideValueChanged(CustomerFields.increaseInIncome)}
                                />
                                <TextSliderInput
                                    label={calculationTranslator.t('incomeRaiseInterval')}
                                    minimumValue={ConfigurationValues.increaseInIncomeInterval.minimumValue}
                                    step={ConfigurationValues.increaseInIncomeInterval.step}
                                    maximumValue={ConfigurationValues.increaseInIncomeInterval.maximumValue}
                                    fixedDecimalLength={0}
                                    type={TextSliderType.intervalInYears}
                                    onChange={onSlideValueChanged(CustomerFields.increaseInIncomeInterval)}
                                    value={_.get(customer, CustomerFields.increaseInIncomeInterval, 0)}
                                />
                            </LabeledWrapper>
                        </div>
                    </div>
                    <Button
                        type={ButtonType.edgy}
                        iconLeft={IconType.circlePlus}
                        text={translator.t(buttonText)}
                        onClick={onUpdateClicked}
                    />
                </div>
            </LayoutScreen>
        </>
    );
};

export default EditCustomerScreen;
