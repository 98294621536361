//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class Address {
    static splitStreetAndHouseNumber = (streetAndHouseNumber) => {
        const regex = /(.*)\s+(\d+.*)/;
        const match = streetAndHouseNumber.match(regex);

        if (match) {
            return [
                match[1],
                match[2],
            ];
        }

        return [streetAndHouseNumber, ''];
    };

    static combineStreetAndHouseNumber(street, houseNumber) {
        if (
            _.isNil(street) &&
            _.isNil(houseNumber)
        ) {
            return null;
        }

        return `${street} ${houseNumber}`;
    }

    static buildAddressString(address) {
        const { street, houseNumber, postalCode, city } = address;
        const addressParts                              = [
            `${street ?? ''} ${houseNumber ?? ''}`,
            `${postalCode ?? ''} ${city ?? ''}`,
        ];

        return _.filter(addressParts, (part) => {
            return (
                !_.isNil(part) &&
                !_.isEmpty(part.trim())
            );
        }).join(', ');
    }
}

export default Address;
