//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                              from 'lodash';
import { useDispatch }                from 'react-redux';
import { useLocation }                from 'react-router';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';

import Logo                 from '@assets/images/logo.png';
import PasswordResetChange  from '@connected/PasswordResetChange';
import Routes               from '@constants/Routes';
import UserFields           from '@constants/UserFields';
import InputRenderHelper    from '@helper/InputRenderHelper';
import ValidationHelper     from '@helper/ValidationHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonSize           from '@stateless/atomic/Button/ButtonSize';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import TextInputType        from '@stateless/atomic/TextInput/TextInputType';
import PageTitle            from '@stateless/composed/PageTitle';
import SignupLoginSwitcher  from '@stateless/composed/SignupLoginSwitcher';

import styles from './styles.module.scss';

const PasswordReset = () => {
    const translator                        = useContextTranslator('screens.passwordReset');
    const dispatch                          = useDispatch();
    const userActions                       = useUser(dispatch);
    const [passwordReset, setPasswordReset] = useState({});
    const [validations, setValidations]     = useState([]);
    const passwordResetFormSchema           = objectValidation({
        [UserFields.email]: string().email().required().test(
            'is-valid-email',
            ValidationHelper.isValidEmail,
        ),
    });
    const location                          = useLocation();
    const searchParameters                  = new URLSearchParams(location.search);
    const passwordToken                     = searchParameters.get('password-token');
    const passwordEmail                     = searchParameters.get('email');

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            setPasswordReset({
                ...passwordReset,
                ...changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations,
        data: passwordReset,
        onUpdateData,
    });

    function onFormSubmit(event) {
        if (passwordToken) {
            return;
        }

        event.preventDefault();

        const currentValidation = ValidationHelper.getValidations(
            passwordResetFormSchema,
            passwordReset,
            setValidations,
        );
        const errorMessages     = {
            [UserFields.email]: 'signupEmailError',
        };

        ValidationHelper.showCustomError(currentValidation, errorMessages);

        if (ValidationHelper.hasNoValidations(currentValidation)) {
            userActions.passwordReset({
                email: _.get(passwordReset, UserFields.email),
            });
        }
    }

    function renderPasswordResetOrPasswordChange() {
        if (passwordToken) {
            return (
                <PasswordResetChange
                    email={passwordEmail}
                    passwordToken={passwordToken}
                />
            );
        }

        return (
            <>
                {inputRenderer.renderTextInput({
                    label:         translator.t('email'),
                    required:      true,
                    textInputType: TextInputType.email,
                    field:         UserFields.email,
                })}
                <Button
                    text={translator.t('resetPasswordButton')}
                    size={ButtonSize.big}
                />
            </>
        );
    }

    function getHeadline() {
        if (passwordToken) {
            return translator.t('headlineChange');
        }

        return translator.t('headline');
    }

    function getSubHeadline() {
        if (passwordToken) {
            return translator.t('subHeadlineChange');
        }

        return translator.t('subHeadline');
    }

    function renderForm(children) {
        if (passwordToken) {
            return children;
        }

        return (
            <form onSubmit={onFormSubmit}>
                {children}
            </form>
        );
    }

    return (
        <div className={styles.passwordResetWrapper}>
            <PageTitle
                title={translator.t('pageTitle')}
            />
            <img
                src={Logo}
                alt={'Logo'}
            />
            <Headline
                title={getHeadline()}
                type={HeadlineType.headline1}
            />
            {renderForm((
                <div className={styles.gridWrapper}>
                    <div className={styles.passwordResetFormWrapper}>
                        <Headline
                            title={getSubHeadline()}
                            type={HeadlineType.headline2}
                        />
                        <div className={styles.passwordResetForm}>
                            {renderPasswordResetOrPasswordChange()}
                            <SignupLoginSwitcher
                                text={translator.t('alreadySignedUp')}
                                targetText={translator.t('loginNow')}
                                target={Routes.login}
                            />
                            <SignupLoginSwitcher
                                text={translator.t('noAccount')}
                                targetText={translator.t('signupNow')}
                                target={Routes.signup}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PasswordReset;
