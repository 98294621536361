//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    lowerTitle: PropTypes.string,
    lowerValue: PropTypes.string,
    upperTitle: PropTypes.string,
    upperValue: PropTypes.string,
};

const SplitWidget = ({
    lowerTitle = '',
    lowerValue = '',
    upperTitle = '',
    upperValue = '',
}) => {
    return (
        <div className={styles.splitWidget}>
            <div className={styles.part}>
                <Headline
                    title={upperTitle}
                    type={HeadlineType.headline4}
                />
                <span className={styles.value}>
                    {upperValue}
                </span>
            </div>
            <div className={styles.part}>
                <Headline
                    title={lowerTitle}
                    type={HeadlineType.headline4}
                />
                <span className={styles.value}>
                    {lowerValue}
                </span>
            </div>
        </div>
    );
};

SplitWidget.propTypes = propTypes;

export default SplitWidget;
