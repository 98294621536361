//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes  from '@components/PropTypes';
import Button     from '@stateless/atomic/Button';
import ButtonType from '@stateless/atomic/Button/ButtonType';
import IconType   from '@stateless/atomic/Icon/IconType';

import AlertBoxType from './AlertBoxType';
import styles       from './styles.module.scss';

const propTypes = {
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    type:               PropTypes.oneOfObjectValues(AlertBoxType),
};

const AlertBox = ({
    onCloseButtonClick = null,
    text               = null,
    type               = null,
}) => {
    function renderCloseButton() {
        if (onCloseButtonClick) {
            return (
                <span className={styles.alertBoxCloseButtonWrapper}>
                    <Button
                        type={ButtonType.icon}
                        iconLeft={IconType.cross}
                        onClick={onCloseButtonClick}
                    />
                </span>
            );
        }

        return null;
    }

    const alertBoxTypeToIconStyle = {
        [AlertBoxType.error]:   styles.alertBoxError,
        [AlertBoxType.success]: styles.alertBoxSuccess,
    };

    return (
        <div
            className={classNames(
                styles.alertBox,
                alertBoxTypeToIconStyle[type],
            )}
        >
            {text}
            {renderCloseButton()}
        </div>
    );
};

AlertBox.propTypes = propTypes;

export default AlertBox;
