//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    columnSpan: PropTypes.number,
    visible:    PropTypes.bool,
};

const Separator = ({
    columnSpan = 1,
    visible    = true,
}) => {
    const style = {
        gridColumnEnd: `span ${columnSpan}`,
    };

    return (
        <div
            style={style}
            className={classNames(
                styles.separator,
                {
                    [styles.hidden]: !visible,
                },
            )}
        />
    );
};

Separator.propTypes = propTypes;

export default Separator;
