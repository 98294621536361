//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { getUrl } from '@api';

class IFrameHelper {
    static getIFrameChartUrl(chartName, parameters, width, height, appTheme) {
        const parameter = new URLSearchParams({
            ...parameters,
            width,
            height,
        });

        if (appTheme) {
            parameter.append('primaryColor', appTheme.primaryColor);
        }

        const parameterString = parameter.toString();
        const url             = getUrl(`/chart/${chartName}?${parameterString}`);

        return url;
    }
}

export default IFrameHelper;
