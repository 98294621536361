//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ApiUrls from '@constants/ApiUrls';

const colorSettingsApi = (api) => {
    return {
        fetchWidgetColorSettings: (userId, externalUserId) => {
            return api.get(
                ApiUrls.API_COLOR_SETTINGS_WIDGET_URL,
                {
                    userId,
                    externalUserId,
                },
            );
        },
        fetchColorSettings:       () => {
            return api.get(ApiUrls.API_COLOR_SETTINGS_URL);
        },
        saveColorSettings:        (colorSettings) => {
            const iri = _.get(colorSettings, 'iri');

            if (iri) {
                return api.put(
                    iri,
                    colorSettings,
                );
            }

            return api.post(ApiUrls.API_COLOR_SETTINGS_URL, colorSettings);
        },
    };
};

export default (api) => colorSettingsApi(api);
