//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { create } from 'apisauce';
import _          from 'lodash';

import CalculationApiContext   from '@api/context/calculation';
import ColorSettingsApiContext from '@api/context/color-settings';
import ConfigurationApiContext from '@api/context/configuration';
import ContactFormApiContext   from '@api/context/contactForm';
import CustomerApiContext      from '@api/context/customerApiContext';
import DashboardApiContext     from '@api/context/dashboardApiContext';
import ExampleApiContext       from '@api/context/example';
import ItemsApiContext         from '@api/context/items';
import ObjectApiContext        from '@api/context/objectApiContext';
import SubscriptionOptionsApi  from '@api/context/subscriptionOptions';
import TranslationsApiContext  from '@api/context/translations';
import UnitApiContext          from '@api/context/unit';
import UserApiContext          from '@api/context/user';
import ApiConfiguration        from '@constants/Api';
import MimeTypes               from '@constants/MimeTypes';
import Hydra                   from '@helper/Hydra';

export const getBaseUrlWithUrl = (baseUrl, url = '') => {
    let urlSuffix = url;

    if (
        baseUrl.endsWith('/') &&
        urlSuffix.startsWith('/')
    ) {
        urlSuffix = urlSuffix.substring(1);
    }

    return baseUrl + urlSuffix;
};

export const createInstance = (host, apiPath) => {
    ApiConfiguration.checkBackendUrl(host);

    const api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30000,
        headers: {
            'accept':       MimeTypes.jsonld,
            'Content-Type': MimeTypes.json,
        },
    });

    api.host   = host;
    api.getUrl = (url = '', baseUrl = api.getBaseURL()) => getBaseUrlWithUrl(baseUrl, url);

    api.addResponseTransform((response) => {
        if (response.ok) {
            if (Hydra.isHydraResponse(response.data)) {
                response.data = Hydra.removeHydraFromObject(response.data);
            }
        } else {
            console.warn('Api-Warning:', response.problem);
        }

        return response;
    });

    api.addMonitor((response) => {
        const responseData = _.get(response, 'data', {});

        if (
            !response.ok &&
            _.has(responseData, 'error') &&
            Notification.translationExistsForKey(responseData.error)
        ) {
            Notification.error(_.get(responseData, 'error'));

            responseData.showedCustomNotification = true;
        }
    });

    return api;
};

export const Api = createInstance(ApiConfiguration.getBackendUrl(), '/');

export const getUrl = (url = '') => (
    getBaseUrlWithUrl(Api.getBaseURL(), url)
);

export const context = {
    // TODO-Skeleton start
    configuration: ConfigurationApiContext(Api),
    example:       ExampleApiContext(Api),
    items:         ItemsApiContext(Api),
    user:          UserApiContext(Api),
    translations:  TranslationsApiContext(Api),
    // TODO-Skeleton end
    unit:                UnitApiContext(Api),
    calculation:         CalculationApiContext(Api),
    colorSettings:       ColorSettingsApiContext(Api),
    contactForm:         ContactFormApiContext(Api),
    object:              ObjectApiContext(Api),
    dashboard:           DashboardApiContext(Api),
    customer:            CustomerApiContext(Api),
    subscriptionOptions: SubscriptionOptionsApi(Api),
};
