//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _             from 'lodash';
import DebounceInput from 'react-debounce-input';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import InputClassHelper       from '@helper/InputClassHelper';
import InputWrapper           from '@stateless/atomic/InputWrapper';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    disabled:        PropTypes.bool,
    minLength:       PropTypes.number,
    multiline:       PropTypes.bool,
    name:            PropTypes.string,
    onBlur:          PropTypes.func,
    onChange:        PropTypes.func,
    onFocus:         PropTypes.func,
    onKeyDown:       PropTypes.func,
    onKeyUp:         PropTypes.func,
    placeholderText: PropTypes.string,
    postIconType:    PropTypes.iconType,
    resizable:       PropTypes.bool,
    size:            PropTypes.oneOfObjectValues(TextInputStyle),
    textAlignment:   PropTypes.oneOfObjectValues(TextInputTextAlignment),
    type:            PropTypes.string,
    value:           PropTypes.date,
};

const TextInput = ({
    disabled        = false,
    minLength       = 1,
    multiline       = false,
    onBlur          = DefaultTypes.func,
    onChange        = DefaultTypes.func,
    name            = null,
    onFocus         = DefaultTypes.func,
    onKeyUp         = DefaultTypes.func,
    onKeyDown       = DefaultTypes.func,
    placeholderText = null,
    postIconType    = null,
    size            = TextInputStyle.default,
    textAlignment   = TextInputTextAlignment.left,
    type            = TextInputType.text,
    value           = '',
    resizable       = false,
}) => {
    function valueChanged(event) {
        onChange(
            _.get(event, 'target.value'),
            event,
        );
    }

    function renderInputModeText() {
        const element   = (
            multiline ?
                'textarea' :
                'input'
        );
        const className = InputClassHelper.getInputClassNames(
            styles,
            disabled,
            multiline,
            postIconType,
            resizable,
        );

        return (
            <DebounceInput
                className={className}
                element={element}
                debounceTimeout={500}
                disabled={disabled}
                minLength={minLength}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={valueChanged}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                placeholder={placeholderText}
                value={value ?? ''}
                name={name}
                type={type}
            />
        );
    }

    return (
        <InputWrapper
            size={size}
            textAlignment={textAlignment}
            postIconType={postIconType}
        >
            {renderInputModeText()}
        </InputWrapper>
    );
};

TextInput.propTypes = propTypes;

export default TextInput;
