//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    contactPersonOne:          'customerPerson1',
    contactPersonTwo:          'customerPerson2',
    contactPersonSalutation:   'salutation',
    contactPersonTitle:        'title',
    contactPersonFirstname:    'firstname',
    contactPersonLastname:     'lastname',
    contactPersonStreet:       ['address', 'street'],
    contactPersonHouseNumber:  ['address', 'houseNumber'],
    contactPersonPostalCode:   ['address', 'postalCode'],
    contactPersonCity:         ['address', 'city'],
    contactPersonAddress:      'address',
    contactPersonEmail:        'email',
    contactPersonMobile:       'mobile',
    contactPersonPhoneWork:    'phoneWork',
    contactPersonPhonePrivate: 'phonePrivate',
    increaseInIncomeInterval:  'increaseInIncomeInterval',
    increaseInIncome:          'increaseInIncome',
    civilStatus:               'civilStatus',
    churchTax:                 'churchTax',
    annualTaxableIncome:       'annualTaxableIncome',
    propertyName:              'propertyName',
    updatedAt:                 'updatedAt',
    number:                    'unit.number',
    carParkingMonthlyRent:     'unit.carParkingMonthlyRent',
    carParkingPurchasePrice:   'unit.carParkingPurchasePrice',
    singleMonthlyRent:         'unit.singleMonthlyRent',
    singlePurchasePrice:       'unit.singlePurchasePrice',
    returnYield:               'unit.returnYield',
    financingAmount:           'financingAmount',
    equityCapital:             'equityCapital',
    cashflowPerMonth:          'cashflowPerMonth',
    returnOnCapital:           'returnOnCapital',
});
