//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    interest:                         {
        maximumValue: 10,
        minimumValue: 0.5,
        step:         0.05,
    },
    redemption:                       {
        maximumValue: 10,
        minimumValue: 0.25,
        step:         0.05,
    },
    income:                           {
        maximumValue: 500000,
        minimumValue: 10000,
        step:         1000,
    },
    equity:                           {
        maximumValue: (purchasePrice) => {
            return purchasePrice;
        },
        minimumValue: 0,
        step:         1000,
    },
    increaseInIncome:                 {
        maximumValue: 10,
        minimumValue: 0,
        step:         0.5,
    },
    rentIncrease:                     {
        maximumValue: 20,
        minimumValue: 0,
        step:         0.5,
    },
    increaseInOperatingCosts:         {
        maximumValue: 10,
        minimumValue: 0,
        step:         0.5,
    },
    increaseInValue:                  {
        maximumValue: 10,
        minimumValue: 0,
        step:         0.5,
    },
    increaseInIncomeInterval:         {
        maximumValue: 10,
        minimumValue: 1,
        step:         1,
    },
    rentIncreaseInterval:             {
        maximumValue: 10,
        minimumValue: 1,
        step:         1,
    },
    increaseInOperatingCostsInterval: {
        maximumValue: 10,
        minimumValue: 1,
        step:         1,
    },
    increaseInValueInterval:          {
        maximumValue: 10,
        minimumValue: 1,
        step:         1,
    },
    landShare:                        {
        maximumValue: 50,
        minimumValue: 0,
        step:         1,
    },
});
