//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n                           from 'i18next';
import _                              from 'lodash';
import Files                          from 'react-files';
import { useIndexedDB }               from 'react-indexed-db-hook';
import { useDispatch }                from 'react-redux';
import { useSelector }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';

import PasswordChange       from '@connected/PasswordChange';
import Limit                from '@constants/Limit';
import UserFields           from '@constants/UserFields';
import FileUpload           from '@helper/FileUpload';
import ImageHelper          from '@helper/ImageHelper';
import ImageStorage         from '@helper/ImageStorage';
import InputRenderHelper    from '@helper/InputRenderHelper';
import Notification         from '@helper/Notification';
import NumberFormat         from '@helper/NumberFormat';
import ValidationHelper     from '@helper/ValidationHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonColor          from '@stateless/atomic/Button/ButtonColor';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import Spacer               from '@stateless/atomic/Spacer';
import LabeledWrapper       from '@stateless/composed/LableledWrapper';
import LayoutScreen         from '@stateless/composed/LayoutScreen';
import PageTitle            from '@stateless/composed/PageTitle';
import { selectUser }       from '@store/selectors/user';

import styles from './styles.module.scss';

const ProfileScreen = () => {
    const imageSize                     = 10000000;
    const imageDatabase                 = useIndexedDB('image');
    const translator                    = useContextTranslator('screens.profileScreen');
    const dispatch                      = useDispatch();
    const userActions                   = useUser(dispatch);
    const user                          = useSelector(selectUser);
    const [validations, setValidations] = useState({});
    const profileFormSchema             = objectValidation({
        [UserFields.street]:      string().max(Limit.maxStringLength).nullable(),
        [UserFields.houseNumber]: string().max(Limit.maxStringLength).nullable(),
        [UserFields.postalCode]:  string().max(Limit.maxStringLength).nullable(),
        [UserFields.city]:        string().max(Limit.maxStringLength).nullable(),
        [UserFields.company]:     string().max(Limit.maxStringLength).nullable(),
        [UserFields.firstname]:   string().max(Limit.maxStringLength),
        [UserFields.lastname]:    string().max(Limit.maxStringLength),
        [UserFields.phone]:       string().max(Limit.maxStringLength).nullable(),
        [UserFields.mobile]:      string().max(Limit.maxStringLength).nullable(),
        [UserFields.website]:     string().max(Limit.maxStringLength).nullable(),
    });
    const [loadedImage, setLoadedImage] = useState(null);
    const addImageFile                  = async (files, database) => {
        const addedFiles  = await FileUpload.addFiles(files, database);
        const storageFile = await ImageStorage.loadImage(addedFiles[0].fileId, database);

        setLoadedImage(storageFile);
        userActions.setUserImage({
            image: addedFiles[0].fileId,
        });
    };

    useEffect(() => {
        ImageStorage.clear(imageDatabase);
    }, []);

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            if (_.has(validations, path)) {
                setValidations(_.omit(validations, path));
            }

            _.set(changeSet, path, value);
            userActions.updateUserData({
                user: changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations,
        data: user,
        onUpdateData,
    });

    function onUpdateClicked() {
        const currentValidations = ValidationHelper.getValidations(profileFormSchema, user, setValidations);

        if (ValidationHelper.hasNoValidations(currentValidations)) {
            userActions.saveUser();
        } else {
            Notification.error('updateUser.validationError');
        }
    }

    async function handleChange(files) {
        await addImageFile(files, imageDatabase);
    }

    function handleError(error) {
        Notification.error('imageTooLarge', {
            imageSize: NumberFormat.toHumanReadableFileSize(imageSize),
        });
    }

    function removePictureClicked() {
        userActions.setUserImage({
            image: null,
        });
    }

    function renderFilePreview() {
        let image = _.get(user, UserFields.image);

        if (_.isNumber(image)) {
            image = loadedImage;
        }

        if (image) {
            return (
                <img
                    src={ImageHelper.getImagePath(image)}
                    alt={translator.t('profilePicture')}
                />
            );
        }

        return (
            <div>
                {translator.t('dropFilesOrClick')}
            </div>
        );
    }

    function renderFileInput() {
        return (
            <>
                <Spacer />
                <LabeledWrapper
                    small={true}
                    label={translator.t('profilePicture')}
                    columns={1}
                >
                    <Files
                        className={styles.fileUpload}
                        onChange={handleChange}
                        onError={handleError}
                        multiple={false}
                        maxFileSize={imageSize}
                        minFileSize={0}
                        clickable
                    >
                        {renderFilePreview()}
                    </Files>
                    <Button
                        text={translator.t('removePicture')}
                        type={ButtonType.edgy}
                        color={ButtonColor.white}
                        onClick={removePictureClicked}
                    />
                </LabeledWrapper>
            </>
        );
    }

    return (
        <>
            <PageTitle
                title={I18n.t('profile')}
            />
            <LayoutScreen>
                <div className={styles.profileScreen}>
                    <Headline
                        title={translator.t('headline')}
                        type={HeadlineType.headline1}
                    />
                    <p>
                        {translator.t('text')}
                    </p>
                    <div className={styles.profileInputWrapper}>
                        <LabeledWrapper
                            label={translator.t('userData')}
                            columns={1}
                        >
                            {inputRenderer.renderTextInput({
                                label: translator.t('company'),
                                field: UserFields.company,
                            })}
                            {inputRenderer.renderTextInput({
                                label:    translator.t('firstName'),
                                field:    UserFields.firstname,
                                required: true,
                            })}
                            {inputRenderer.renderTextInput({
                                label:    translator.t('lastName'),
                                field:    UserFields.lastname,
                                required: true,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('street'),
                                field: UserFields.street,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('houseNumber'),
                                field: UserFields.houseNumber,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('zipCode'),
                                field: UserFields.postalCode,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('city'),
                                field: UserFields.city,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('phone'),
                                field: UserFields.phone,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('mobile'),
                                field: UserFields.mobile,
                            })}
                            {inputRenderer.renderTextInput({
                                label: translator.t('website'),
                                field: UserFields.website,
                            })}
                            {renderFileInput()}
                            <Button
                                type={ButtonType.edgy}
                                text={translator.t('saveUserData')}
                                onClick={onUpdateClicked}
                            />
                        </LabeledWrapper>
                        <Spacer height={20} />
                        <PasswordChange />
                    </div>
                </div>
            </LayoutScreen>
        </>
    );
};

export default ProfileScreen;
