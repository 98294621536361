//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import styled     from 'styled-components';

import PropTypes    from '@components/PropTypes';
import { svgColor } from '@helper/StyledMixins';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    menuItems:  PropTypes.array,
    targetData: PropTypes.object,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const MenuWrapper = styled.div`
    background:   ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.backgroundColor};
`;

const MenuItemWrapper = styled.div`
    color: ${(props) => props.theme.textColor} !important;

    ${(props) => svgColor(props.theme.textColor)}
    &:hover
    {
        background: ${(props) => props.theme.backgroundColor};
        color:      ${(props) => props.theme.secondaryColor} !important;

        ${(props) => svgColor(props.theme.secondaryColor)}
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const RowMenu = ({
    menuItems  = [],
    targetData = {},
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const reference           = useRef(null);
    const handleClickOutside  = (event) => {
        if (
            reference.current &&
            !reference.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function toggleMenuClicked(event) {
        event.stopPropagation();
        setIsOpen(!isOpen);
    }

    function renderMenuItems() {
        return menuItems.map((item) => {
            function onMenuItemClicked(event) {
                event.stopPropagation();
                item.onClick(targetData);
                setIsOpen(false);
            }

            return (
                <MenuItemWrapper
                    key={item.id}
                    className={styles.menuItem}
                    onClick={onMenuItemClicked}
                >
                    <Icon iconType={item.icon} />
                    <span>
                        {item.text}
                    </span>
                </MenuItemWrapper>
            );
        });
    }

    return (
        <div
            className={styles.rowMenu}
            onClick={toggleMenuClicked}
            ref={reference}
        >
            <Icon iconType={IconType.ellipsis} />
            <MenuWrapper
                className={classNames(
                    styles.menu,
                    {
                        [styles.open]: isOpen,
                    },
                )}
            >
                {renderMenuItems()}
            </MenuWrapper>
        </div>
    );
};

RowMenu.propTypes = propTypes;

export default RowMenu;
