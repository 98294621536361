//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    breadCrumbStart:  'breadCrumbStart',
    breadCrumbEnd:    'breadCrumbEnd',
    check:            'check',
    contract:         'contract',
    card:             'card',
    chevronDown:      'chevronDown',
    chevronRight:     'chevronRight',
    circleArrowLeft:  'circleArrowLeft',
    circleArrowRight: 'circleArrowRight',
    circleCheck:      'circleCheck',
    rocket:           'rocket',
    circlePlus:       'circlePlus',
    cross:            'cross',
    download:         'download',
    equal:            'equal',
    idea:             'idea',
    pieChart:         'pieChart',
    home:             'home',
    clipboard:        'clipboard',
    information:      'information',
    menu:             'menu',
    pen:              'pen',
    plus:             'plus',
    trash:            'trash',
    save:             'save',
    ellipsis:         'ellipsis',
    send:             'send',
    settings:         'settings',
    settingsBig:      'settingsBig',
    user:             'user',
    zoom:             'zoom',
    zoomBox:          'zoomBox',
    logout:           'logout',
});
