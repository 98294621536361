//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import styled     from 'styled-components';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import { svgColor } from '@helper/StyledMixins';
import ButtonColor  from '@stateless/atomic/Button/ButtonColor';
import ButtonSize   from '@stateless/atomic/Button/ButtonSize';
import ButtonType   from '@stateless/atomic/Button/ButtonType';
import Icon         from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    color:            PropTypes.buttonColor,
    customColorLeft:  PropTypes.string,
    customColorRight: PropTypes.string,
    disabled:         PropTypes.bool,
    iconLeft:         PropTypes.iconType,
    iconRight:        PropTypes.iconType,
    onClick:          PropTypes.func,
    size:             PropTypes.oneOfObjectValues(ButtonSize),
    text:             PropTypes.string,
    type:             PropTypes.buttonType,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const ButtonWrapper = styled.button`
    border-color:     ${(props) => props.theme.secondaryColor};
    background-color: ${(props) => props.theme.secondaryColor};

    &:not(.white)
    {
        color: ${(props) => props.theme.buttonTextColor};
        ${(props) => !props.hasCustomIconColor && svgColor(props.theme.buttonTextColor)}
    }

`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const Button = ({
    color            = ButtonColor.default,
    disabled         = false,
    iconLeft         = null,
    iconRight        = null,
    onClick          = DefaultTypes.noop,
    text             = null,
    type             = ButtonType.default,
    size             = ButtonSize.default,
    customColorLeft  = null,
    customColorRight = null,
}) => {
    const [pressed, setPressed] = React.useState(false);
    const hasCustomIconColor    = customColorLeft || customColorRight;

    function renderIconLeft() {
        if (iconLeft) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerLeft,
                    )}
                >
                    <Icon
                        customColor={customColorLeft}
                        iconType={iconLeft}
                    />
                </div>
            );
        }

        return null;
    }

    function renderIconRight() {
        if (iconRight) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerRight,
                    )}
                >
                    <Icon
                        iconType={iconRight}
                        customColor={customColorRight}
                    />
                </div>
            );
        }

        return null;
    }

    function onMouseDown(event) {
        setPressed(true);
    }

    function onMouseUp(event) {
        setPressed(false);
    }

    function renderText() {
        if (text) {
            return (
                <p>
                    {text}
                </p>
            );
        }

        return null;
    }

    return (
        <ButtonWrapper
            className={classNames(
                styles.button,
                {
                    [styles.pressed]:  pressed,
                    [styles.iconOnly]: !text,
                    [styles.edgy]:     type === ButtonType.edgy,
                    [styles.icon]:     type === ButtonType.icon,
                    'white':           color === ButtonColor.white,
                    [styles.pink]:     color === ButtonColor.pink,
                    [styles.big]:      size === ButtonSize.big,
                },
            )}
            onClick={onClick}
            disabled={disabled}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            hasCustomIconColor={hasCustomIconColor}
        >
            {renderIconLeft()}
            {renderText()}
            {renderIconRight()}
        </ButtonWrapper>
    );
};

Button.propTypes = propTypes;

export default Button;
