//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectCalculation = (state) => {
    return _.get(state, ['widget', 'calculation']);
};

export const selectColorSettings = (state) => {
    return _.get(state, ['widget', 'colorSettings']);
};

export const selectUnits = (state) => {
    return _.get(state, ['widget', 'units']);
};

export const selectContactForm = (state) => {
    return _.get(state, ['widget', 'contactForm']);
};

export const selectShowThankYouPage = (state) => {
    return _.get(state, ['widget', 'showThankYouPage']);
};

export const selectCashflowPerMonth = (state) => {
    return _.get(state, ['widget', 'calculation', 'cashflowPerMonth']);
};

export const selectUserId = (state) => {
    return _.get(state, ['widget', 'userId']);
};

export const selectExternalUserId = (state) => {
    return _.get(state, ['widget', 'externalUserId']);
};

export default selectCalculation;
