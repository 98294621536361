//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import CalculationFields from '@constants/CalculationFields';
import CustomerFields    from '@constants/CustomerFields';

export const selectCurrentCalculation = (state) => {
    return _.get(state, ['calculation', 'currentCalculation']);
};

export const selectCurrentCalculationStep = (state) => {
    return _.get(selectCurrentCalculation(state), CalculationFields.step);
};

export const selectCurrentCalculationCustomer = (state) => {
    return _.get(state, ['calculation', 'currentCalculation', CalculationFields.customer]);
};

export const selectCurrentCalculationCustomerEmail = (state) => {
    return _.get(selectCurrentCalculationCustomer(state), [CustomerFields.contactPersonOne, CustomerFields.contactPersonEmail]);
};

export const selectCurrentCalculationResult = (state) => {
    return _.get(state, ['calculation', 'currentCalculation', CalculationFields.calculationResult]);
};

export const selectCurrentCalculationFields = (state) => {
    return _.get(state, ['calculation', 'currentCalculation', CalculationFields.calculationFields]);
};

export default selectCurrentCalculation;
