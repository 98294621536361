//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const initialState = Object.freeze({
    lastLogin:     null,
    password:      '',
    token:         null,
    username:      '',
    colorSettings: {
        mainColor:       '#4b6941',
        accentColor:     '#d9d68f',
        backgroundColor: '#a65757',
        textColor:       '#c43d3d',
    },
    user:          {},
});

const userSlice = createSlice({
    name:     'user',
    initialState,
    reducers: {
        login:                       LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loading.login'),
        ),
        loginWithCredentials:        _.noop,
        loginFailed:                 LoadingLevelHelper.decreaseLoading(() => {
        }),
        loginSucceeded:              LoadingLevelHelper.decreaseLoading((state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                lastLogin: {
                    $set: _.get(payload, 'lastLogin.date'),
                },
                password:  {
                    $set: '',
                },
                token:     {
                    $set: _.get(payload, 'token'),
                },
                username:  {
                    $set: _.get(payload, 'username'),
                },
                iri:       {
                    $set: _.get(payload, 'userId'),
                },
                id:        {
                    $set: _.get(payload, 'id'),
                },
            });
        }),
        logout:                      (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setPassword:                 (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                password: {
                    $set: payload.password,
                },
            });
        },
        setUsername:                 (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                username: {
                    $set: payload.username,
                },
            });
        },
        tryRestoreToken:             (state, action) => {
        },
        tryRestoreTokenCompleted:    (state, action) => {
        },
        updateColorSettingsData:     (state, action) => {
            return update(state, {
                colorSettings: {
                    $set: _.merge(
                        _.get(state, 'colorSettings', initialState.colorSettings),
                        _.get(action, 'payload.colorSettings', {}),
                    ),
                },
            });
        },
        saveColorSettings:           LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.colorSettingsSave')),
        saveColorSettingsFailed:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveColorSettingsSucceeded:  LoadingLevelHelper.decreaseLoading((state, action) => {
            console.debug('Color settings saved red:', _.get(action, 'payload.colorSettings', {}));
            return update(state, {
                colorSettings: {
                    $set: _.get(action, 'payload.colorSettings', initialState.colorSettings),
                },
            });
        }),
        fetchColorSettings:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.colorSettings')),
        fetchColorSettingsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchColorSettingsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                colorSettings: {
                    $set: _.get(action, 'payload.colorSettings', initialState.colorSettings),
                },
            });
        }),
        fetchUser:                   LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.user')),
        fetchUserFailed:             LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchUserSucceeded:          LoadingLevelHelper.decreaseLoading((state, action) => {
            const user = _.get(action, 'payload.user', initialState.user);

            return update(state, {
                user: {
                    $set: user,
                },
            });
        }),
        saveUser:                    LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.userSave')),
        saveUserFailed:              LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveUserSucceeded:           LoadingLevelHelper.decreaseLoading((state, action) => {
            const user = _.get(action, 'payload.user', initialState.user);

            return update(state, {
                user: {
                    $set: user,
                },
            });
        }),
        changePassword:              LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.changePassword')),
        changePasswordFailed:        LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        changePasswordSucceeded:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveSettings:                LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.settingsSave')),
        saveSettingsFailed:          LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveSettingsSucceeded:       LoadingLevelHelper.decreaseLoading((state, action) => {
            const user = _.get(action, 'payload.user', initialState.user);

            return update(state, {
                user: {
                    $set: user,
                },
            });
        }),
        signup:                      LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.signup')),
        signupFailed:                LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        signupSucceeded:             LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        passwordReset:               LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.passwordReset')),
        passwordResetFailed:         LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        passwordResetSucceeded:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        setNewPassword:              LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.setNewPassword')),
        setNewPasswordFailed:        LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        setNewPasswordSucceeded:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateUserData:              (state, action) => {
            return update(state, {
                user: {
                    $set: Merge.mergeWith(
                        _.get(state, 'user', initialState.user),
                        _.get(action, 'payload.user', {}),
                    ),
                },
            });
        },
        setUserImage:                (state, action) => {
            return update(state, {
                user: {
                    image: {
                        $set: _.get(action, 'payload.image', {}),
                    },
                },
            });
        },
    },
});

export const UserActions = userSlice.actions;

export const UserReducer = userSlice.reducer;
export const useUser     = (dispatch) => bindActionCreators(UserActions, dispatch);

export default userSlice;
