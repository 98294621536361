//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                              from 'lodash';
import { useDispatch }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { string }                     from 'yup';
import { ref }                        from 'yup';

import PropTypes            from '@components/PropTypes';
import Security             from '@constants/Security';
import UserFields           from '@constants/UserFields';
import InputRenderHelper    from '@helper/InputRenderHelper';
import Notification         from '@helper/Notification';
import ValidationHelper     from '@helper/ValidationHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUser }          from '@slices/user';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import TextInputType        from '@stateless/atomic/TextInput/TextInputType';
import LabeledWrapper       from '@stateless/composed/LableledWrapper';

const propTypes = {
    email:         PropTypes.string,
    passwordToken: PropTypes.string,
};

const PasswordResetChange = ({
    passwordToken = null,
    email         = null,
}) => {
    const translator                                    = useContextTranslator('screens.profileScreen');
    const dispatch                                      = useDispatch();
    const userActions                                   = useUser(dispatch);
    const [newPassword, setNewPassword]                 = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat]     = useState('');
    const [passwordValidations, setPasswordValidations] = useState({});
    const passwordObject                                = {
        newPassword,
        newPasswordRepeat,
    };
    const changePasswordSchema                          = objectValidation({
        [UserFields.newPassword]:       string().required().min(Security.minimumPasswordLength).required('Password is required'),
        [UserFields.newPasswordRepeat]: string().required().min(Security.minimumPasswordLength).oneOf([ref(UserFields.newPassword), null], 'Passwords must match'),
    });
    const inputRenderer                                 = new InputRenderHelper({
        validations:  passwordValidations,
        data:         passwordObject,
        onUpdateData: _.noop,
    });

    function onChangePasswordClicked() {
        const validations = ValidationHelper.getValidations(changePasswordSchema, passwordObject);

        setPasswordValidations(validations);

        if (ValidationHelper.hasNoValidations(validations)) {
            userActions.setNewPassword({
                newPassword,
                passwordToken,
                email,
            });
        } else {
            Notification.error('changePassword.validationError', {
                minimumLength: Security.minimumPasswordLength,
            });
        }
    }

    return (
        <LabeledWrapper
            label={translator.t('password')}
            columns={1}
        >
            {inputRenderer.renderTextInput({
                label:         translator.t('password'),
                field:         UserFields.newPassword,
                textInputType: TextInputType.password,
                value:         newPassword,
                onChange:      setNewPassword,
            })}
            {inputRenderer.renderTextInput({
                label:         translator.t('passwordConfirm'),
                field:         UserFields.newPasswordRepeat,
                textInputType: TextInputType.password,
                value:         newPasswordRepeat,
                onChange:      setNewPasswordRepeat,
            })}
            <Button
                type={ButtonType.edgy}
                text={translator.t('changePassword')}
                onClick={onChangePasswordClicked}
            />
        </LabeledWrapper>
    );
};

PasswordResetChange.propTypes = propTypes;

export default PasswordResetChange;
