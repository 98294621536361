//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n                     from 'i18next';
import _                        from 'lodash';
import { useDispatch }          from 'react-redux';
import { useSelector }          from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { Api }                 from '@api';
import ColorSettingsFields     from '@constants/ColorSettingsFields';
import Routes                  from '@constants/Routes';
import useContextTranslator    from '@hooks/ContextTranslator';
import { useUser }             from '@slices/user';
import { useWidget }           from '@slices/widget';
import Headline                from '@stateless/atomic/Headline';
import HeadlineType            from '@stateless/atomic/Headline/HeadlineType';
import LabeledInput            from '@stateless/atomic/LabeledInput';
import LabeledInputType        from '@stateless/atomic/LabeledInput/LabeledInputType';
import Spacer                  from '@stateless/atomic/Spacer';
import CalculationWidget       from '@stateless/composed/CalculationWidget';
import ColorPicker             from '@stateless/composed/ColorPicker';
import LayoutScreen            from '@stateless/composed/LayoutScreen';
import PageTitle               from '@stateless/composed/PageTitle';
import { selectColorSettings } from '@store/selectors/user';
import { selectUserId }        from '@store/selectors/user';

import styles from './styles.module.scss';

const CustomerOnlineCalculatorScreen = () => {
    const translator    = useContextTranslator('screens.customerOnlineCalculatorScreen');
    const dispatch      = useDispatch();
    const userActions   = useUser(dispatch);
    const widgetActions = useWidget(dispatch);
    const colorSettings = useSelector(selectColorSettings);
    const debouncedSave = useDebouncedCallback(userActions.saveColorSettings, 1000);
    const userId        = useSelector(selectUserId);

    useEffect(() => {
        userActions.fetchColorSettings();
        widgetActions.setUserId({
            userId,
        });
    }, []);

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            userActions.updateColorSettingsData({
                colorSettings: changeSet,
            });
            widgetActions.setColorSettings({
                colorSettings: {
                    ...colorSettings,
                    ...changeSet,
                },
            });
            debouncedSave();
        };
    }

    function getIFrameCode() {
        const baseUrl   = window.location.origin;
        const widget    = `${baseUrl}${Routes.widget}?userId=${userId}`;
        const scriptUrl = Api.getUrl('iframeResizer.min.js');

        return [
            `<script type="text/javascript" src="${scriptUrl}"></script>`,
            `<iframe src="${widget}" width="100%" height="400" frameborder="0" scrolling="auto" onload="iFrameResize();"></iframe>`,
        ].join('\n');
    }

    return (
        <>
            <PageTitle
                title={I18n.t('customerOnlineCalculator')}
            />
            <LayoutScreen>
                <div className={styles.customerOnlineCalculatorScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('customerOnlineCalculator')}
                    />
                    <div className={styles.grid}>
                        <div>
                            <Headline
                                type={HeadlineType.headline3}
                                title={translator.t('colorSettings')}
                            />
                            <ColorPicker
                                colorTitle={translator.t('mainColor')}
                                colorHex={_.get(colorSettings, ColorSettingsFields.mainColor)}
                                onColorChange={onUpdateData(ColorSettingsFields.mainColor)}
                            />
                            <ColorPicker
                                colorTitle={translator.t('detailColor')}
                                colorHex={_.get(colorSettings, ColorSettingsFields.accentColor)}
                                onColorChange={onUpdateData(ColorSettingsFields.accentColor)}
                            />
                            <ColorPicker
                                colorTitle={translator.t('backgroundColor')}
                                colorHex={_.get(colorSettings, ColorSettingsFields.backgroundColor)}
                                onColorChange={onUpdateData(ColorSettingsFields.backgroundColor)}
                            />
                            <ColorPicker
                                colorTitle={translator.t('textColor')}
                                colorHex={_.get(colorSettings, ColorSettingsFields.textColor)}
                                onColorChange={onUpdateData(ColorSettingsFields.textColor)}
                            />
                        </div>
                        <div>
                            <Headline
                                type={HeadlineType.headline3}
                                title={translator.t('embedCodeHeader')}
                            />
                            <LabeledInput
                                multiline={true}
                                type={LabeledInputType.light}
                                value={getIFrameCode()}
                            />
                        </div>
                        <div>
                            <Headline
                                type={HeadlineType.headline3}
                                title={translator.t('howToPublishHeader')}
                            />
                            <p>
                                {translator.t('howToPublishText')}
                            </p>
                        </div>
                    </div>
                    <Spacer />
                    <Headline
                        title={translator.t('calculatorPreview')}
                        type={HeadlineType.headline3}
                    />
                    <CalculationWidget />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CustomerOnlineCalculatorScreen;
