//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useMemo }   from 'react';
import { useEffect } from 'react';

import classNames       from 'classnames';
import _                from 'lodash';
import { SketchPicker } from 'react-color';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    colorHex:      PropTypes.string,
    colorTitle:    PropTypes.string,
    onColorChange: PropTypes.function,
};

const ColorPicker = ({
    colorHex      = Colors.lightGreen,
    onColorChange = _.noop,
    colorTitle    = '',
}) => {
    const [selectedColor, setSelectedColor]     = useState(colorHex);
    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(
        () => {
            setSelectedColor(colorHex);
        },
        [colorHex],
    );

    function onColorChanged(color) {
        setSelectedColor(color.hex);
        onColorChange(color.hex);
    }

    function openColorPickerClicked() {
        setShowColorPicker(true);
    }

    function closeColorPickerClicked() {
        setShowColorPicker(false);
    }

    const previewStyles = useMemo(
        () => {
            return {
                background: selectedColor,
            };
        },
        [selectedColor],
    );

    return (
        <div className={styles.colorPicker}>
            <p>
                {colorTitle}
            </p>
            <div
                onClick={openColorPickerClicked}
                style={previewStyles}
                className={styles.colorPreview}
            />
            <div
                className={classNames(
                    styles.pickerWrapper,
                    {
                        [styles.show]: showColorPicker,
                    },
                )}
            >
                <div
                    className={styles.popover}
                    onClick={closeColorPickerClicked}
                />
                <SketchPicker
                    triangle={'hide'}
                    onChange={onColorChanged}
                    onChageComplete={onColorChanged}
                    onSwatchHover={onColorChanged}
                    color={selectedColor}
                />
            </div>
        </div>
    );
};

ColorPicker.propTypes = propTypes;

export default ColorPicker;
