//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children:          PropTypes.children,
    columns:           PropTypes.number,
    contentFullHeight: PropTypes.bool,
    fullHeight:        PropTypes.bool,
    label:             PropTypes.string,
    minHeight:         PropTypes.number,
    reference:         PropTypes.func,
    small:             PropTypes.bool,
};

const LabeledWrapper = ({
    children          = null,
    columns           = 2,
    fullHeight        = false,
    contentFullHeight = false,
    label             = '',
    minHeight         = null,
    reference         = null,
    small             = false,
}) => {
    function createColumnStyles() {
        const columnsStyle = [];

        _.times(columns, (column) => {
            columnsStyle.push('1fr');
        });

        return columnsStyle.join(' ');
    }

    const columnsStyles = {
        gridTemplateColumns: createColumnStyles(),
    };

    function getWrapperStyles() {
        let wrapperStyles = {};

        if (minHeight !== null) {
            wrapperStyles = {
                ...wrapperStyles,
                minHeight: `${minHeight}px`,
            };
        }

        if (fullHeight) {
            wrapperStyles = {
                ...wrapperStyles,
                height: '100%',
            };
        }

        return wrapperStyles;
    }

    return (
        <div
            className={classNames(
                styles.labeledWrapper,
                {
                    [styles.small]: small,
                },
            )}
            style={getWrapperStyles()}
        >
            <label>
                {label}
            </label>
            <div
                style={columnsStyles}
                className={classNames(
                    styles.wrapperGrid,
                    {
                        [styles.wrapperGridFullHeight]: contentFullHeight,
                    },
                )}
                ref={reference}
            >
                {children}
            </div>
        </div>
    );
};

LabeledWrapper.propTypes = propTypes;

export default LabeledWrapper;
